var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("出库管理")]),
              _c("el-breadcrumb-item", [_vm._v("库存修改出库")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.orderCreate }
                },
                [_vm._v("新增修改单")]
              ),
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.searchForm,
                  size: "mini",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "库房名称" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "库房名称",
                          clearable: "",
                          filterable: ""
                        },
                        model: {
                          value: _vm.searchForm.warehouseId,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "warehouseId", $$v)
                          },
                          expression: "searchForm.warehouseId"
                        }
                      },
                      _vm._l(_vm.warehouseList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "供应商" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "供应商",
                          clearable: "",
                          filterable: ""
                        },
                        model: {
                          value: _vm.searchForm.manufacturerId,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "manufacturerId", $$v)
                          },
                          expression: "searchForm.manufacturerId"
                        }
                      },
                      _vm._l(_vm.supplierList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "修改单号" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "修改单号", clearable: "" },
                      model: {
                        value: _vm.searchForm.orderNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "orderNumber", $$v)
                        },
                        expression: "searchForm.orderNumber"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "状态" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "状态",
                          clearable: "",
                          filterable: ""
                        },
                        model: {
                          value: _vm.searchForm.status,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "status", $$v)
                          },
                          expression: "searchForm.status"
                        }
                      },
                      _vm._l(_vm.statusList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "制单日期" }
                  },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        "unlink-panels": true,
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd"
                      },
                      model: {
                        value: _vm.searchForm.dateRange,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "dateRange", $$v)
                        },
                        expression: "searchForm.dateRange"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "产品名称" }
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "产品名称", clearable: "" },
                      model: {
                        value: _vm.searchForm.productName,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "productName", $$v)
                        },
                        expression: "searchForm.productName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "产品编号" }
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "产品编号", clearable: "" },
                      model: {
                        value: _vm.searchForm.productNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "productNumber", $$v)
                        },
                        expression: "searchForm.productNumber"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "规格型号" }
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "规格型号", clearable: "" },
                      model: {
                        value: _vm.searchForm.specificationModel,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "specificationModel", $$v)
                        },
                        expression: "searchForm.specificationModel"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "产品主码" }
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "产品主码", clearable: "" },
                      model: {
                        value: _vm.searchForm.barCode,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "barCode", $$v)
                        },
                        expression: "searchForm.barCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "产品批号" }
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "产品批号", clearable: "" },
                      model: {
                        value: _vm.searchForm.batchNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "batchNumber", $$v)
                        },
                        expression: "searchForm.batchNumber"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "序列号" }
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "序列号", clearable: "" },
                      model: {
                        value: _vm.searchForm.trackingCode,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "trackingCode", $$v)
                        },
                        expression: "searchForm.trackingCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.searchSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.orderLoading,
              expression: "orderLoading"
            }
          ],
          ref: "orderTableRef",
          attrs: {
            hasIndex: true,
            pagination: _vm.searchForm,
            hasPagination: true,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            data: _vm.orderList
          },
          on: {
            "row-click": _vm.orderRowClick,
            "row-dblclick": _vm.orderRowDblClick,
            pageChange: _vm.orderPageChange,
            sizeChange: _vm.orderSizeChange
          },
          scopedSlots: _vm._u([
            {
              key: "status",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "驳回原因：" + row.reviewComments,
                        placement: "top",
                        disabled:
                          row.reviewComments && row.status === 1 ? false : true
                      }
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.statusFormatter(row))
                        }
                      })
                    ]
                  )
                ]
              }
            },
            {
              key: "operations",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "el-link",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.toViewOrder(row)
                        }
                      }
                    },
                    [_vm._v("查看详情")]
                  )
                ]
              }
            }
          ])
        }),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading"
            }
          ],
          ref: "detailTableRef",
          staticStyle: { "margin-top": "10px" },
          attrs: {
            hasIndex: true,
            pagination: _vm.detailForm,
            hasPagination: true,
            "route-name": _vm.$route.name + "-index-detail",
            columns: _vm.detailColumns,
            data: _vm.detailList
          },
          on: {
            pageChange: _vm.detailPageChange,
            sizeChange: _vm.detailSizeChange
          }
        })
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }