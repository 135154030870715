var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "900px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "100px",
                size: "mini",
                disabled: true
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "flex-wrap": "wrap" },
                  attrs: { type: "flex" }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["product_name"]),
                          attrs: { label: "物资名称", prop: "productName" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入物资名称",
                              maxlength: "50",
                              "show-word-limit": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.productName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "productName", $$v)
                              },
                              expression: "form.productName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["brand_name"]),
                          attrs: { label: "品牌", prop: "brandName" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.brandName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "brandName", $$v)
                              },
                              expression: "form.brandName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["product_place"]),
                          attrs: { label: "产地", prop: "productPlace" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入产地",
                              maxlength: "100",
                              "show-word-limit": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.productPlace,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "productPlace", $$v)
                              },
                              expression: "form.productPlace"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["classification_text"]),
                          attrs: { label: "所属分类", prop: "classification" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请选择所属分类" },
                            model: {
                              value: _vm.form.classificationText,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "classificationText", $$v)
                              },
                              expression: "form.classificationText"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["manufacturer"]),
                          attrs: { label: "生产厂家", prop: "manufacturer" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入生产厂家" },
                            model: {
                              value: _vm.form.manufacturer,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "manufacturer", $$v)
                              },
                              expression: "form.manufacturer"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["registration_number"]),
                          attrs: {
                            label: "注册证号",
                            prop: "registrationNumber"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                multiple: "",
                                clearable: "",
                                placeholder: "请选择注册证号"
                              },
                              model: {
                                value: _vm.certIdString,
                                callback: function($$v) {
                                  _vm.certIdString = $$v
                                },
                                expression: "certIdString"
                              }
                            },
                            _vm._l(_vm.registrationList, function(item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.ID,
                                  attrs: { label: item.regNoCn, value: item.ID }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(item.regNoCn))]
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24, align: "center" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium", type: "primary" },
                      on: { click: _vm.examineConfirm }
                    },
                    [_vm._v("确认审核")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("ExamineComponents", {
            ref: "ExamineComponents",
            on: { confirm: _vm.parentReload }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }