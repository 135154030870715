var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("出库管理")]),
              _c("el-breadcrumb-item", [_vm._v("销售出库")]),
              _c("el-breadcrumb-item", [
                _vm._v(
                  _vm._s(_vm.form.id ? "销售出库单详情" : "新增销售出库单")
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "font-size": "14px", color: "#606266" } },
            [
              _vm.orderRow.deliveryOrderNo
                ? _c("span", [
                    _vm._v("出库单号：" + _vm._s(_vm.orderRow.deliveryOrderNo))
                  ])
                : _vm._e(),
              _vm.form.intelligent === 0
                ? _c("span", { staticStyle: { "margin-left": "20px" } }, [
                    _vm._v(
                      "销售单号：" + _vm._s(_vm.orderRow.sellOrder.orderNumber)
                    )
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              "label-suffix": ":",
              size: "mini",
              "label-width": "100px",
              "show-message": false,
              disabled: _vm.form.status > 1
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户类型", prop: "customerType" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { disabled: _vm.form.intelligent === 0 },
                            on: { change: _vm.customerTypeChange },
                            model: {
                              value: _vm.form.customerType,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "customerType", $$v)
                              },
                              expression: "form.customerType"
                            }
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("经销商")
                            ]),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("医院")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.customerType === 1,
                        expression: "form.customerType===1"
                      }
                    ],
                    attrs: { sm: 12, lg: 8, xl: 6 }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户名称", prop: "distributorId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.form.intelligent === 0
                            },
                            on: { change: _vm.distributorIdChange },
                            model: {
                              value: _vm.form.distributorId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "distributorId", $$v)
                              },
                              expression: "form.distributorId"
                            }
                          },
                          _vm._l(_vm.distributorList, function(item, index) {
                            return _c("el-option", {
                              key: "distributorId" + index,
                              attrs: {
                                label: item.name,
                                value: item.ID,
                                disabled: !item.show_status
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.customerType === 2,
                        expression: "form.customerType===2"
                      }
                    ],
                    attrs: { sm: 12, lg: 8, xl: 6 }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户名称", prop: "hospitalId2" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.form.status > 1
                            },
                            on: { change: _vm.hospitalId2Change },
                            model: {
                              value: _vm.form.hospitalId2,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "hospitalId2", $$v)
                              },
                              expression: "form.hospitalId2"
                            }
                          },
                          _vm._l(_vm.hospitalList, function(item, index) {
                            return _c("el-option", {
                              key: "hospitalId2" + index,
                              attrs: {
                                label: item.name,
                                value: item.ID,
                                disabled: !item.status === 0
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "出库时间", prop: "deliveryTime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetime",
                            placeholder: "选择日期",
                            format: "yyyy-MM-dd HH:mm:ss",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            disabled: _vm.form.status > 1
                          },
                          model: {
                            value: _vm.form.deliveryTime,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "deliveryTime", $$v)
                            },
                            expression: "form.deliveryTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库", prop: "warehouseId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.form.status > 1
                            },
                            on: { change: _vm.warehouseIdChange },
                            model: {
                              value: _vm.form.warehouseId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "warehouseId", $$v)
                              },
                              expression: "form.warehouseId"
                            }
                          },
                          _vm._l(_vm.warehouseList, function(item, index) {
                            return _c(
                              "el-option",
                              {
                                key: "warehouseId" + index,
                                attrs: {
                                  label:
                                    item.name +
                                    (item.status === 0 ? "(无效)" : ""),
                                  value: item.ID,
                                  disabled: item.status === 0
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "space-between"
                                    }
                                  },
                                  [
                                    _c("span", [_vm._v(_vm._s(item.name))]),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.status === 0 ? true : false,
                                            expression:
                                              "item.status===0?true:false"
                                          }
                                        ],
                                        staticClass: "text-danger"
                                      },
                                      [_vm._v("(无效)")]
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "归属公司", prop: "subsidiaryId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.subsidiaryId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "subsidiaryId", $$v)
                              },
                              expression: "form.subsidiaryId"
                            }
                          },
                          _vm._l(_vm.subsidiaryList, function(item) {
                            return _c("el-option", {
                              key: "subsidiaryId" + item.ID,
                              attrs: {
                                label: item.companyName,
                                value: item.ID,
                                disabled: item.isValid === 0
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售人员", prop: "salespersonUid" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled:
                                _vm.form.status > 1 ||
                                _vm.form.intelligent === 0
                            },
                            model: {
                              value: _vm.form.salespersonUid,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "salespersonUid", $$v)
                              },
                              expression: "form.salespersonUid"
                            }
                          },
                          _vm._l(_vm.userList, function(item, index) {
                            return _c("el-option", {
                              key: "salespersonUid-" + index,
                              attrs: { label: item.name, value: item.uid }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "出库人", prop: "warehousePersonnelId" }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            ref: "buyerUid",
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.form.status > 1
                            },
                            model: {
                              value: _vm.form.warehousePersonnelId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "warehousePersonnelId", $$v)
                              },
                              expression: "form.warehousePersonnelId"
                            }
                          },
                          _vm._l(_vm.userList, function(item, index) {
                            return _c("el-option", {
                              key: "warehousePersonnelId-" + index,
                              attrs: { label: item.name, value: item.uid }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "物流信息", prop: "shippingMethodId" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "item-text" },
                          [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary", underline: false },
                                on: { click: _vm.viewLogistics }
                              },
                              [_vm._v("添加/查看物流信息")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "复核意见", prop: "checkPinion" } },
                      [
                        _c("span", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.orderRow.checkPinion))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "收货地址", prop: "addressManu" } },
                      [
                        _vm.orderRow.tokenSource === 2
                          ? _c("span", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.form.addressManu))
                            ])
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "请选择",
                                      disabled: _vm.form.status > 1
                                    },
                                    on: { change: _vm.addressManuChange },
                                    model: {
                                      value: _vm.form.addressManu,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "addressManu", $$v)
                                      },
                                      expression: "form.addressManu"
                                    }
                                  },
                                  _vm._l(_vm.addressList, function(
                                    item,
                                    index
                                  ) {
                                    return _c("el-option", {
                                      key: "addressManu-" + index,
                                      attrs: {
                                        label: item.address,
                                        value: item.address
                                      }
                                    })
                                  }),
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100px",
                                      "border-left": "1px solid #eee",
                                      "text-align": "center"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "primary",
                                          underline: false,
                                          disabled:
                                            _vm.form.status !== 1 ||
                                            (!_vm.form.distributorId &&
                                              !_vm.form.hospitalId2)
                                        },
                                        on: { click: _vm.addressAdd }
                                      },
                                      [_vm._v("新增地址")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "收件人", prop: "contactPersonManu" } },
                      [
                        _vm.orderRow.tokenSource === 2
                          ? _c("span", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.form.contactPersonManu))
                            ])
                          : _c("el-input", {
                              attrs: {
                                placeholder: "收件人",
                                maxlength: "50",
                                "suffix-icon": "el-icon-edit",
                                clearable: "",
                                disabled: _vm.form.status > 1
                              },
                              model: {
                                value: _vm.form.contactPersonManu,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "contactPersonManu", $$v)
                                },
                                expression: "form.contactPersonManu"
                              }
                            })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "联系方式", prop: "mobileManu" } },
                      [
                        _vm.orderRow.tokenSource === 2
                          ? _c("span", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.form.mobileManu))
                            ])
                          : _c("el-input", {
                              attrs: {
                                placeholder: "联系方式",
                                maxlength: "11",
                                "suffix-icon": "el-icon-edit",
                                clearable: "",
                                disabled: _vm.form.status > 1
                              },
                              model: {
                                value: _vm.form.mobileManu,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "mobileManu", $$v)
                                },
                                expression: "form.mobileManu"
                              }
                            })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAll,
                        expression: "showAll"
                      }
                    ],
                    attrs: { sm: 12, lg: 8, xl: 6 }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "医院", prop: "hospitalId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.form.status > 1
                            },
                            model: {
                              value: _vm.form.hospitalId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "hospitalId", $$v)
                              },
                              expression: "form.hospitalId"
                            }
                          },
                          _vm._l(_vm.hospitalList, function(item, index) {
                            return _c("el-option", {
                              key: "hospitalId" + index,
                              attrs: {
                                label: item.name,
                                value: item.ID,
                                disabled: !item.status === 0
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAll,
                        expression: "showAll"
                      }
                    ],
                    attrs: { sm: 12, lg: 8, xl: 6 }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "医生", prop: "surgeon" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "医生",
                            maxlength: "250",
                            "suffix-icon": "el-icon-edit",
                            clearable: "",
                            disabled: _vm.form.status > 1
                          },
                          model: {
                            value: _vm.form.surgeon,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "surgeon", $$v)
                            },
                            expression: "form.surgeon"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAll,
                        expression: "showAll"
                      }
                    ],
                    attrs: { sm: 12, lg: 8, xl: 6 }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "病人姓名", prop: "patientName" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "病人姓名",
                            maxlength: "250",
                            "suffix-icon": "el-icon-edit",
                            clearable: "",
                            disabled: _vm.form.status > 1
                          },
                          model: {
                            value: _vm.form.patientName,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "patientName", $$v)
                            },
                            expression: "form.patientName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAll,
                        expression: "showAll"
                      }
                    ],
                    attrs: { sm: 12, lg: 8, xl: 6 }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "住院号", prop: "admissionNumber" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "住院号",
                            maxlength: "250",
                            "suffix-icon": "el-icon-edit",
                            clearable: "",
                            disabled: _vm.form.status > 1
                          },
                          model: {
                            value: _vm.form.admissionNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "admissionNumber", $$v)
                            },
                            expression: "form.admissionNumber"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAll,
                        expression: "showAll"
                      }
                    ],
                    attrs: { sm: 12, lg: 8, xl: 6 }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "手术日期", prop: "operationTime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            placeholder: "选择日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            disabled: _vm.form.status > 1
                          },
                          model: {
                            value: _vm.form.operationTime,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "operationTime", $$v)
                            },
                            expression: "form.operationTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAll,
                        expression: "showAll"
                      }
                    ],
                    attrs: { sm: 12, lg: 8, xl: 6 }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "手术名称", prop: "surgicalName" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "手术名称",
                            maxlength: "250",
                            "suffix-icon": "el-icon-edit",
                            clearable: "",
                            disabled: _vm.form.status > 1
                          },
                          model: {
                            value: _vm.form.surgicalName,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "surgicalName", $$v)
                            },
                            expression: "form.surgicalName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    attrs: {
                      sm: _vm.showAll ? 24 : 24,
                      lg: _vm.showAll ? 24 : 24,
                      xl: _vm.showAll ? 12 : 24
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remarks" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "备注",
                              clearable: "",
                              maxlength: "500",
                              disabled: _vm.form.status > 1
                            },
                            model: {
                              value: _vm.form.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remarks", $$v)
                              },
                              expression: "form.remarks"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "form-more" }, [
              _c("span", { on: { click: _vm.showAllClick } }, [
                _vm._v(" " + _vm._s(_vm.showAll ? "合并" : "展开") + " "),
                _c("i", {
                  class: _vm.showAll
                    ? "el-icon-arrow-up el-icon--right"
                    : "el-icon-arrow-down el-icon--right"
                })
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0"
            }
          },
          [
            _c("div", [
              _vm.sellProcessMode === 1
                ? _c(
                    "div",
                    [
                      _vm.form.intelligent === 1
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "mini",
                                icon: "el-icon-plus",
                                disabled: _vm.form.status !== 1
                              },
                              on: { click: _vm.selectStock }
                            },
                            [_vm._v("增加产品")]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            size: "mini",
                            disabled: _vm.form.status !== 1
                          },
                          on: { click: _vm.scanInput }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-barcode",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(" 扫码录入")
                        ]
                      ),
                      _vm.form.intelligent === 1
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "mini",
                                icon: "el-icon-upload2",
                                disabled: _vm.form.status !== 1
                              },
                              on: { click: _vm.importFromExcel }
                            },
                            [_vm._v("Excel产品明细导入")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  "font-size": "14px",
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center",
                  color: "#606266"
                }
              },
              [
                _c("div", [
                  _vm._v(" 订单产品数合计："),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.countQuantity()))
                  ])
                ]),
                _c("div", { staticStyle: { margin: "0 20px" } }, [
                  _vm._v(" 出库数量合计："),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.countDeliveryQuantity()))
                  ])
                ]),
                _c(
                  "el-link",
                  {
                    attrs: { type: "primary", icon: "el-icon-menu" },
                    on: { click: _vm.showBatchList }
                  },
                  [_vm._v("批号列表显示")]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showScanInputBox,
                expression: "showScanInputBox"
              }
            ]
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  margin: "10px 0",
                  display: "flex",
                  "align-items": "center"
                }
              },
              [
                _c("div", { staticStyle: { width: "450px" } }, [
                  _c("input", {
                    ref: "scanInputBoxRef",
                    staticClass: "bar-input",
                    attrs: { placeholder: "请扫描产品条码", clearable: "" },
                    on: { change: _vm.barCodeSearch }
                  })
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#f00",
                      flex: "1",
                      "margin-left": "20px"
                    }
                  },
                  [_vm._v(_vm._s(_vm.tipMessage))]
                )
              ]
            )
          ]
        ),
        _c(
          "el-form",
          {
            ref: "detailListForm",
            attrs: {
              model: _vm.detailListForm,
              rules: _vm.detailListFormRules,
              size: "small",
              "show-message": false
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.detailLoading,
                    expression: "detailLoading"
                  }
                ],
                ref: "detailListTable",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.detailListForm.data,
                  "highlight-current-row": "",
                  border: "",
                  size: "mini",
                  "header-cell-style": {
                    background: "#f5f7fa",
                    borderColor: "#ebeef5",
                    color: "#333"
                  },
                  "row-key": _vm.detailRowKey,
                  "row-class-name": _vm.rowClassName,
                  "max-height": 450
                },
                on: {
                  "expand-change": _vm.expandChange,
                  "row-click": _vm.detailRowClick
                }
              },
              [
                _c("el-table-column", {
                  staticStyle: { padding: "0" },
                  attrs: { type: "expand", width: "50" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "el-table",
                            {
                              ref: "table-" + props.row.rowKey,
                              attrs: {
                                "max-height": "340",
                                size: "small",
                                data: props.row.productList,
                                "row-key": function(row) {
                                  return _vm.detailRowKey(row, "productList")
                                }
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  width: "50",
                                  label: "#",
                                  align: "center"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "100",
                                  align: "center",
                                  label: "批号",
                                  prop: "batchNumber"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "100",
                                  align: "center",
                                  label: "序列号",
                                  prop: "trackingCode"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "100",
                                  align: "center",
                                  label: "生产日期",
                                  prop: "manufacturerDate"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "100",
                                  align: "center",
                                  label: "有效期",
                                  prop: "validityDate"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "100",
                                  align: "center",
                                  label: "出库数量",
                                  prop: "deliveryQuantity"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "100",
                                  align: "center",
                                  label: "赠送数量",
                                  prop: "sellComplimentaryQuantity"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "是否寄售",
                                  prop: "isConsign",
                                  width: "100",
                                  align: "center"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          row.isConsign === 1
                                            ? _c(
                                                "el-tag",
                                                {
                                                  attrs: {
                                                    type: "success",
                                                    effect: "dark",
                                                    size: "mini"
                                                  }
                                                },
                                                [_vm._v("是")]
                                              )
                                            : _c(
                                                "el-tag",
                                                {
                                                  attrs: {
                                                    type: "info",
                                                    effect: "dark",
                                                    size: "mini"
                                                  }
                                                },
                                                [_vm._v("否")]
                                              )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "100",
                                  align: "center",
                                  label: "灭菌批号",
                                  prop: "sterilizationBatchNumber"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "100",
                                  align: "center",
                                  label: "灭菌效期",
                                  prop: "sterilizationDate"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "100",
                                  align: "center",
                                  label: "操作"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-link",
                                            {
                                              attrs: {
                                                type: "danger",
                                                disabled: _vm.form.status !== 1
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeBatch(
                                                    props.row,
                                                    scope.row
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("删除")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      type: "index",
                      label: "序号",
                      width: "80",
                      align: "center",
                      index: function(index) {
                        return _vm.indexMethod(index, _vm.detailListForm)
                      }
                    }
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom-start",
                              trigger: "click"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "dragHeaderTable" },
                              [
                                _c("HeaderCustomComponent", {
                                  attrs: {
                                    remoteColumnsList: _vm.remoteColumnsList,
                                    cacheName: _vm.cacheName
                                  },
                                  on: { "save-table-set": _vm.saveTableSet }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-tag",
                              {
                                staticStyle: { cursor: "pointer" },
                                attrs: {
                                  slot: "reference",
                                  effect: "dark",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [
                                _vm._v("序号"),
                                _c("i", {
                                  staticClass: "el-icon-s-tools",
                                  attrs: { "aria-hidden": "true" }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  2
                ),
                _vm._l(_vm.remoteColumnsList, function(item, idx) {
                  return [
                    item.checked
                      ? _c("el-table-column", {
                          key: "columns-" + idx + _vm.tamptime,
                          attrs: {
                            prop: item.prop,
                            label: item.label,
                            "min-width": item.width,
                            align: "center",
                            "show-overflow-tooltip": ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    item.prop === "deliveryQuantity"
                                      ? _c(
                                          "div",
                                          [
                                            _vm.form.status === 1 &&
                                            _vm.form.intelligent === 1
                                              ? _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "100%"
                                                  },
                                                  attrs: {
                                                    min: 0,
                                                    controls: false,
                                                    precision: 0,
                                                    placeholder: "销售数量",
                                                    size: "mini"
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.detailSaveOne(
                                                        row
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value: row.deliveryQuantity,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "deliveryQuantity",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression:
                                                      "row.deliveryQuantity"
                                                  }
                                                })
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(row.deliveryQuantity)
                                                  )
                                                ])
                                          ],
                                          1
                                        )
                                      : item.prop === "complimentaryQuantity"
                                      ? _c(
                                          "div",
                                          [
                                            _vm.form.status === 1 &&
                                            _vm.form.intelligent === 1
                                              ? _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "100%"
                                                  },
                                                  attrs: {
                                                    min: 0,
                                                    controls: false,
                                                    precision: 0,
                                                    placeholder: "赠送数量",
                                                    size: "mini"
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.detailSaveOne(
                                                        row
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      row.complimentaryQuantity,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "complimentaryQuantity",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression:
                                                      "row.complimentaryQuantity"
                                                  }
                                                })
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      row.complimentaryQuantity
                                                    )
                                                  )
                                                ])
                                          ],
                                          1
                                        )
                                      : item.prop === "remarks"
                                      ? _c(
                                          "div",
                                          [
                                            _vm.form.status === 1 &&
                                            _vm.form.intelligent === 1
                                              ? _c("el-input", {
                                                  attrs: {
                                                    placeholder: "备注",
                                                    size: "mini"
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.detailSaveOne(
                                                        row
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value: row.remarks,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "remarks",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.remarks"
                                                  }
                                                })
                                              : _c("span", [
                                                  _vm._v(_vm._s(row.remarks))
                                                ])
                                          ],
                                          1
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataTypeFormatter(
                                                row[item.prop],
                                                item.type
                                              )
                                            )
                                          )
                                        ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e()
                  ]
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "操作",
                    width: "150",
                    align: "center",
                    fixed: "right"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.form.status === 1
                            ? _c(
                                "el-link",
                                {
                                  attrs: { type: "primary", underline: false },
                                  on: {
                                    click: function($event) {
                                      return _vm.batchManager(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("添加批次")]
                              )
                            : _c(
                                "el-link",
                                {
                                  attrs: { type: "primary", underline: false },
                                  on: {
                                    click: function($event) {
                                      return _vm.batchManager(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("查看批次")]
                              ),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "right",
                                width: "600",
                                trigger: "click"
                              }
                            },
                            [
                              _c("DetailExtendComponents", {
                                key: scope.$index,
                                ref: "DetailExtendComponents",
                                attrs: { row: scope.row }
                              }),
                              _c("el-link", {
                                staticStyle: {
                                  "margin-left": "10px",
                                  padding: "5px"
                                },
                                attrs: {
                                  slot: "reference",
                                  icon: "el-icon-arrow-down",
                                  underline: false
                                },
                                slot: "reference"
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              2
            )
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "total, sizes, prev, pager, next, jumper",
                align: "center",
                "page-sizes": [10, 50, 100, 200],
                total: _vm.detailListForm.total,
                "current-page": _vm.detailListForm.page,
                "page-size": _vm.detailListForm.pageSize
              },
              on: {
                "size-change": _vm.detailListFormSizeChange,
                "current-change": _vm.detailListFormPageChange
              }
            })
          ],
          1
        )
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-breadcrumb",
              {
                staticStyle: {
                  "font-size": "12px",
                  "margin-bottom": "10px",
                  width: "100%"
                },
                attrs: { separator: " " }
              },
              [
                _c("el-breadcrumb-item", [
                  _vm._v("制单人：" + _vm._s(_vm.form.issuePersonnel))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("出库人：" + _vm._s(_vm.form.nameWarehousePersonnel))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("出库时间：" + _vm._s(_vm.form.deliveryTime))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("出库复核人：" + _vm._s(_vm.orderRow.checkName))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("出库复核时间：" + _vm._s(_vm.orderRow.checkDate))
                ])
              ],
              1
            ),
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" } },
                  [
                    _vm.form.status === 1 && _vm.form.intelligent === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: {
                              type: "danger",
                              disabled: _vm.detailRowIndex < 0
                            },
                            on: { click: _vm.detailRowRemove }
                          },
                          [_vm._v("删除行")]
                        )
                      : _vm._e(),
                    _vm.form.status == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.submitCheck("storage")
                              }
                            }
                          },
                          [_vm._v("暂存")]
                        )
                      : _vm._e(),
                    _vm.form.status == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.submitCheck("submit")
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-send" }),
                            _vm._v(" 提交 ")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.status == 2 || _vm.form.status == 3
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger", disabled: !_vm.form.id },
                            on: { click: _vm.unFormSubmit }
                          },
                          [
                            _c("i", { staticClass: "fa el-icon-refresh-left" }),
                            _vm._v(" 撤销提交")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.status == 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary", icon: "el-icon-check" },
                            on: { click: _vm.formCheck }
                          },
                          [_vm._v("复核 ")]
                        )
                      : _vm._e(),
                    _vm.orderRow.allStatusName === "暂存" && _vm.form.id
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger", icon: "el-icon-delete" },
                            on: { click: _vm.orderInvalid }
                          },
                          [_vm._v(" 作废出库单 ")]
                        )
                      : _vm._e(),
                    _vm.orderRow.allStatusName === "作废"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: { click: _vm.orderDelete }
                          },
                          [_vm._v("删除订单")]
                        )
                      : _vm._e(),
                    _vm.orderRow.allStatusName === "作废"
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: { click: _vm.reEdit }
                          },
                          [_vm._v("重新编辑")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "prevent-re-click",
                            rawName: "v-prevent-re-click"
                          }
                        ],
                        attrs: { type: "primary", disabled: !_vm.form.id },
                        on: { click: _vm.orderPrint }
                      },
                      [_vm._v("打印出库单")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("StockSearchComponents", {
        ref: "StockSearchComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("ScanInputComponents", {
        ref: "ScanInputComponents",
        on: {
          confirm: _vm.stockAddToList,
          ytconfirm: _vm.scanResultConfirm,
          reload: _vm.loadDetail
        }
      }),
      _c("ImportDetailComponents", {
        ref: "ImportDetailComponents",
        on: { confirm: _vm.stockAddToListImport }
      }),
      _c("ExpireDialogComponents", {
        ref: "ExpireDialogComponents",
        on: { confirm: _vm.expireConfirm }
      }),
      _c("BatchManagerComponents", {
        ref: "BatchManagerComponents",
        on: { confirm: _vm.batchUpdate }
      }),
      _c("LogisticsComponents", {
        ref: "LogisticsComponents",
        on: { confirm: _vm.logisticsConfirm }
      }),
      _c("CheckComponents", {
        ref: "CheckComponents",
        on: { confirm: _vm.formCheckConfirm }
      }),
      _c("BatchListComponents", { ref: "BatchListComponents" }),
      _c("AddressComponent", {
        ref: "AddressComponentRef",
        on: { reload: _vm.loadAddress }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }