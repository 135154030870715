var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "800px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            size: "small",
            "label-width": "110px",
            "label-suffix": ":"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收货地址", prop: "addressManu" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", filterable: "" },
                          on: { change: _vm.addressManuChange },
                          model: {
                            value: _vm.form.addressManu,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "addressManu", $$v)
                            },
                            expression: "form.addressManu"
                          }
                        },
                        _vm._l(_vm.addressList, function(item, index) {
                          return _c("el-option", {
                            key: "addressManu-" + index,
                            attrs: { label: item.address, value: item.address }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收件人", prop: "contactPersonManu" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", maxlength: "50" },
                        model: {
                          value: _vm.form.contactPersonManu,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "contactPersonManu", $$v)
                          },
                          expression: "form.contactPersonManu"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系方式", prop: "mobileManu" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", maxlength: "50" },
                        model: {
                          value: _vm.form.mobileManu,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "mobileManu", $$v)
                          },
                          expression: "form.mobileManu"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "selfDelivery", label: "运输方式" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.selfDelivery,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "selfDelivery", $$v)
                            },
                            expression: "form.selfDelivery"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("自运")
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("快递")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "sendDate", label: "发货日期" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          placeholder: "选择日期",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd"
                        },
                        model: {
                          value: _vm.form.sendDate,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sendDate", $$v)
                          },
                          expression: "form.sendDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.selfDelivery === 1,
                      expression: "form.selfDelivery===1"
                    }
                  ]
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "transportPersonnel",
                            label: "运输人员"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text", clearable: "" },
                            model: {
                              value: _vm.form.transportPersonnel,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "transportPersonnel", $$v)
                              },
                              expression: "form.transportPersonnel"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "transportationPhone",
                            label: "运输人员电话"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text", clearable: "" },
                            model: {
                              value: _vm.form.transportationPhone,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "transportationPhone", $$v)
                              },
                              expression: "form.transportationPhone"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "environmentalTemperature",
                            label: "运输环境温度"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text", clearable: "" },
                            model: {
                              value: _vm.form.environmentalTemperature,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "environmentalTemperature",
                                  $$v
                                )
                              },
                              expression: "form.environmentalTemperature"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "plateNumber", label: "车牌号" } },
                        [
                          _c("el-input", {
                            attrs: { type: "text", clearable: "" },
                            model: {
                              value: _vm.form.plateNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "plateNumber", $$v)
                              },
                              expression: "form.plateNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.selfDelivery === 2,
                      expression: "form.selfDelivery===2"
                    }
                  ]
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "courierNumber", label: "快递单号" } },
                        [
                          _c("el-input", {
                            attrs: { type: "text", clearable: "" },
                            model: {
                              value: _vm.form.courierNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "courierNumber", $$v)
                              },
                              expression: "form.courierNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { prop: "expressCompany", label: "快递公司" }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text", clearable: "" },
                            model: {
                              value: _vm.form.expressCompany,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "expressCompany", $$v)
                              },
                              expression: "form.expressCompany"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "expressCompanyPhone",
                            label: "快递公司电话"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text", clearable: "" },
                            model: {
                              value: _vm.form.expressCompanyPhone,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "expressCompanyPhone", $$v)
                              },
                              expression: "form.expressCompanyPhone"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "sendRemarks", label: "备注" } },
                    [
                      _c("el-input", {
                        attrs: { type: "text", clearable: "" },
                        model: {
                          value: _vm.form.sendRemarks,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sendRemarks", $$v)
                          },
                          expression: "form.sendRemarks"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0", align: "center" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "medium" },
                          on: { click: _vm.dialogClose }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary", size: "medium" },
                          on: { click: _vm.formSubmit }
                        },
                        [_vm._v("提交")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }