var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "700px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    size: "small",
                    "label-suffix": ":",
                    "label-width": "auto"
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "类型", prop: "type" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.form.type,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "type", $$v)
                                    },
                                    expression: "form.type"
                                  }
                                },
                                _vm._l(_vm.typeList, function(item, index) {
                                  return _c("el-option", {
                                    key: "type" + index,
                                    attrs: { label: item.name, value: item.ID }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "借调方", prop: "lend" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { filterable: "", clearable: "" },
                                  model: {
                                    value: _vm.form.lend,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "lend", $$v)
                                    },
                                    expression: "form.lend"
                                  }
                                },
                                _vm._l(_vm.retailerList, function(item, index) {
                                  return _c("el-option", {
                                    key: "lend" + index,
                                    attrs: { label: item.name, value: item.ID }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "借调开始时间",
                                prop: "startTime"
                              }
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetime",
                                  placeholder: "选择日期时间"
                                },
                                model: {
                                  value: _vm.form.startTime,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "startTime", $$v)
                                  },
                                  expression: "form.startTime"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "借调结束时间", prop: "endTime" }
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetime",
                                  placeholder: "选择日期时间"
                                },
                                model: {
                                  value: _vm.form.endTime,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "endTime", $$v)
                                  },
                                  expression: "form.endTime"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "借调清单备注", prop: "remark" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 3,
                                  placeholder: "请输入备注",
                                  maxlength: "100"
                                },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "借调清单附件", prop: "attach" }
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    drag: "",
                                    action:
                                      "https://jsonplaceholder.typicode.com/posts/",
                                    multiple: ""
                                  }
                                },
                                [
                                  _c("i", { staticClass: "el-icon-upload" }),
                                  _c(
                                    "div",
                                    { staticClass: "el-upload__text" },
                                    [
                                      _vm._v("将文件拖到此处，或"),
                                      _c("em", [_vm._v("点击上传")])
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip"
                                    },
                                    [
                                      _vm._v(
                                        "只能上传jpg/png文件，且不超过500kb"
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer", align: "center" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.formSubmit }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }