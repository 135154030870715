var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "500px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "modal-append-to-body": false,
            "append-to-body": true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-suffix": ":",
                "label-width": "70px",
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.relationType === 1,
                      expression: "form.relationType===1"
                    }
                  ],
                  attrs: { label: "经销商", prop: "manuId" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "" },
                      model: {
                        value: _vm.form.manuId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "manuId", $$v)
                        },
                        expression: "form.manuId"
                      }
                    },
                    _vm._l(_vm.retailerList, function(item, index) {
                      return _c("el-option", {
                        key: "manuId" + index,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.relationType === 2,
                      expression: "form.relationType===2"
                    }
                  ],
                  attrs: { label: "医院", prop: "hospitalId" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "" },
                      model: {
                        value: _vm.form.hospitalId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "hospitalId", $$v)
                        },
                        expression: "form.hospitalId"
                      }
                    },
                    _vm._l(_vm.hospitalList, function(item, index) {
                      return _c("el-option", {
                        key: "hospitalId" + index,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "地址", prop: "address" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "请输入地址",
                      clearable: "",
                      maxlength: "1000",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.form.address,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人", prop: "contactPerson" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "请输入联系人",
                      clearable: "",
                      maxlength: "255",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.form.contactPerson,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "contactPerson", $$v)
                      },
                      expression: "form.contactPerson"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "mobile" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "请输入手机号",
                      clearable: "",
                      maxlength: "11"
                    },
                    model: {
                      value: _vm.form.mobile,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "mobile", $$v)
                      },
                      expression: "form.mobile"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "mark" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "请输入备注",
                      clearable: "",
                      maxlength: "100"
                    },
                    model: {
                      value: _vm.form.mark,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "mark", $$v)
                      },
                      expression: "form.mark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.formSubmit }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }