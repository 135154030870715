var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "300px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    size: "small",
                    "label-suffix": ":",
                    "label-width": "auto"
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _vm.form.command === "reject"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "备注", prop: "remark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 3,
                              placeholder: "请输入备注",
                              maxlength: "100"
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark"
                            }
                          })
                        ],
                        1
                      )
                    : _c("div", { attrs: { align: "center" } }, [
                        _vm._v("是否确认归档？")
                      ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer", align: "center" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: {
                    type: _vm.form.command === "reject" ? "danger" : "primary"
                  },
                  on: { click: _vm.formSubmit }
                },
                [
                  _vm._v(
                    "确认" +
                      _vm._s(_vm.form.command === "reject" ? "驳回" : "归档")
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }