var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "1200px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading"
                }
              ],
              ref: "detailListTable",
              attrs: {
                data: _vm.detailList,
                border: "",
                size: "mini",
                "row-class-name": _vm.rowClassName,
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                },
                "row-key": _vm.detailRowKey,
                "cell-style": _vm.cellStyle
              },
              on: { "expand-change": _vm.expandChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand", width: "60", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                size: "mini",
                                border: "",
                                data: props.row.expands,
                                "header-cell-style": {
                                  background: "#f5f7fa",
                                  borderColor: "#ebeef5",
                                  color: "#333"
                                }
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品编号",
                                  prop: "productNumber",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "品牌",
                                  prop: "brandName",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品名称",
                                  prop: "productName",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "规格型号",
                                  prop: "specificationModel",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "生产批号",
                                  prop: "batchNumber",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "序列号",
                                  prop: "trackingCode",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "归属公司",
                                  prop: "companyName",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "出库数量",
                                  prop: "quantityUsed",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "消耗数量",
                                  prop: "consumedQuantity",
                                  "show-overflow-tooltip": ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { margin: "10px 0" } },
                            [
                              _c("el-pagination", {
                                attrs: {
                                  background: "",
                                  layout: "prev, pager, next",
                                  total: props.row.page.total,
                                  "current-page": props.row.page.page,
                                  "page-size": props.row.page.pageSize
                                },
                                on: {
                                  "current-change": function(val) {
                                    return _vm.expandRowPageChange(
                                      val,
                                      props.row
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1795757478
                )
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "productNumber",
                  label: "产品编号",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "productName",
                  label: "物资名称",
                  width: "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "genericName",
                  label: "通用名称",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specificationModel",
                  label: "规格型号",
                  width: "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "batchNumber",
                  label: "生产批号",
                  width: "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "deliveryQuantity",
                  label: "出库数量",
                  width: "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "quantityUsed",
                  label: "使用数量",
                  width: "100",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.consumTypeId
                            ? _c("span", [
                                _vm._v(_vm._s(scope.row.consumedQuantitySum))
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(scope.row.quantityUsed))
                              ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3760145284
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "unit",
                  label: "单位",
                  width: "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "manufacturerDate",
                  label: "生产日期",
                  width: "150",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "validityDate",
                  label: "有效期",
                  width: "150",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "trackingCode",
                  label: "序列号",
                  width: "150",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sterilizationBatchNumber",
                  label: "灭菌批号",
                  width: "150",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sterilizationDate",
                  label: "灭菌效期",
                  width: "150",
                  align: "center"
                }
              })
            ],
            1
          ),
          _vm.orderInfo.issueType === 4
            ? _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.loading,
                      expression: "!loading"
                    }
                  ],
                  ref: "confirmFormRef",
                  staticClass: "form-table",
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    model: _vm.confirmForm,
                    "show-message": false,
                    rules: _vm.confirmFormRules,
                    size: "mini",
                    "label-width": "85px",
                    "label-suffix": ":"
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "flex-wrap": "wrap" },
                      attrs: { type: "flex" }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c("el-form-item", { attrs: { label: "销售" } }, [
                            _c(
                              "span",
                              { staticClass: "item-text" },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary", underline: false }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.orderInfo.nameSalesperson)
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c("el-form-item", { attrs: { label: "跟台员" } }, [
                            _c(
                              "span",
                              { staticClass: "item-text" },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary", underline: false }
                                  },
                                  [_vm._v(_vm._s(_vm.orderInfo.nameAttendant))]
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "配货出库",
                                prop: "warehousePersonnelId"
                              }
                            },
                            [
                              _vm.checkLeaved(
                                _vm.confirmForm.warehousePersonnelId
                              )
                                ? _c("div", { staticClass: "subscript" }, [
                                    _vm._v("已离职")
                                  ])
                                : _vm._e(),
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "", filterable: "" },
                                  model: {
                                    value: _vm.confirmForm.warehousePersonnelId,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.confirmForm,
                                        "warehousePersonnelId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "confirmForm.warehousePersonnelId"
                                  }
                                },
                                _vm._l(
                                  _vm.followUserList.filter(function(item) {
                                    return item.uid !== 0
                                  }),
                                  function(item, index) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: "warehousePersonnelId" + index,
                                        attrs: {
                                          label: item.name,
                                          value: item.uid,
                                          disabled:
                                            item.deleted === 1 ? true : false
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "justify-content": "space-between"
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.name))
                                            ]),
                                            item.deleted === 1
                                              ? _c("span", [
                                                  _vm._v("("),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#f00"
                                                      }
                                                    },
                                                    [_vm._v("已离职")]
                                                  ),
                                                  _vm._v(")")
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "出库核验",
                                prop: "warehouseCheckUid"
                              }
                            },
                            [
                              _vm.checkLeaved(_vm.confirmForm.warehouseCheckUid)
                                ? _c("div", { staticClass: "subscript" }, [
                                    _vm._v("已离职")
                                  ])
                                : _vm._e(),
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "", filterable: "" },
                                  model: {
                                    value: _vm.confirmForm.warehouseCheckUid,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.confirmForm,
                                        "warehouseCheckUid",
                                        $$v
                                      )
                                    },
                                    expression: "confirmForm.warehouseCheckUid"
                                  }
                                },
                                _vm._l(
                                  _vm.warehouseUserList.filter(function(item) {
                                    return item.uid !== 0
                                  }),
                                  function(item, index) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: "warehouseCheckName" + index,
                                        attrs: {
                                          label: item.name,
                                          value: item.uid,
                                          disabled:
                                            item.deleted === 1 ? true : false
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "justify-content": "space-between"
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.name))
                                            ]),
                                            item.deleted === 1
                                              ? _c("span", [
                                                  _vm._v("("),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#f00"
                                                      }
                                                    },
                                                    [_vm._v("已离职")]
                                                  ),
                                                  _vm._v(")")
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c("el-form-item", { attrs: { label: "出库配送" } }, [
                            _c(
                              "span",
                              { staticClass: "item-text" },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary", underline: false }
                                  },
                                  [_vm._v(_vm._s(_vm.orderInfo.deliveryName))]
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _vm.hospitalInfo.checkoutStatus === 0
                        ? _c(
                            "el-col",
                            {
                              staticStyle: { position: "relative" },
                              attrs: { span: 4 }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "验货", prop: "examineUid" }
                                },
                                [
                                  _vm.checkLeaved(_vm.confirmForm.examineUid)
                                    ? _c("div", { staticClass: "subscript" }, [
                                        _vm._v("已离职")
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { clearable: "", filterable: "" },
                                      model: {
                                        value: _vm.confirmForm.examineUid,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.confirmForm,
                                            "examineUid",
                                            $$v
                                          )
                                        },
                                        expression: "confirmForm.examineUid"
                                      }
                                    },
                                    _vm._l(
                                      _vm.followUserList.filter(function(item) {
                                        return item.uid !== 0
                                      }),
                                      function(item, index) {
                                        return _c(
                                          "el-option",
                                          {
                                            key: "examineName" + index,
                                            attrs: {
                                              label: item.name,
                                              value: Number(item.uid),
                                              disabled:
                                                item.deleted === 1
                                                  ? true
                                                  : false
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "justify-content":
                                                    "space-between"
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.name))
                                                ]),
                                                item.deleted === 1
                                                  ? _c("span", [
                                                      _vm._v("("),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#f00"
                                                          }
                                                        },
                                                        [_vm._v("已离职")]
                                                      ),
                                                      _vm._v(")")
                                                    ])
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.hospitalInfo.preCleanerStatus === 0
                        ? _c(
                            "el-col",
                            {
                              staticStyle: { position: "relative" },
                              attrs: { span: 4 }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "术前清洗",
                                    prop: "preCleanerUid"
                                  }
                                },
                                [
                                  _vm.checkLeaved(_vm.confirmForm.preCleanerUid)
                                    ? _c("div", { staticClass: "subscript" }, [
                                        _vm._v("已离职")
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { clearable: "", filterable: "" },
                                      model: {
                                        value: _vm.confirmForm.preCleanerUid,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.confirmForm,
                                            "preCleanerUid",
                                            $$v
                                          )
                                        },
                                        expression: "confirmForm.preCleanerUid"
                                      }
                                    },
                                    _vm._l(
                                      _vm.followUserList.filter(function(item) {
                                        return item.uid !== 0
                                      }),
                                      function(item, index) {
                                        return _c(
                                          "el-option",
                                          {
                                            key: "preCleanerUid" + index,
                                            attrs: {
                                              label: item.name,
                                              value: item.uid,
                                              disabled:
                                                item.deleted === 1
                                                  ? true
                                                  : false
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "justify-content":
                                                    "space-between"
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.name))
                                                ]),
                                                item.deleted === 1
                                                  ? _c("span", [
                                                      _vm._v("("),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#f00"
                                                          }
                                                        },
                                                        [_vm._v("已离职")]
                                                      ),
                                                      _vm._v(")")
                                                    ])
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.orderInfo.isPreCleaner === 2
                                ? _c("div", { staticClass: "tooltip" }, [
                                    _c("div", [
                                      _vm._v(
                                        "配送员" +
                                          _vm._s(_vm.orderInfo.deliveryName) +
                                          "选择无需术前清洗"
                                      )
                                    ]),
                                    _c("div", { staticClass: "arrow" })
                                  ])
                                : _vm._e(),
                              _vm.orderInfo.isPreCleaner !== 2 &&
                              !_vm.confirmForm.preCleanerUid
                                ? _c("div", { staticClass: "tooltip" }, [
                                    _c("div", [_vm._v("术前清洗任务等待认领")]),
                                    _c("div", { staticClass: "arrow" })
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.hospitalInfo.disinfectStatus === 0
                        ? _c(
                            "el-col",
                            {
                              staticStyle: { position: "relative" },
                              attrs: { span: 4 }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "消毒",
                                    prop: "disinfectName"
                                  }
                                },
                                [
                                  _vm.checkLeaved(_vm.confirmForm.disinfectUid)
                                    ? _c("div", { staticClass: "subscript" }, [
                                        _vm._v("已离职")
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { clearable: "", filterable: "" },
                                      on: { change: _vm.disinfectNameChange },
                                      model: {
                                        value: _vm.confirmForm.disinfectName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.confirmForm,
                                            "disinfectName",
                                            $$v
                                          )
                                        },
                                        expression: "confirmForm.disinfectName"
                                      }
                                    },
                                    _vm._l(
                                      _vm.followUserList.filter(function(item) {
                                        return item.uid !== 0
                                      }),
                                      function(item, index) {
                                        return _c(
                                          "el-option",
                                          {
                                            key: "disinfectName" + index,
                                            attrs: {
                                              label: item.name,
                                              value: item.name,
                                              disabled:
                                                item.deleted === 1
                                                  ? true
                                                  : false
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "justify-content":
                                                    "space-between"
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.name))
                                                ]),
                                                item.deleted === 1
                                                  ? _c("span", [
                                                      _vm._v("("),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#f00"
                                                          }
                                                        },
                                                        [_vm._v("已离职")]
                                                      ),
                                                      _vm._v(")")
                                                    ])
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.orderInfo.isDisinfect === 2
                                ? _c("div", { staticClass: "tooltip" }, [
                                    _c("div", [
                                      _vm._v(
                                        "配送员" +
                                          _vm._s(_vm.orderInfo.deliveryName) +
                                          "选择无需消毒"
                                      )
                                    ]),
                                    _c("div", { staticClass: "arrow" })
                                  ])
                                : _vm._e(),
                              _vm.orderInfo.isDisinfect !== 2 &&
                              !_vm.confirmForm.disinfectName
                                ? _c("div", { staticClass: "tooltip" }, [
                                    _c("div", [_vm._v("消毒任务等待认领")]),
                                    _c("div", { staticClass: "arrow" })
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c("el-form-item", { attrs: { label: "回库配送" } }, [
                            _c(
                              "span",
                              { staticClass: "item-text" },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary", underline: false }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.orderInfo.returnDeliveryName)
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "回库核对",
                                prop: "returningPersonnelId"
                              }
                            },
                            [
                              _vm.checkLeaved(
                                _vm.confirmForm.returningPersonnelId
                              )
                                ? _c("div", { staticClass: "subscript" }, [
                                    _vm._v("已离职")
                                  ])
                                : _vm._e(),
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "", filterable: "" },
                                  model: {
                                    value: _vm.confirmForm.returningPersonnelId,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.confirmForm,
                                        "returningPersonnelId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "confirmForm.returningPersonnelId"
                                  }
                                },
                                _vm._l(_vm.warehouseUserList, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: "warehouseInUid" + index,
                                      attrs: {
                                        label: item.name,
                                        value: item.uid,
                                        disabled:
                                          item.deleted === 1 ? true : false
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "justify-content": "space-between"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.name))
                                          ]),
                                          item.deleted === 1
                                            ? _c("span", [
                                                _vm._v("("),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#f00"
                                                    }
                                                  },
                                                  [_vm._v("已离职")]
                                                ),
                                                _vm._v(")")
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.hospitalInfo.afterCleanerStatus === 0
                        ? _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "术后清洗",
                                    prop: "cleanerId"
                                  }
                                },
                                [
                                  _vm.checkLeaved(_vm.confirmForm.cleanerId)
                                    ? _c("div", { staticClass: "subscript" }, [
                                        _vm._v("已离职")
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { clearable: "", filterable: "" },
                                      model: {
                                        value: _vm.confirmForm.cleanerId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.confirmForm,
                                            "cleanerId",
                                            $$v
                                          )
                                        },
                                        expression: "confirmForm.cleanerId"
                                      }
                                    },
                                    _vm._l(
                                      _vm.followUserList.filter(function(item) {
                                        return item.uid !== 0
                                      }),
                                      function(item, index) {
                                        return _c(
                                          "el-option",
                                          {
                                            key: "cleanerId" + index,
                                            attrs: {
                                              label: item.name,
                                              value: Number(item.uid),
                                              disabled:
                                                item.deleted === 1
                                                  ? true
                                                  : false
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "justify-content":
                                                    "space-between"
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.name))
                                                ]),
                                                item.deleted === 1
                                                  ? _c("span", [
                                                      _vm._v("("),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#f00"
                                                          }
                                                        },
                                                        [_vm._v("已离职")]
                                                      ),
                                                      _vm._v(")")
                                                    ])
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "回库上架",
                                prop: "warehouseInUid"
                              }
                            },
                            [
                              _vm.checkLeaved(_vm.confirmForm.warehouseInUid)
                                ? _c("div", { staticClass: "subscript" }, [
                                    _vm._v("已离职")
                                  ])
                                : _vm._e(),
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "", filterable: "" },
                                  model: {
                                    value: _vm.confirmForm.warehouseInUid,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.confirmForm,
                                        "warehouseInUid",
                                        $$v
                                      )
                                    },
                                    expression: "confirmForm.warehouseInUid"
                                  }
                                },
                                _vm._l(_vm.warehouseUserList, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: "warehouseInUid" + index,
                                      attrs: {
                                        label: item.name,
                                        value: item.uid,
                                        disabled:
                                          item.deleted === 1 ? true : false
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "justify-content": "space-between"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.name))
                                          ]),
                                          item.deleted === 1
                                            ? _c("span", [
                                                _vm._v("("),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#f00"
                                                    }
                                                  },
                                                  [_vm._v("已离职")]
                                                ),
                                                _vm._v(")")
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "footer", align: "center" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    },
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.detailConfirm }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }