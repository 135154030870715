var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("销售管理")]),
              _c("el-breadcrumb-item", [_vm._v("寄售报台中心")]),
              _c("el-breadcrumb-item", [_vm._v("寄售报台记录")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "div",
          { staticClass: "index-container" },
          [
            _c(
              "el-card",
              { staticClass: "form-card", attrs: { shadow: "never" } },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      inline: true,
                      model: _vm.searchForm,
                      size: "mini",
                      "label-suffix": ":"
                    },
                    nativeOn: {
                      submit: function($event) {
                        $event.preventDefault()
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchFormSubmit.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "报台单号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "报台单号", clearable: "" },
                          model: {
                            value: _vm.searchForm.deliveryOrderNo,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "deliveryOrderNo", $$v)
                            },
                            expression: "searchForm.deliveryOrderNo"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "供应商" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "供应商", clearable: "" },
                          model: {
                            value: _vm.searchForm.organizationName,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "organizationName", $$v)
                            },
                            expression: "searchForm.organizationName"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户名称" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "客户名称", clearable: "" },
                          model: {
                            value: _vm.searchForm.customerName,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "customerName", $$v)
                            },
                            expression: "searchForm.customerName"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "状态" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100px" },
                                attrs: { placeholder: "状态" },
                                model: {
                                  value: _vm.searchForm.upStatus,
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchForm, "upStatus", $$v)
                                  },
                                  expression: "searchForm.upStatus"
                                }
                              },
                              _vm._l(_vm.statusList, function(item) {
                                return _c("el-option", {
                                  key: item.ID,
                                  attrs: { label: item.name, value: item.ID }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100px" },
                                attrs: { placeholder: "报台日期" },
                                model: {
                                  value: _vm.searchForm.timeType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchForm, "timeType", $$v)
                                  },
                                  expression: "searchForm.timeType"
                                }
                              },
                              _vm._l(_vm.timeTypeList, function(item) {
                                return _c("el-option", {
                                  key: "timeType" + item.ID,
                                  attrs: { label: item.name, value: item.ID }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "daterange",
                                "unlink-panels": true,
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd",
                                clearable: ""
                              },
                              model: {
                                value: _vm.searchForm.dateRange,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchForm, "dateRange", $$v)
                                },
                                expression: "searchForm.dateRange"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary" },
                            on: { click: _vm.searchFormSubmit }
                          },
                          [_vm._v("查询")]
                        ),
                        _c(
                          "el-link",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function($event) {
                                _vm.searchFormExpand = !_vm.searchFormExpand
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.searchFormExpand ? "合并" : "展开")
                            ),
                            _c("i", {
                              class:
                                "el-icon-arrow-" +
                                (_vm.searchFormExpand ? "up" : "down") +
                                " el-icon--right"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("YTable", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.orderLoading,
                  expression: "orderLoading"
                }
              ],
              ref: "dragTableRef",
              attrs: {
                hasIndex: true,
                pagination: _vm.searchForm,
                "route-name": _vm.$route.name,
                columns: _vm.columns,
                data: _vm.orderList
              },
              on: {
                "row-click": _vm.orderRowClick,
                "row-dblclick": _vm.orderRowDbClick
              },
              scopedSlots: _vm._u([
                {
                  key: "deliveryOrderNo",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c(
                        "el-popover",
                        {
                          ref: "barCodePopover" + row.ID,
                          attrs: { placement: "right", trigger: "click" }
                        },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: { id: "barCodeImage" + row.ID }
                            })
                          ]),
                          _c(
                            "el-link",
                            {
                              attrs: {
                                slot: "reference",
                                underline: false,
                                type: "primary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.makeBarCode(
                                    row.ID,
                                    row.deliveryOrderNo
                                  )
                                }
                              },
                              slot: "reference"
                            },
                            [_vm._v(_vm._s(row.deliveryOrderNo))]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "operations",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c(
                        "div",
                        { staticClass: "control-column" },
                        [
                          row.status === 1
                            ? _c(
                                "el-link",
                                {
                                  attrs: { type: "danger" },
                                  on: {
                                    click: function($event) {
                                      return _vm.orderRelease(row)
                                    }
                                  }
                                },
                                [_vm._v("释放")]
                              )
                            : _vm._e(),
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.viewDetail(row)
                                }
                              }
                            },
                            [_vm._v("查看详情")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _c(
              "div",
              { staticStyle: { margin: "10px 0" } },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "total, sizes, prev, pager, next, jumper",
                    align: "center",
                    total: _vm.searchForm.total,
                    "page-sizes": [5, 10, 20, 50, 100],
                    "current-page": _vm.searchForm.page,
                    "page-size": _vm.searchForm.pageSize
                  },
                  on: {
                    "current-change": _vm.pageChange,
                    "size-change": _vm.sizeChange
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }