var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("财务管理")]),
              _c("el-breadcrumb-item", [_vm._v("销售管理")]),
              _c("el-breadcrumb-item", [_vm._v("销售单审核")]),
              _c("el-breadcrumb-item", [_vm._v(_vm._s(_vm.salesTypeName))])
            ],
            1
          ),
          _c("div", [
            _c(
              "div",
              { staticStyle: { "font-size": "14px", color: "#409EFF" } },
              [_vm._v("销售单号：" + _vm._s(_vm.orderInfo.salesOrderNo))]
            )
          ])
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.orderInfo,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "100px",
              "show-message": false,
              "label-suffix": ":",
              disabled: _vm.orderInfo.status > 2,
              loading: _vm.formLoading
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "仓库" } }, [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.warehouse.name))
                      ])
                    ])
                  ],
                  1
                ),
                _vm.salesType([2, 3, 6]) &&
                _vm.orderInfo.stockout.customerType !== 2
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "经销商" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.distributorName))
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([2, 3, 6]) &&
                _vm.orderInfo.stockout.customerType === 2
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "医院" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(
                              _vm._s(_vm.orderInfo.stockout.hospital2.name)
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([6])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "经销商客户" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.customerName))
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([6])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "报台日期" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.tradeDate))
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([6])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "报台单号" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(
                              _vm._s(_vm.orderInfo.stockout.deliveryOrderNo)
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([1, 2, 4])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "医院" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.hospitalName))
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([2])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "出诊类型" } }, [
                          _c(
                            "span",
                            { staticClass: "item-text" },
                            [
                              _vm.orderInfo.emergencyNot === 2
                                ? _c(
                                    "el-tag",
                                    {
                                      attrs: { type: "danger", size: "small" }
                                    },
                                    [_vm._v("急诊")]
                                  )
                                : _c("el-tag", { attrs: { size: "small" } }, [
                                    _vm._v("普通")
                                  ])
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([1, 3, 4])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "销售部门" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.departmentName))
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([1, 4])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "销售人员" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.nameSalesperson))
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([1, 4])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "维护人员" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(
                              _vm._s(_vm.orderInfo.nameMaintenancePersonnel)
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([1, 4])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "跟台人员" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.nameAttendant))
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([1, 2, 4])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "手术类型" } }, [
                          _vm.orderInfo.nameOperationType.length > 0
                            ? _c(
                                "div",
                                { staticStyle: { margin: "0 10px 0 15px" } },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "bottom",
                                        trigger: "click"
                                      }
                                    },
                                    [
                                      _c(
                                        "ul",
                                        { staticClass: "surgery-list" },
                                        _vm._l(
                                          _vm.orderInfo.nameOperationType,
                                          function(item, index) {
                                            return _c(
                                              "li",
                                              {
                                                key:
                                                  "nameOperationType-" + index
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.surgeryName ||
                                                        item.surgery_name
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            cursor: "pointer"
                                          },
                                          attrs: { slot: "reference" },
                                          slot: "reference"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content":
                                                  "space-between",
                                                "align-items": "center"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    height: "40px",
                                                    flex: "1",
                                                    overflow: "hidden",
                                                    "text-overflow": "ellipsis",
                                                    "white-space": "nowrap",
                                                    width: "0"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.orderInfo
                                                        .nameOperationType[0]
                                                        .surgeryName ||
                                                        _vm.orderInfo
                                                          .nameOperationType[0]
                                                          .surgery_name
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-arrow-down",
                                                staticStyle: {
                                                  width: "40px",
                                                  "text-align": "center",
                                                  padding: "0 10px",
                                                  color: "#C0C4CC"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([1, 2, 4])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "手术时间" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.operationTime))
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([1, 3, 4])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "科室" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.usingDepartment))
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([1, 2, 4])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "手术医生" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.surgeon))
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([1, 4])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "病人姓名" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.patientName))
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([1, 2, 4])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "住院号" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.inpatientNo))
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([1, 2, 3, 4])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "配送方式" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.shippingMethodName))
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([1, 2, 3, 4])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "快递单号" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.courierNumber))
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([1, 2, 3, 4])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "运输费" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.freight))
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([1, 4])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "跟台费用" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.followUpFee))
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([1, 2, 3, 4])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "收货电话" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.receivingPhone))
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([1, 2, 3, 4])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "收货地址" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.shipAddress))
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.salesType([2])
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "销售总金额" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.totalSalesAmount))
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "归属公司", prop: "subsidiaryId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            on: { change: _vm.subsidiaryIdChange },
                            model: {
                              value: _vm.orderInfo.subsidiaryId,
                              callback: function($$v) {
                                _vm.$set(_vm.orderInfo, "subsidiaryId", $$v)
                              },
                              expression: "orderInfo.subsidiaryId"
                            }
                          },
                          _vm._l(_vm.subsidiaryList, function(item) {
                            return _c("el-option", {
                              key: "subsidiaryId" + item.ID,
                              attrs: {
                                label: item.companyName,
                                value: item.ID,
                                disabled: item.isValid === 0
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    attrs: {
                      sm: _vm.orderInfo.salesType === 6 ? 24 : 12,
                      lg: _vm.orderInfo.salesType === 6 ? 24 : 8,
                      xl: _vm.orderInfo.salesType === 6 ? 12 : 6
                    }
                  },
                  [
                    _c("el-form-item", { attrs: { label: "备注" } }, [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.remarks))
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("div", { staticStyle: { "margin-top": "10px" } }, [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "align-items": "center",
                "font-size": "14px"
              }
            },
            [
              _c(
                "div",
                { staticClass: "tab-list-grey", staticStyle: { margin: "0" } },
                [
                  _vm.orderInfo.salesType === 4
                    ? _c(
                        "div",
                        {
                          class:
                            _vm.activeTabId === 2
                              ? "tab-item hover"
                              : "tab-item",
                          on: {
                            click: function($event) {
                              return _vm.tabChange(2)
                            }
                          }
                        },
                        [_vm._v("医院销售单明细")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      class:
                        _vm.activeTabId === 1 ? "tab-item hover" : "tab-item",
                      on: {
                        click: function($event) {
                          return _vm.tabChange(1)
                        }
                      }
                    },
                    [_vm._v("库房出库明细")]
                  ),
                  _vm.orderInfo.salesType === 4
                    ? _c(
                        "div",
                        {
                          class:
                            _vm.activeTabId === 3
                              ? "tab-item hover"
                              : "tab-item",
                          on: {
                            click: function($event) {
                              return _vm.tabChange(3)
                            }
                          }
                        },
                        [_vm._v("提成明细")]
                      )
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                { staticStyle: { display: "flex", "flex-direction": "row" } },
                [
                  _vm.activeTabId === 2
                    ? _c("div", { staticStyle: { "margin-right": "20px" } }, [
                        _vm._v("销售数量："),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.sumQuantity()))
                        ]),
                        _vm._v("个")
                      ])
                    : _vm._e(),
                  _vm.activeTabId === 1
                    ? _c("div", { staticStyle: { "margin-right": "20px" } }, [
                        _vm._v("销售数量："),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.detailOther.quantityUsedSum))
                        ]),
                        _vm._v("个")
                      ])
                    : _vm._e(),
                  _vm.activeTabId === 2
                    ? _c("div", [
                        _vm._v("总金额："),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.sumAmount()))
                        ]),
                        _vm._v("元")
                      ])
                    : _vm._e(),
                  _vm.activeTabId === 1
                    ? _c("div", { staticStyle: { "margin-right": "20px" } }, [
                        _vm._v("成本价合计(不含税)："),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.detailOther.afterTaxPrice2Sum))
                        ]),
                        _vm._v("元")
                      ])
                    : _vm._e(),
                  _vm.activeTabId === 1
                    ? _c("div", { staticStyle: { "margin-right": "20px" } }, [
                        _vm._v("销售价合计(不含税)："),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.detailOther.salesPrice2Sum))
                        ]),
                        _vm._v("元")
                      ])
                    : _vm._e(),
                  _vm.activeTabId === 1
                    ? _c("div", [
                        _vm._v("毛利润合计："),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.detailOther.grossProfitSum))
                        ]),
                        _vm._v("元")
                      ])
                    : _vm._e()
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeTabId === 1,
                  expression: "activeTabId===1"
                }
              ]
            },
            [
              _c("YTable", {
                key: "detailListTable" + _vm.timestamp,
                ref: "detailListTable",
                attrs: {
                  hasSelection: _vm.orderInfo.salesType === 4 ? false : true,
                  selectable: _vm.selectable,
                  hasPagination: true,
                  hasIndex: true,
                  pagination: _vm.detailForm,
                  "route-name": _vm.$route.name + "detail",
                  columns: _vm.detailColumns,
                  data: _vm.detailList.slice(
                    (_vm.detailForm.page - 1) * _vm.detailForm.pageSize,
                    _vm.detailForm.page * _vm.detailForm.pageSize
                  )
                },
                on: {
                  pageChange: _vm.detailPageChange,
                  sizeChange: _vm.detailSizeChange,
                  "row-click": _vm.detailRowClick
                },
                scopedSlots: _vm._u([
                  {
                    key: "partialReview",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        row.partialReview === 2
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "success",
                                  size: "mini",
                                  effect: "dark"
                                }
                              },
                              [_vm._v("是")]
                            )
                          : row.partialReview === 1
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "info",
                                  size: "mini",
                                  effect: "dark"
                                }
                              },
                              [_vm._v("否")]
                            )
                          : _c("span", [_vm._v("-")])
                      ]
                    }
                  },
                  {
                    key: "isConsign",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        row.isConsign === 1
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "success",
                                  effect: "dark",
                                  size: "mini"
                                }
                              },
                              [_vm._v("是")]
                            )
                          : _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "info",
                                  effect: "dark",
                                  size: "mini"
                                }
                              },
                              [_vm._v("否")]
                            )
                      ]
                    }
                  },
                  {
                    key: "complimentaryQuantity",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm.orderInfo.status === 1 && row.partialReview === 1
                          ? _c("el-input-number", {
                              attrs: {
                                controls: false,
                                size: "mini",
                                min: 0,
                                precision: 0
                              },
                              on: {
                                change: function($event) {
                                  return _vm.detailSaveOne(row)
                                }
                              },
                              model: {
                                value: row.complimentaryQuantity,
                                callback: function($$v) {
                                  _vm.$set(row, "complimentaryQuantity", $$v)
                                },
                                expression: "row.complimentaryQuantity"
                              }
                            })
                          : _c("span", [
                              _vm._v(_vm._s(row.complimentaryQuantity))
                            ])
                      ]
                    }
                  },
                  {
                    key: "cenCollection",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        row.cenCollection === 2
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "success",
                                  size: "mini",
                                  effect: "dark"
                                }
                              },
                              [_vm._v("集采")]
                            )
                          : row.cenCollection === 3
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "info",
                                  size: "mini",
                                  effect: "dark"
                                }
                              },
                              [_vm._v("非集采")]
                            )
                          : _c("span", [_vm._v("-")])
                      ]
                    }
                  },
                  {
                    key: "remarks",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm.orderInfo.status === 2 && row.partialReview === 1
                          ? _c("el-input", {
                              attrs: { size: "mini" },
                              on: {
                                change: function($event) {
                                  return _vm.detailSaveOne(row)
                                }
                              },
                              model: {
                                value: row.remarks,
                                callback: function($$v) {
                                  _vm.$set(row, "remarks", $$v)
                                },
                                expression: "row.remarks"
                              }
                            })
                          : _c("span", [_vm._v(_vm._s(row.remarks))])
                      ]
                    }
                  },
                  {
                    key: "salesUnitPrice",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            Number(
                              row.sourceUnitPrice ? row.sourceUnitPrice : 0
                            ) !==
                            Number(row.salesUnitPrice ? row.salesUnitPrice : 0)
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-right": "5px",
                                      display: "flex",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      { attrs: { placement: "top" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content"
                                          },
                                          [
                                            _vm._v(
                                              "预警信息：根据后台价格管理设置的报价为" +
                                                _vm._s(
                                                  Number(
                                                    row.sourceUnitPrice
                                                      ? row.sourceUnitPrice
                                                      : 0
                                                  )
                                                ) +
                                                "，而这里为" +
                                                _vm._s(
                                                  Number(
                                                    row.salesUnitPrice
                                                      ? row.salesUnitPrice
                                                      : 0
                                                  )
                                                ) +
                                                "。"
                                            )
                                          ]
                                        ),
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "icon",
                                            attrs: {
                                              width: "14px",
                                              height: "14px",
                                              viewBox: "0 0 1024 1024",
                                              version: "1.1",
                                              xmlns:
                                                "http://www.w3.org/2000/svg"
                                            }
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d:
                                                  "M124.3 860h775.5L512 139.9 124.3 860zM512 760c-19.9 0-36-16.1-36-36s16.1-36 36-36 36 16.1 36 36-16.1 36-36 36z m36-340v160c0 19.9-16.1 36-36 36s-36-16.1-36-36V420c0-19.9 16.1-36 36-36s36 16.1 36 36z",
                                                fill: "#FFEB3B"
                                              }
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d:
                                                  "M991.7 878.9l-448-832C537.4 35.3 525.2 28 512 28s-25.4 7.3-31.7 18.9l-448 832c-6 11.2-5.7 24.6 0.8 35.5C39.6 925.3 51.3 932 64 932h896c12.7 0 24.4-6.7 30.9-17.5 6.5-10.9 6.8-24.4 0.8-35.6zM124.3 860L512 139.9 899.7 860H124.3z",
                                                fill: "#FF9800"
                                              }
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d:
                                                  "M476 420v160c0 19.9 16.1 36 36 36s36-16.1 36-36V420c0-19.9-16.1-36-36-36s-36 16.1-36 36z",
                                                fill: "#FF5722"
                                              }
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d:
                                                  "M512 724m-36 0a36 36 0 1 0 72 0 36 36 0 1 0-72 0Z",
                                                fill: "#FF5722"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.orderInfo.status === 2 &&
                            row.partialReview === 1
                              ? _c("el-input-number", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    controls: false,
                                    min: 0,
                                    precision: 2
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.detailSaveOne(row)
                                    },
                                    input: function($event) {
                                      return _vm.priceToTax(row)
                                    }
                                  },
                                  model: {
                                    value: row.salesUnitPrice,
                                    callback: function($$v) {
                                      _vm.$set(row, "salesUnitPrice", $$v)
                                    },
                                    expression: "row.salesUnitPrice"
                                  }
                                })
                              : _c(
                                  "div",
                                  {
                                    staticStyle: { flex: "1" },
                                    attrs: { align: "center" }
                                  },
                                  [_vm._v(_vm._s(row.salesUnitPrice))]
                                )
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "salesTaxRate",
                    fn: function(ref) {
                      var row = ref.row
                      var index = ref.index
                      return [
                        _vm.orderInfo.status === 2 && row.partialReview === 1
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c("el-input-number", {
                                  ref: "salesTaxRate-" + index,
                                  attrs: {
                                    size: "mini",
                                    controls: false,
                                    min: 0,
                                    precision: 2,
                                    max: 100
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.detailSaveOne(row)
                                    }
                                  },
                                  model: {
                                    value: row.salesTaxRate,
                                    callback: function($$v) {
                                      _vm.$set(row, "salesTaxRate", $$v)
                                    },
                                    expression: "row.salesTaxRate"
                                  }
                                }),
                                _c(
                                  "span",
                                  { staticStyle: { padding: "0 5px" } },
                                  [_vm._v("%")]
                                )
                              ],
                              1
                            )
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  row.salesTaxRate == undefined ||
                                    row.salesTaxRate == null
                                    ? 0
                                    : row.salesTaxRate
                                ) + "%"
                              )
                            ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeTabId === 2,
                  expression: "activeTabId===2"
                }
              ]
            },
            [
              _c("YTable", {
                key: "detailListTable2" + _vm.timestamp,
                ref: "detailListTable2",
                attrs: {
                  selectable: _vm.selectable,
                  hasPagination: true,
                  hasIndex: true,
                  hasSelection: _vm.orderInfo.salesType === 4 ? true : false,
                  pagination: _vm.hospitalForm,
                  "route-name": _vm.$route.name + "hospital",
                  columns: _vm.hospitalColumns,
                  data: _vm.hospitalDetailList.slice(
                    (_vm.hospitalForm.page - 1) * _vm.hospitalForm.pageSize,
                    _vm.hospitalForm.page * _vm.hospitalForm.pageSize
                  )
                },
                on: {
                  pageChange: _vm.hospitalPageChange,
                  sizeChange: _vm.hospitalSizeChange,
                  "row-click": _vm.detailRowClick
                },
                scopedSlots: _vm._u([
                  {
                    key: "partialReview",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        row.partialReview === 2
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "success",
                                  size: "mini",
                                  effect: "dark"
                                }
                              },
                              [_vm._v("是")]
                            )
                          : row.partialReview === 1
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "info",
                                  size: "mini",
                                  effect: "dark"
                                }
                              },
                              [_vm._v("否")]
                            )
                          : _c("span", [_vm._v("-")])
                      ]
                    }
                  },
                  {
                    key: "isConsign",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        row.isConsign === 1
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "success",
                                  effect: "dark",
                                  size: "mini"
                                }
                              },
                              [_vm._v("是")]
                            )
                          : _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "info",
                                  effect: "dark",
                                  size: "mini"
                                }
                              },
                              [_vm._v("否")]
                            )
                      ]
                    }
                  },
                  {
                    key: "complimentaryQuantity",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm.orderInfo.status === 1 && row.partialReview === 1
                          ? _c("el-input-number", {
                              attrs: {
                                controls: false,
                                size: "mini",
                                min: 0,
                                precision: 0
                              },
                              on: {
                                change: function($event) {
                                  return _vm.detailSaveOne(row)
                                }
                              },
                              model: {
                                value: row.complimentaryQuantity,
                                callback: function($$v) {
                                  _vm.$set(row, "complimentaryQuantity", $$v)
                                },
                                expression: "row.complimentaryQuantity"
                              }
                            })
                          : _c("span", [
                              _vm._v(_vm._s(row.complimentaryQuantity))
                            ])
                      ]
                    }
                  },
                  {
                    key: "cenCollection",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        row.cenCollection === 2
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "success",
                                  size: "mini",
                                  effect: "dark"
                                }
                              },
                              [_vm._v("集采")]
                            )
                          : row.cenCollection === 1
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "info",
                                  size: "mini",
                                  effect: "dark"
                                }
                              },
                              [_vm._v("非集采")]
                            )
                          : _c("span", [_vm._v("-")])
                      ]
                    }
                  },
                  {
                    key: "remarks",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm.orderInfo.status === 2 && row.partialReview === 1
                          ? _c("el-input", {
                              attrs: { size: "mini" },
                              on: {
                                change: function($event) {
                                  return _vm.detailSaveOne(row)
                                }
                              },
                              model: {
                                value: row.remarks,
                                callback: function($$v) {
                                  _vm.$set(row, "remarks", $$v)
                                },
                                expression: "row.remarks"
                              }
                            })
                          : _c("span", [_vm._v(_vm._s(row.remarks))])
                      ]
                    }
                  },
                  {
                    key: "salesUnitPrice",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            Number(
                              row.sourceUnitPrice ? row.sourceUnitPrice : 0
                            ) !==
                            Number(row.salesUnitPrice ? row.salesUnitPrice : 0)
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-right": "5px",
                                      display: "flex",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      { attrs: { placement: "top" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content"
                                          },
                                          [
                                            _vm._v(
                                              "预警信息：根据后台价格管理设置的报价为" +
                                                _vm._s(
                                                  Number(
                                                    row.sourceUnitPrice
                                                      ? row.sourceUnitPrice
                                                      : 0
                                                  )
                                                ) +
                                                "，而这里为" +
                                                _vm._s(
                                                  Number(
                                                    row.salesUnitPrice
                                                      ? row.salesUnitPrice
                                                      : 0
                                                  )
                                                ) +
                                                "。"
                                            )
                                          ]
                                        ),
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "icon",
                                            attrs: {
                                              width: "14px",
                                              height: "14px",
                                              viewBox: "0 0 1024 1024",
                                              version: "1.1",
                                              xmlns:
                                                "http://www.w3.org/2000/svg"
                                            }
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d:
                                                  "M124.3 860h775.5L512 139.9 124.3 860zM512 760c-19.9 0-36-16.1-36-36s16.1-36 36-36 36 16.1 36 36-16.1 36-36 36z m36-340v160c0 19.9-16.1 36-36 36s-36-16.1-36-36V420c0-19.9 16.1-36 36-36s36 16.1 36 36z",
                                                fill: "#FFEB3B"
                                              }
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d:
                                                  "M991.7 878.9l-448-832C537.4 35.3 525.2 28 512 28s-25.4 7.3-31.7 18.9l-448 832c-6 11.2-5.7 24.6 0.8 35.5C39.6 925.3 51.3 932 64 932h896c12.7 0 24.4-6.7 30.9-17.5 6.5-10.9 6.8-24.4 0.8-35.6zM124.3 860L512 139.9 899.7 860H124.3z",
                                                fill: "#FF9800"
                                              }
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d:
                                                  "M476 420v160c0 19.9 16.1 36 36 36s36-16.1 36-36V420c0-19.9-16.1-36-36-36s-36 16.1-36 36z",
                                                fill: "#FF5722"
                                              }
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d:
                                                  "M512 724m-36 0a36 36 0 1 0 72 0 36 36 0 1 0-72 0Z",
                                                fill: "#FF5722"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.orderInfo.status === 2 &&
                            row.partialReview === 1
                              ? _c("el-input-number", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    controls: false,
                                    min: 0,
                                    precision: 2
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.detailSaveOne(row)
                                    },
                                    input: function($event) {
                                      return _vm.priceToTax(row)
                                    }
                                  },
                                  model: {
                                    value: row.salesUnitPrice,
                                    callback: function($$v) {
                                      _vm.$set(row, "salesUnitPrice", $$v)
                                    },
                                    expression: "row.salesUnitPrice"
                                  }
                                })
                              : _c(
                                  "div",
                                  {
                                    staticStyle: { flex: "1" },
                                    attrs: { align: "center" }
                                  },
                                  [_vm._v(_vm._s(row.salesUnitPrice))]
                                )
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "salesTaxRate",
                    fn: function(ref) {
                      var row = ref.row
                      var index = ref.index
                      return [
                        _vm.orderInfo.status === 2 && row.partialReview === 1
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c("el-input-number", {
                                  ref: "salesTaxRate-" + index,
                                  attrs: {
                                    size: "mini",
                                    controls: false,
                                    min: 0,
                                    precision: 2,
                                    max: 100
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.detailSaveOne(row)
                                    }
                                  },
                                  model: {
                                    value: row.salesTaxRate,
                                    callback: function($$v) {
                                      _vm.$set(row, "salesTaxRate", $$v)
                                    },
                                    expression: "row.salesTaxRate"
                                  }
                                }),
                                _c(
                                  "span",
                                  { staticStyle: { padding: "0 5px" } },
                                  [_vm._v("%")]
                                )
                              ],
                              1
                            )
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  row.salesTaxRate == undefined ||
                                    row.salesTaxRate == null
                                    ? 0
                                    : row.salesTaxRate
                                ) + "%"
                              )
                            ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm.orderInfo.salesType === 4
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTabId === 3,
                      expression: "activeTabId===3"
                    }
                  ]
                },
                [
                  _c("YTable", {
                    key: "detailListTable3" + _vm.timestamp,
                    ref: "detailListTable3",
                    attrs: {
                      "route-name": _vm.$route.name + "-node-roles",
                      columns: _vm.nodeRolesColumns,
                      data: _vm.nodeRolesData
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: { margin: "10px 0", "font-size": "12px" },
                      attrs: { align: "right" }
                    },
                    [
                      _vm._v(" 订单销售金额合计："),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.orderInfo.faOrderSaleAmount) + "元")
                      ]),
                      _vm._v(" 提成合计："),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.orderInfo.totalSettleAmount) + "元")
                      ]),
                      _vm._v(" 提成比率："),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.orderInfo.settleProportion) + "%")
                      ])
                    ]
                  ),
                  _c("YTable", {
                    key: "detailListTable4" + _vm.timestamp,
                    ref: "detailListTable4",
                    attrs: {
                      "route-name": _vm.$route.name + "-percentage-detail",
                      columns: _vm.percentageColumns,
                      data: _vm.percentageData
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-breadcrumb",
              {
                staticStyle: {
                  "font-size": "12px",
                  "margin-bottom": "10px",
                  width: "100%"
                },
                attrs: { separator: " " }
              },
              [
                _c("el-breadcrumb-item", [
                  _vm._v("审核人：" + _vm._s(_vm.orderInfo.nameReviewer))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("审核时间：" + _vm._s(_vm.orderInfo.auditTime))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("报价人：" + _vm._s(_vm.orderInfo.quotationPersonnel))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("报价时间：" + _vm._s(_vm.orderInfo.quotationDate))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("记账人：" + _vm._s(_vm.orderInfo.bookkeeper))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("记账时间：" + _vm._s(_vm.orderInfo.postingDate))
                ])
              ],
              1
            ),
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" }, attrs: { align: "left" } },
                  [
                    _vm.orderInfo.status === 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.formCheck)
                              }
                            }
                          },
                          [_vm._v("审核")]
                        )
                      : _vm._e(),
                    _vm.orderInfo.status === 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.formPartCheck)
                              }
                            }
                          },
                          [_vm._v("部分审核")]
                        )
                      : _vm._e(),
                    _vm.orderInfo.status === 3
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: { click: _vm.formUnCheck }
                          },
                          [_vm._v("撤消审核")]
                        )
                      : _vm._e(),
                    _vm.orderInfo.status === 3
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: { click: _vm.formAccount }
                          },
                          [_vm._v("记账")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", disabled: !_vm.orderInfo.ID },
                        on: { click: _vm.orderPrint }
                      },
                      [_c("i", { staticClass: "fa fa-print" }), _vm._v(" 打印")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "prevent-re-click",
                            rawName: "v-prevent-re-click"
                          }
                        ],
                        attrs: { type: "primary", disabled: !_vm.orderInfo.ID },
                        on: { click: _vm.exportDetail }
                      },
                      [
                        _c("i", { staticClass: "el-icon-download" }),
                        _vm._v(" 导出明细")
                      ]
                    ),
                    _vm.orderInfo.cancelMenu === 1 ||
                    _vm.orderInfo.cancelMenu === 2
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: "超1个月不能反记账",
                              placement: "top",
                              disabled: _vm.cancelMenuShow
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  disabled: _vm.orderInfo.cancelMenu === 2
                                },
                                on: { click: _vm.toUnAccount }
                              },
                              [_vm._v("反记账")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintDirectComponents", { ref: "PrintDirectComponents" }),
      _c("PrintStockComponents", { ref: "PrintStockComponents" }),
      _c("PrintConsignComponents", { ref: "PrintConsignComponents" }),
      _c("PrintYoutaiStockComponents", { ref: "PrintYoutaiStockComponents" }),
      _c("PartCheckComponent", {
        ref: "PartCheckComponentRef",
        on: { reload: _vm.init }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }