var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("系统设置")]),
              _c("el-breadcrumb-item", [_vm._v("流程设置")]),
              _c("el-breadcrumb-item", [_vm._v("审核流程设置")])
            ],
            1
          ),
          _c("div")
        ],
        1
      ),
      [
        _c(
          "el-table",
          {
            attrs: {
              width: "100%",
              data: _vm.processList,
              border: "",
              "header-cell-style": {
                background: "#F9F9F9",
                borderColor: "#F9F9F9",
                color: "#333"
              },
              size: "small"
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "flow_type_name",
                label: "流程名称",
                width: "200",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: { prop: "configText", label: "流程步骤" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                            "justify-content": "flex-start",
                            "align-items": "center",
                            "flex-wrap": "wrap"
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _vm._v(" 发起人："),
                              _vm._l(row.request_user_list, function(
                                row1,
                                idx1
                              ) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: "request-user" + idx1,
                                    staticStyle: { margin: "5px" },
                                    attrs: { size: "mini" }
                                  },
                                  [_vm._v(_vm._s(row1.name))]
                                )
                              })
                            ],
                            2
                          ),
                          _vm._l(row.examine_user_list, function(row1, idx1) {
                            return _c(
                              "div",
                              { key: "examine-user-list" + idx1 },
                              [
                                _vm._v(" 审核人" + _vm._s(idx1 + 1) + "： "),
                                _vm._l(row1, function(row2, idx2) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: "examine-user" + idx2,
                                      staticStyle: { margin: "5px" },
                                      attrs: { size: "mini" }
                                    },
                                    [_vm._v(_vm._s(row2.name))]
                                  )
                                })
                              ],
                              2
                            )
                          })
                        ],
                        2
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "是否启用", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: {
                          "active-value": 1,
                          "inactive-value": 2,
                          "active-color": "#409EFF",
                          "inactive-color": "#999"
                        },
                        on: {
                          change: function($event) {
                            return _vm.statusChange(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.status,
                          callback: function($$v) {
                            _vm.$set(scope.row, "status", $$v)
                          },
                          expression: "scope.row.status"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { width: "100", label: "操作", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.rowEdit(row)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      _c("ProcessComponents", {
        ref: "ProcessComponents",
        on: { confirm: _vm.loadData }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }