import Vue from 'vue';
import Vuex from 'vuex';
import d2admin from './modules/d2admin';
import financial from './modules/financial';
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    d2admin: d2admin,
    financial: financial
  }
});