var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      class: "dropHeaderTable " + _vm.cacheName,
      staticStyle: { width: "200px" },
      attrs: {
        size: "mini",
        "row-key": "prop",
        "max-height": "400",
        data: _vm.remoteColumnsList,
        "show-header": false
      }
    },
    [
      _c("el-table-column", {
        attrs: {
          prop: "label",
          label: "列名",
          align: "left",
          "show-message-tooltip": ""
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-right": "10px",
                          width: "18px",
                          height: "100%",
                          "text-align": "left",
                          display: "flex",
                          "align-items": "center"
                        }
                      },
                      [
                        _c("d2-icon-svg", {
                          staticStyle: {
                            width: "18px",
                            height: "18px",
                            fill: "#666",
                            cursor: "move"
                          },
                          attrs: { name: "ic_drag" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          flex: "1",
                          overflow: "hidden",
                          "white-space": "nowrap",
                          "text-overflow": "ellipsis"
                        }
                      },
                      [_vm._v(_vm._s(scope.row.label))]
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { width: "60", label: "显示", align: "center" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("el-switch", {
                  attrs: { disabled: scope.row.prop === "index" },
                  on: { change: _vm.saveTableSet },
                  model: {
                    value: scope.row.checked,
                    callback: function($$v) {
                      _vm.$set(scope.row, "checked", $$v)
                    },
                    expression: "scope.row.checked"
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }