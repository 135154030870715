import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getReturnWarehouseInfoTemplate } from '@/api/stock.js';
import util from '@/libs/util.js';
import axios from 'axios';
import format from '@/libs/util.format';
import dayjs from 'dayjs';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: 'Excel产品明细导入',
      dialogVisible: false,
      uploadFileType: ['.xls', '.xlsx'],
      uploadURL: '',
      uploadToken: '',
      loading: false,
      errorMsg: null,
      successMsg: null,
      warehouseId: null
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init(form) {
      var that = this;
      that.dialogVisible = true;
      that.errorMsg = null;
      that.successMsg = null;
      that.uploadURL = process.env.VUE_APP_API + '/erpReturnWarehouse/uploadExcel?isRet=1&warehouseId=' + form.warehouseId + '&manufacturerId=' + form.manufacturerId;
      that.uploadToken = {
        Authorization: util.cookies.get('token'),
        Os: 'web'
      };
      that.warehouseId = form.warehouseId;
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    myUpload: function myUpload(content) {
      var _this = this;

      var that = this;
      that.errorMsg = null;
      that.successMsg = null;
      var form = new FormData();
      form.append('file', content.file); // form.append('warehouseId', that.warehouseId)

      var config = {
        headers: content.headers,
        timeout: 1000 * 180
      };

      if (content.file.size / 1024 / 1024 > 30) {
        that.errorMsg = '不允许上传大于30MB的文件';
        that.$message.error(that.errorMsg);
        return false;
      }

      var FileExt = content.file.name.replace(/.+\./, '');

      if (that.uploadFileType.indexOf('.' + FileExt.toLowerCase()) === -1) {
        that.errorMsg = '不允许上传此类型文件';
        that.$message.error(that.errorMsg);
        return false;
      }

      this.loading = true;
      axios.post(content.action, form, config).then(function (res) {
        if (res.data.code !== 0) {
          that.errorMsg = res.data.msg;
          that.$message.error(that.errorMsg);
        } else {
          // that.$notify.success(res.data.msg)
          that.successMsg = res.data.msg;
          var listData = that.detailListFormat(res.data.data.list);
          that.$emit('add', listData);
        }
      }).catch(function (error) {
        console.log(error);

        if (error.response) {
          that.errorMsg = '文件上传失败,' + error.response.data;
        } else if (error.request) {
          that.errorMsg = '文件上传失败，服务器端无响应';
        } else {
          that.errorMsg = '文件上传失败，请求封装失败';
        }

        that.$message.error(that.errorMsg);
      }).finally(function () {
        _this.loading = false;
      });
    },
    detailListFormat: function detailListFormat(rows) {
      var that = this;
      var resultRows = [];
      rows.forEach(function (row, index) {
        resultRows.push({
          ID: row.ID,
          productNumber: row.productNumber,
          batchNumber: row.batchNumber,
          productPlace: row.main.productPlace,
          brandName: row.main.brandName,
          productName: row.main.productName,
          inWarehouseQuantity: row.inWarehouseQuantity,
          specificationModel: row.specs.specificationModel,
          trackingCode: row.trackingCode,
          unit: row.specs.unit,
          registrationNumber: row.main.registrationNumber,
          manufacturerDate: row.manufacturerDate,
          validityDate: row.validityDate,
          sterilizationBatchNumber: row.sterilizationBatchNumber,
          sterilizationDate: row.sterilizationDate,
          mainId: row.mainId,
          specsId: row.specsId,
          returnedQuantity: row.returnedQuantity,
          afterTaxPrice: Number(row.afterTaxPrice).toFixed(2),
          normalQuantity: row.inventory.normalQuantity,
          sterilizationWays: row.specs.sterilizationWays,
          inventoryId: row.inventoryId,
          manufacturer: row.main.manufacturer,
          manufacturerName: row.manufacturerName,
          inWarehouseInfoId: row.ID,
          taxRate: row.taxRate
        });
      });
      return resultRows;
    },
    templateDownload: function templateDownload() {
      getReturnWarehouseInfoTemplate({
        fileName: 'goodsStockImport.xlsx'
      }).then(function (res) {
        var blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        }); // type这里表示xlsx类型

        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob); // 创建下载的链接

        downloadElement.href = href;
        downloadElement.download = '模板导入.xlsx'; // 下载后文件名

        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载

        document.body.removeChild(downloadElement); // 下载完成移除元素

        window.URL.revokeObjectURL(href); // 释放掉blob对象
      });
    },
    // 是否过期
    isExpire: function isExpire(sdate) {
      if (sdate) {
        if (format.isDate(sdate)) {
          if (dayjs().isBefore(dayjs(sdate))) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    expireDays: function expireDays(sdate) {
      return sdate ? Math.ceil(dayjs(sdate).diff(dayjs(), 'days', true)) : 9999;
    }
  }
};