var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "1000px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                border: "",
                size: "small",
                data: _vm.productList,
                "max-height": "500"
              }
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", width: "50", align: "center" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "brandName",
                  label: "品牌",
                  "min-width": "100",
                  align: "center",
                  "show-message-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "productName",
                  label: "产品名称",
                  "min-width": "100",
                  align: "center",
                  "show-message-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "productNumber",
                  label: "产品编号",
                  width: "100",
                  align: "center",
                  "show-message-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "registrationNumber",
                  label: "注册证号",
                  "min-width": "100",
                  align: "center",
                  "show-message-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specificationModel",
                  label: "规格型号",
                  width: "100",
                  align: "center",
                  "show-message-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "manufacturer",
                  label: "生产商",
                  "min-width": "100",
                  align: "center",
                  "show-message-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "quantity",
                  label: "数量",
                  width: "100",
                  align: "center",
                  "show-message-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "100", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.selectProduct(scope.row)
                                }
                              }
                            },
                            [_vm._v("获取")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  598601044
                )
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: { "margin-top": "10px" },
              attrs: { align: "right" }
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function($event) {
                      return _vm.selectProduct()
                    }
                  }
                },
                [_vm._v("一键获得库存耗材")]
              )
            ],
            1
          ),
          _c("SelectProductComponent", {
            ref: "SelectProductComponentRef",
            on: { confirm: _vm.selectConfirm }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }