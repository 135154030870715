var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "1200px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "modal-append-to-body": true,
            "append-to-body": true,
            fullscreen: _vm.dialogFullScreen
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "custom_dialog_header" }, [
              _c("span", { staticClass: "el_dialog_title" }, [
                _vm._v(_vm._s(_vm.dialogTitle))
              ]),
              _c(
                "div",
                {
                  staticClass: "custom_dialog_menu",
                  on: {
                    click: function($event) {
                      _vm.dialogFullScreen = !_vm.dialogFullScreen
                    }
                  }
                },
                [
                  !_vm.dialogFullScreen
                    ? _c("i", {
                        staticClass: "fa fa-window-maximize",
                        attrs: { "aria-hidden": "true" }
                      })
                    : _vm._e(),
                  _vm.dialogFullScreen
                    ? _c("i", {
                        staticClass: "fa fa-window-restore",
                        attrs: { "aria-hidden": "true" }
                      })
                    : _vm._e()
                ]
              )
            ])
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dbLoading,
                  expression: "dbLoading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.dbList,
                size: "small",
                border: "",
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                },
                "cell-class-name": _vm.cellClassName
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "50",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "regNoCn",
                  label: "注册证编号",
                  width: "200",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "regProductName",
                  label: "产品名称",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "regDate",
                  label: "注册日期",
                  width: "200",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "expirationDate",
                  label: "有效期至",
                  width: "200",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "validityCategory",
                  label: "预警提醒",
                  width: "200",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.expirationDay < 0
                            ? _c("span", [
                                _vm._v(
                                  "已过期" +
                                    _vm._s(Math.abs(scope.row.expirationDay)) +
                                    "天"
                                )
                              ])
                            : _vm._e(),
                          scope.row.expirationDay >= 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(scope.row.expirationDay) + "天后过期"
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1093808979
                )
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "180", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function($event) {
                                  return _vm.toRetailer(row)
                                }
                              }
                            },
                            [_vm._v("跳转至该注册证页面")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3190466914
                )
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "prev, pager, next, total",
                  align: "center",
                  total: _vm.form.total,
                  "current-page": _vm.form.page,
                  "page-size": _vm.form.pageSize
                },
                on: { "current-change": _vm.pageChange }
              })
            ],
            1
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }