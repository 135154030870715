var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sample-barcode" }, [
    _vm._m(0),
    _c(
      "div",
      { staticStyle: { "margin-bottom": "5px" } },
      [
        _c("el-input", {
          attrs: { type: "text", size: "medium", clearable: "" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.barcodeParse()
            }
          },
          model: {
            value: _vm.sampleBarcodeString,
            callback: function($$v) {
              _vm.sampleBarcodeString = $$v
            },
            expression: "sampleBarcodeString"
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "scale" }, [
      _c(
        "div",
        { staticClass: "line" },
        _vm._l(50, function(i) {
          return _c(
            "span",
            {
              key: "num" + i,
              class: _vm.dangerListIdx.includes(i) ? "bg-danger" : ""
            },
            [_vm._v("▲")]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "num" },
        _vm._l(50, function(i) {
          return _c(
            "span",
            {
              key: "num" + i,
              class: _vm.dangerListIdx.includes(i) ? "bg-danger" : ""
            },
            [_vm._v(_vm._s(i))]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "line" },
        _vm._l(50, function(i) {
          return _c(
            "span",
            {
              key: "num" + i,
              class: _vm.dangerListIdx.includes(i) ? "bg-danger" : ""
            },
            [_vm._v("▼")]
          )
        }),
        0
      )
    ]),
    _c(
      "div",
      { staticClass: "barcode" },
      _vm._l(_vm.sampleBarcodeItems, function(item, index) {
        return _c("span", { key: "span" + index, class: item.class }, [
          _vm._v(_vm._s(item.char))
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "space-between",
          "align-items": "center"
        }
      },
      [
        _c("span", { staticStyle: { "font-weight": "bold" } }, [
          _vm._v("PI码样例")
        ]),
        _c(
          "span",
          {
            staticStyle: {
              "font-size": "12px",
              color: "#f00",
              "text-align": "right"
            }
          },
          [_vm._v("扫码/手动输入PI码（仅供参考）")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }