var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "1200px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "modal-append-to-body": true,
            "append-to-body": true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: { align: "right" }
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-download"
                  },
                  on: {
                    click: function($event) {
                      return _vm.dataExport()
                    }
                  }
                },
                [_vm._v("导出")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dbLoading,
                  expression: "dbLoading"
                }
              ],
              staticStyle: {
                width: "100%",
                height: "460px",
                "overflow-y": "auto"
              },
              attrs: {
                data: _vm.dbList,
                size: "small",
                border: "",
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                },
                "cell-class-name": _vm.cellClassName
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "50",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "warehouse.name",
                  label: "仓库",
                  "min-width": "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "main.productName",
                  label: "产品名称",
                  "min-width": "200",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "stockQuantity",
                  label: "库存数量",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "normalQuantity",
                  label: "可用数量",
                  width: "100",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.normalQuantityFormatter(row)
                              )
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2744077574
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "lowInventory",
                  label: "库存低储",
                  width: "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "highInventory",
                  label: "库存高储",
                  width: "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "150", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.toStock(row)
                                }
                              }
                            },
                            [_vm._v("跳转至该库存页面")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1105889242
                )
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "sizes, prev, pager, next, total",
                  align: "center",
                  total: _vm.form.total,
                  "current-page": _vm.form.page,
                  "page-size": _vm.form.pageSize
                },
                on: {
                  "size-change": _vm.sizeChange,
                  "current-change": _vm.pageChange
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }