var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("入库管理")]),
              _c("el-breadcrumb-item", [_vm._v("采购入库")]),
              _c("el-breadcrumb-item", [
                _vm._v(
                  "入库单详情（" +
                    _vm._s(_vm.action === "edit" ? "修改" : "新增") +
                    "）"
                )
              ])
            ],
            1
          ),
          _c("div", [
            _vm.orderInfo.orderNumber
              ? _c("span", { staticStyle: { "font-size": "14px" } }, [
                  _vm._v("入库单号：" + _vm._s(_vm.orderInfo.orderNumber))
                ])
              : _vm._e()
          ])
        ],
        1
      ),
      [
        _c(
          "el-popover",
          {
            ref: "shelfNumberPopover",
            attrs: { placement: "right-start", width: "250", trigger: "click" },
            model: {
              value: _vm.shelfNumberVisible,
              callback: function($$v) {
                _vm.shelfNumberVisible = $$v
              },
              expression: "shelfNumberVisible"
            }
          },
          [
            _c("ShelfComponents", {
              ref: "ShelfComponents",
              on: { select: _vm.shelfSelect }
            })
          ],
          1
        ),
        _c(
          "el-popover",
          {
            ref: "locationNumberPopover",
            attrs: { placement: "right-start", width: "300", trigger: "click" },
            model: {
              value: _vm.locationNumberVisible,
              callback: function($$v) {
                _vm.locationNumberVisible = $$v
              },
              expression: "locationNumberVisible"
            }
          },
          [
            _c("LocationComponents", {
              ref: "LocationComponents",
              on: { select: _vm.locationSelect }
            })
          ],
          1
        ),
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "100px",
              "show-message": false,
              "label-suffix": ":",
              disabled: _vm.form.inWarehouseStatus > 1,
              loading: _vm.formLoading
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "供应商", prop: "manufacturerId" } },
                      [
                        _c(
                          "el-select",
                          {
                            ref: "manufacturerIdRef",
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled:
                                _vm.orderInfo.intelligent === 0 ? true : false
                            },
                            on: { change: _vm.reSetTaxRate2 },
                            model: {
                              value: _vm.form.manufacturerId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "manufacturerId", $$v)
                              },
                              expression: "form.manufacturerId"
                            }
                          },
                          _vm._l(_vm.manufacturerList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: {
                                label: item.name,
                                value: item.ID,
                                disabled: !item.show_status
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "入库人", prop: "inWarehouseUid" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.inWarehouseUid,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "inWarehouseUid", $$v)
                              },
                              expression: "form.inWarehouseUid"
                            }
                          },
                          _vm._l(_vm.userList, function(item) {
                            return _c("el-option", {
                              key: "inWarehouseUid-" + item.uid,
                              attrs: { label: item.name, value: item.uid }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "入库时间", prop: "inWarehouseTime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetime",
                            clearable: "",
                            placeholder: "选择日期时间",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            format: "yyyy-MM-dd HH:mm:ss"
                          },
                          model: {
                            value: _vm.form.inWarehouseTime,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "inWarehouseTime", $$v)
                            },
                            expression: "form.inWarehouseTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库", prop: "warehouseId" } },
                      [
                        _vm.form.intelligent === 1
                          ? _c(
                              "el-select",
                              {
                                ref: "warehouseId",
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: _vm.form.warehouseId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "warehouseId", $$v)
                                  },
                                  expression: "form.warehouseId"
                                }
                              },
                              _vm._l(_vm.warehouseList, function(item) {
                                return _c(
                                  "el-option",
                                  {
                                    key: item.ID,
                                    attrs: {
                                      label: item.name,
                                      value: item.ID,
                                      disabled: item.status === 0
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "space-between"
                                        }
                                      },
                                      [
                                        _c("span", [_vm._v(_vm._s(item.name))]),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  item.status === 0
                                                    ? true
                                                    : false,
                                                expression:
                                                  "item.status===0?true:false"
                                              }
                                            ],
                                            staticClass: "text-danger"
                                          },
                                          [_vm._v("(无效)")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderInfo.warehouseName))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "归属公司", prop: "subsidiaryId" } },
                      [
                        _c(
                          "el-select",
                          {
                            ref: "subsidiaryIdRef",
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled:
                                _vm.orderInfo.intelligent === 0 &&
                                _vm.form.subsidiaryId
                                  ? true
                                  : false
                            },
                            on: { change: _vm.reSetTaxRate },
                            model: {
                              value: _vm.form.subsidiaryId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "subsidiaryId", $$v)
                              },
                              expression: "form.subsidiaryId"
                            }
                          },
                          _vm._l(_vm.subsidiaryList, function(item) {
                            return _c("el-option", {
                              key: "subsidiaryId" + item.ID,
                              attrs: {
                                label: item.companyName,
                                value: item.ID,
                                disabled: item.isValid === 0
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 18 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "备注",
                            maxlength: "500",
                            "suffix-icon": "el-icon-edit",
                            clearable: ""
                          },
                          model: {
                            value: _vm.form.remark,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0"
            }
          },
          [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _vm.form.intelligent !== 1
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-tag",
                          {
                            staticStyle: { "margin-right": "20px" },
                            attrs: { type: "danger", size: "small" }
                          },
                          [_vm._v("GSP")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticStyle: { "margin-right": "10px" } },
                  [
                    _vm.form.intelligent === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "mini",
                              icon: "el-icon-plus",
                              disabled: _vm.form.inWarehouseStatus !== 1
                            },
                            on: { click: _vm.selectSpecs }
                          },
                          [_vm._v("增加产品 ")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "danger",
                          size: "mini",
                          disabled: _vm.form.inWarehouseStatus !== 1
                        },
                        on: { click: _vm.scanInput }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-barcode",
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v(" 扫码录入 ")
                      ]
                    ),
                    _vm.form.intelligent === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "mini",
                              disabled: _vm.form.inWarehouseStatus !== 1
                            },
                            on: { click: _vm.scanInput2 }
                          },
                          [_vm._v("斯潘威随货同行单扫码导入")]
                        )
                      : _vm._e(),
                    _vm.form.intelligent === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "mini",
                              disabled: _vm.form.inWarehouseStatus !== 1
                            },
                            on: { click: _vm.scanInput3 }
                          },
                          [_vm._v("YTIMS出库单扫码导入")]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "el-link",
                  {
                    attrs: { type: "primary", icon: "el-icon-menu" },
                    on: { click: _vm.showBatchList }
                  },
                  [_vm._v("批号列表显示")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center"
                }
              },
              [
                _c("div", { staticStyle: { "font-size": "12px" } }, [
                  _vm.activeTabId === 1
                    ? _c("span", { staticStyle: { "margin-right": "10px" } }, [
                        _vm._v(" 总金额（不含税）："),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#f00",
                              "font-weight": "bold"
                            }
                          },
                          [_vm._v(_vm._s(_vm.totalAmountExclusive()))]
                        )
                      ])
                    : _vm._e(),
                  _c("span", { staticStyle: { "margin-right": "10px" } }, [
                    _vm._v(" 总金额（含税）："),
                    _c(
                      "span",
                      { staticStyle: { color: "#f00", "font-weight": "bold" } },
                      [_vm._v(_vm._s(_vm.totalAmount()))]
                    )
                  ]),
                  _vm.activeTabId === 1
                    ? _c("span", { staticStyle: { "margin-right": "10px" } }, [
                        _vm._v(" 税额合计："),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#f00",
                              "font-weight": "bold"
                            }
                          },
                          [_vm._v(_vm._s(_vm.totalAmountTax()))]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" 总数量（"),
                  _c(
                    "span",
                    { staticStyle: { color: "#f00", "font-weight": "bold" } },
                    [_vm._v(_vm._s(_vm.totalQuantity()))]
                  ),
                  _vm._v("） ")
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          disabled: !_vm.form.id
                        },
                        on: { click: _vm.orderPrint }
                      },
                      [
                        _c("i", { staticClass: "fa fa-print" }),
                        _vm._v(" 打印入库单")
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ]
        ),
        _c(
          "stickySlot",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showScanInputBox,
                expression: "showScanInputBox"
              }
            ],
            staticClass: "stickyTop"
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  margin: "10px 0",
                  background: "#fff",
                  display: "flex",
                  "align-items": "center"
                }
              },
              [
                _c("div", { staticStyle: { width: "450px" } }, [
                  _c("input", {
                    ref: "scanInputBoxRef",
                    staticClass: "bar-input",
                    attrs: { placeholder: "请扫描产品条码", clearable: "" },
                    on: { change: _vm.barCodeSearch }
                  })
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#f00",
                      flex: "1",
                      "margin-left": "20px"
                    }
                  },
                  [_vm._v(_vm._s(_vm.tipMessage))]
                )
              ]
            )
          ]
        ),
        _c(
          "el-form",
          {
            ref: "detailListForm",
            attrs: { model: _vm.detailListForm, rules: _vm.detailListFormRules }
          },
          [
            _vm.returnList.length > 0
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "tab-list-grey",
                      staticStyle: { margin: "0" }
                    },
                    [
                      _c(
                        "div",
                        {
                          class:
                            _vm.activeTabId === 1
                              ? "tab-item hover"
                              : "tab-item",
                          on: {
                            click: function($event) {
                              return _vm.tabChange(1)
                            }
                          }
                        },
                        [_vm._v("入库单详情")]
                      ),
                      _c(
                        "div",
                        {
                          class:
                            _vm.activeTabId === 2
                              ? "tab-item hover"
                              : "tab-item",
                          on: {
                            click: function($event) {
                              return _vm.tabChange(2)
                            }
                          }
                        },
                        [_vm._v("退货详情")]
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeTabId === 1,
                    expression: "activeTabId===1"
                  }
                ]
              },
              [
                _c(
                  "el-table",
                  {
                    key: "detailListTable" + _vm.timestamp,
                    ref: "detailListTableRef",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.detailList.slice(
                        (_vm.detailListForm.page - 1) *
                          _vm.detailListForm.pageSize,
                        _vm.detailListForm.page * _vm.detailListForm.pageSize
                      ),
                      "row-key": "rowKey",
                      border: "",
                      size: "small",
                      "header-cell-style": {
                        background: "#f5f7fa",
                        borderColor: "#ebeef5",
                        color: "#333"
                      },
                      "highlight-current-row": "",
                      "expand-row-keys": _vm.expandRowKeys,
                      "row-class-name": _vm.rowClassName
                    },
                    on: {
                      "expand-change": _vm.expandChange,
                      "row-click": _vm.detailRowClick,
                      "cell-mouse-enter": _vm.enter,
                      "cell-mouse-leave": _vm.leave
                    }
                  },
                  [
                    _c("el-table-column", {
                      staticStyle: { padding: "0" },
                      attrs: { type: "expand", width: "50" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(props) {
                            return [
                              _c(
                                "el-table",
                                {
                                  ref: "table-" + props.row.rowKey,
                                  attrs: {
                                    "max-height": "340",
                                    size: "small",
                                    "row-class-name": _vm.batchRowClassName,
                                    data: props.row.productList,
                                    "row-key": "rowKey"
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      type: "index",
                                      index: function(index) {
                                        return _vm.indexSort(
                                          index,
                                          props.row.productList.length
                                        )
                                      },
                                      width: "50",
                                      label: "#",
                                      align: "center"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "80",
                                      align: "center",
                                      label: "操作",
                                      prop: "operations"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope2) {
                                            return [
                                              _c(
                                                "el-link",
                                                {
                                                  attrs: {
                                                    type: "danger",
                                                    disabled:
                                                      _vm.form
                                                        .inWarehouseStatus !== 1
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.productRowRemove(
                                                        scope2.row,
                                                        props.row
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("删除")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      "min-width": "100",
                                      align: "center",
                                      label: "批号",
                                      prop: "batchNumber"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope2) {
                                            return [
                                              _vm.form.inWarehouseStatus === 1
                                                ? _c("el-input", {
                                                    attrs: { size: "mini" },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.batchSave(
                                                          scope2.row,
                                                          props.row
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        scope2.row.batchNumber,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          scope2.row,
                                                          "batchNumber",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope2.row.batchNumber"
                                                    }
                                                  })
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope2.row.batchNumber
                                                      )
                                                    )
                                                  ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      "min-width": "100",
                                      align: "center",
                                      label: "序列号",
                                      prop: "trackingCode"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope2) {
                                            return [
                                              _vm.form.inWarehouseStatus === 1
                                                ? _c("el-input", {
                                                    attrs: { size: "mini" },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.batchSave(
                                                          scope2.row,
                                                          props.row
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        scope2.row.trackingCode,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          scope2.row,
                                                          "trackingCode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope2.row.trackingCode"
                                                    }
                                                  })
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope2.row.trackingCode
                                                      )
                                                    )
                                                  ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "100",
                                      align: "center",
                                      label: "数量",
                                      prop: "inWarehouseQuantity",
                                      "class-name": "input-number"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope2) {
                                            return [
                                              _vm.form.inWarehouseStatus === 1
                                                ? _c("el-input", {
                                                    directives: [
                                                      {
                                                        name: "enter-number",
                                                        rawName:
                                                          "v-enter-number",
                                                        value: {
                                                          precision: 0,
                                                          min: 0
                                                        },
                                                        expression:
                                                          "{precision:0,min:0}"
                                                      }
                                                    ],
                                                    attrs: { size: "mini" },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.batchSave(
                                                          scope2.row,
                                                          props.row
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        scope2.row
                                                          .inWarehouseQuantity,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          scope2.row,
                                                          "inWarehouseQuantity",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope2.row.inWarehouseQuantity"
                                                    }
                                                  })
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope2.row
                                                          .inWarehouseQuantity
                                                      )
                                                    )
                                                  ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "170",
                                      align: "center",
                                      label: "生产日期",
                                      prop: "manufacturerDate"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope2) {
                                            return [
                                              _c(
                                                "div",
                                                [
                                                  _c("el-date-picker", {
                                                    staticStyle: {
                                                      position: "absolute",
                                                      "z-index": "-1"
                                                    },
                                                    attrs: {
                                                      "picker-options":
                                                        _vm.pickerOptions,
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                      format: "yyyy-MM-dd",
                                                      id:
                                                        "manufacturerDate-" +
                                                        scope2.row.rowKey
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.batchSave(
                                                          scope2.row,
                                                          props.row
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        scope2.row
                                                          .manufacturerDate,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          scope2.row,
                                                          "manufacturerDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope2.row.manufacturerDate"
                                                    }
                                                  }),
                                                  _vm.form.inWarehouseStatus ===
                                                  1
                                                    ? _c(
                                                        "el-input",
                                                        {
                                                          attrs: {
                                                            size: "mini"
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.batchSave(
                                                                scope2.row,
                                                                props.row
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              scope2.row
                                                                .manufacturerDate,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope2.row,
                                                                "manufacturerDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope2.row.manufacturerDate"
                                                          }
                                                        },
                                                        [
                                                          _c("el-button", {
                                                            staticStyle: {
                                                              padding: "10px"
                                                            },
                                                            attrs: {
                                                              slot: "append",
                                                              icon:
                                                                "el-icon-date"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.showDatePicker(
                                                                  "manufacturerDate-" +
                                                                    scope2.row
                                                                      .rowKey
                                                                )
                                                              }
                                                            },
                                                            slot: "append"
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope2.row
                                                              .manufacturerDate
                                                          )
                                                        )
                                                      ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "170",
                                      align: "center",
                                      label: "有效期",
                                      prop: "validityDate"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope2) {
                                            return [
                                              _c(
                                                "div",
                                                [
                                                  _c("el-date-picker", {
                                                    staticStyle: {
                                                      position: "absolute",
                                                      "z-index": "-1"
                                                    },
                                                    attrs: {
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                      format: "yyyy-MM-dd",
                                                      id:
                                                        "validityDate-" +
                                                        scope2.row.rowKey
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.batchSave(
                                                          scope2.row,
                                                          props.row
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        scope2.row.validityDate,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          scope2.row,
                                                          "validityDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope2.row.validityDate"
                                                    }
                                                  }),
                                                  _vm.form.inWarehouseStatus ===
                                                  1
                                                    ? _c(
                                                        "el-input",
                                                        {
                                                          attrs: {
                                                            size: "mini"
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.batchSave(
                                                                scope2.row,
                                                                props.row
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              scope2.row
                                                                .validityDate,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope2.row,
                                                                "validityDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope2.row.validityDate"
                                                          }
                                                        },
                                                        [
                                                          _c("el-button", {
                                                            staticStyle: {
                                                              padding: "10px"
                                                            },
                                                            attrs: {
                                                              slot: "append",
                                                              icon:
                                                                "el-icon-date"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.showDatePicker(
                                                                  "validityDate-" +
                                                                    scope2.row
                                                                      .rowKey
                                                                )
                                                              }
                                                            },
                                                            slot: "append"
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope2.row
                                                              .validityDate
                                                          )
                                                        )
                                                      ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "150",
                                      align: "center",
                                      label: "灭菌批号",
                                      prop: "sterilizationBatchNumber"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope2) {
                                            return [
                                              _vm.form.inWarehouseStatus === 1
                                                ? _c("el-input", {
                                                    attrs: { size: "mini" },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.batchSave(
                                                          scope2.row,
                                                          props.row
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        scope2.row
                                                          .sterilizationBatchNumber,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          scope2.row,
                                                          "sterilizationBatchNumber",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope2.row.sterilizationBatchNumber"
                                                    }
                                                  })
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope2.row
                                                          .sterilizationBatchNumber
                                                      )
                                                    )
                                                  ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "170",
                                      align: "center",
                                      label: "灭菌效期",
                                      prop: "sterilizationDate"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope2) {
                                            return [
                                              _c(
                                                "div",
                                                [
                                                  _c("el-date-picker", {
                                                    staticStyle: {
                                                      position: "absolute",
                                                      "z-index": "-1"
                                                    },
                                                    attrs: {
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                      format: "yyyy-MM-dd",
                                                      id:
                                                        "sterilizationDate-" +
                                                        scope2.row.rowKey
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.batchSave(
                                                          scope2.row,
                                                          props.row
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        scope2.row
                                                          .sterilizationDate,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          scope2.row,
                                                          "sterilizationDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope2.row.sterilizationDate"
                                                    }
                                                  }),
                                                  _vm.form.inWarehouseStatus ===
                                                  1
                                                    ? _c(
                                                        "el-input",
                                                        {
                                                          attrs: {
                                                            size: "mini"
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.batchSave(
                                                                scope2.row,
                                                                props.row
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              scope2.row
                                                                .sterilizationDate,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope2.row,
                                                                "sterilizationDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope2.row.sterilizationDate"
                                                          }
                                                        },
                                                        [
                                                          _c("el-button", {
                                                            staticStyle: {
                                                              padding: "10px"
                                                            },
                                                            attrs: {
                                                              slot: "append",
                                                              icon:
                                                                "el-icon-date"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.showDatePicker(
                                                                  "sterilizationDate-" +
                                                                    scope2.row
                                                                      .rowKey
                                                                )
                                                              }
                                                            },
                                                            slot: "append"
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope2.row
                                                              .sterilizationDate
                                                          )
                                                        )
                                                      ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "150",
                                      align: "center",
                                      label: "货架",
                                      prop: "shelfName"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope2) {
                                            return [
                                              _vm.form.inWarehouseStatus === 1
                                                ? _c(
                                                    "el-input",
                                                    {
                                                      attrs: { size: "mini" },
                                                      model: {
                                                        value:
                                                          scope2.row.shelfName,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope2.row,
                                                            "shelfName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope2.row.shelfName"
                                                      }
                                                    },
                                                    [
                                                      _c("el-button", {
                                                        staticStyle: {
                                                          padding: "0 10px"
                                                        },
                                                        attrs: {
                                                          slot: "append",
                                                          icon:
                                                            "el-icon-caret-bottom"
                                                        },
                                                        on: {
                                                          click: function(
                                                            e,
                                                            row
                                                          ) {
                                                            return _vm.showShelf(
                                                              e,
                                                              scope2.row
                                                            )
                                                          }
                                                        },
                                                        slot: "append"
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope2.row.shelfName
                                                      )
                                                    )
                                                  ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "150",
                                      align: "center",
                                      label: "货位",
                                      prop: "locationNumber"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope2) {
                                            return [
                                              _vm.form.inWarehouseStatus === 1
                                                ? _c(
                                                    "el-input",
                                                    {
                                                      attrs: { size: "mini" },
                                                      model: {
                                                        value:
                                                          scope2.row
                                                            .locationNumber,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope2.row,
                                                            "locationNumber",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope2.row.locationNumber"
                                                      }
                                                    },
                                                    [
                                                      _c("el-button", {
                                                        staticStyle: {
                                                          padding: "0 10px"
                                                        },
                                                        attrs: {
                                                          slot: "append",
                                                          icon:
                                                            "el-icon-caret-bottom"
                                                        },
                                                        on: {
                                                          click: function(
                                                            e,
                                                            row
                                                          ) {
                                                            return _vm.showLocation(
                                                              e,
                                                              scope2.row
                                                            )
                                                          }
                                                        },
                                                        slot: "append"
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope2.row
                                                          .locationNumber
                                                      )
                                                    )
                                                  ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { width: "80", align: "center", label: "序号" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-popover",
                                {
                                  ref: "popover" + scope.row.rowIndex,
                                  attrs: {
                                    trigger: "hover",
                                    placement: "right",
                                    disabled: false
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { color: "#f00" } },
                                    [_vm._v("请添加批次数据")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        id: "scrollView" + scope.$index
                                      },
                                      slot: "reference"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.indexMethod(
                                            scope.$index,
                                            _vm.detailListForm
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        align: "center",
                        label: "品牌",
                        prop: "brandName",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        align: "center",
                        label: "产品编号",
                        prop: "productNumber",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        "min-width": "150",
                        align: "center",
                        label: "物资名称",
                        prop: "productName",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        "min-width": "100",
                        align: "center",
                        label: "规格型号",
                        prop: "specificationModel",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _vm.profile.settings.processMode === 1
                      ? _c("el-table-column", {
                          attrs: {
                            width: "100",
                            align: "center",
                            label: "验收合格数",
                            prop: "qualifiedQuantity"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm.form.inWarehouseStatus === 1
                                      ? _c("el-input", {
                                          directives: [
                                            {
                                              name: "enter-number",
                                              rawName: "v-enter-number",
                                              value: { precision: 0, min: 0 },
                                              expression: "{precision:0,min:0}"
                                            }
                                          ],
                                          attrs: { size: "mini" },
                                          on: {
                                            change: function($event) {
                                              return _vm.detailSaveOne(
                                                scope.row
                                              )
                                            }
                                          },
                                          model: {
                                            value: scope.row.qualifiedQuantity,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "qualifiedQuantity",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.qualifiedQuantity"
                                          }
                                        })
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.qualifiedQuantity)
                                          )
                                        ])
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1101195285
                          )
                        })
                      : _vm._e(),
                    _vm.profile.settings.processMode === 1
                      ? _c("el-table-column", {
                          attrs: {
                            "class-name": "input-number",
                            width: "100",
                            align: "center",
                            label: "入库数",
                            prop: "receiptQuantity"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm.form.inWarehouseStatus === 1
                                      ? _c("el-input", {
                                          directives: [
                                            {
                                              name: "enter-number",
                                              rawName: "v-enter-number",
                                              value: { precision: 0, min: 0 },
                                              expression: "{precision:0,min:0}"
                                            }
                                          ],
                                          attrs: { size: "mini" },
                                          on: {
                                            change: function($event) {
                                              return _vm.detailSaveOne(
                                                scope.row
                                              )
                                            }
                                          },
                                          model: {
                                            value: scope.row.receiptQuantity,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "receiptQuantity",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "scope.row.receiptQuantity"
                                          }
                                        })
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.receiptQuantity)
                                          )
                                        ])
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1174711397
                          )
                        })
                      : _vm._e(),
                    _vm.profile.settings.processMode !== 1
                      ? _c("el-table-column", {
                          attrs: {
                            "class-name": "input-number",
                            width: "100",
                            align: "center",
                            label: "入库数",
                            prop: "qualifiedQuantity"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm.form.inWarehouseStatus === 1 &&
                                    _vm.form.intelligent === 1
                                      ? _c("el-input", {
                                          directives: [
                                            {
                                              name: "enter-number",
                                              rawName: "v-enter-number",
                                              value: { precision: 0, min: 0 },
                                              expression: "{precision:0,min:0}"
                                            }
                                          ],
                                          staticStyle: {
                                            "text-align": "center"
                                          },
                                          attrs: { size: "mini" },
                                          on: {
                                            change: function($event) {
                                              return _vm.detailSaveOne(
                                                scope.row
                                              )
                                            }
                                          },
                                          model: {
                                            value: scope.row.qualifiedQuantity,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "qualifiedQuantity",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "scope.row.qualifiedQuantity"
                                          }
                                        })
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.qualifiedQuantity)
                                          )
                                        ])
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            645538341
                          )
                        })
                      : _vm._e(),
                    _vm.profile.settings.processMode !== 1
                      ? _c("el-table-column", {
                          attrs: {
                            width: "130",
                            align: "center",
                            label: "已加批次数量",
                            prop: "receiptQuantity"
                          }
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: {
                        label: "采购金额(含税)",
                        width: "120",
                        align: "center",
                        prop: "totalAmount"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.sumRowAmount(scope.row)) + " "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "采购金额(不含税)",
                        width: "120",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.sumRowAmountTaxExclusive(scope.row)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm.profile.settings.processMode !== 1
                      ? _c("el-table-column", {
                          attrs: {
                            prop: "afterTaxPrice",
                            label: "采购单价(含税)",
                            width: "160",
                            align: "center"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  var $index = ref.$index
                                  return [
                                    _vm.form.inWarehouseStatus === 1 &&
                                    _vm.form.intelligent === 1
                                      ? _c("el-input", {
                                          directives: [
                                            {
                                              name: "enter-number",
                                              rawName: "v-enter-number",
                                              value: { precision: 2, min: 0 },
                                              expression: "{precision:2,min:0}"
                                            }
                                          ],
                                          ref: "afterTaxPrice-" + $index,
                                          attrs: { size: "mini" },
                                          on: {
                                            change: function($event) {
                                              return _vm.detailSaveOne(row)
                                            }
                                          },
                                          model: {
                                            value: row.afterTaxPrice,
                                            callback: function($$v) {
                                              _vm.$set(
                                                row,
                                                "afterTaxPrice",
                                                $$v
                                              )
                                            },
                                            expression: "row.afterTaxPrice"
                                          }
                                        })
                                      : _c("span", [
                                          _vm._v(_vm._s(row.afterTaxPrice))
                                        ])
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2079169700
                          )
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: {
                        prop: "taxRate",
                        label: "税率",
                        width: "160",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            var $index = ref.$index
                            return [
                              _c(
                                "el-form-item",
                                [
                                  _vm.form.inWarehouseStatus === 1 &&
                                  _vm.form.intelligent === 1
                                    ? _c(
                                        "el-input",
                                        {
                                          directives: [
                                            {
                                              name: "enter-number",
                                              rawName: "v-enter-number",
                                              value: {
                                                precision: 2,
                                                min: 0,
                                                max: 100
                                              },
                                              expression:
                                                "{precision:2,min:0,max:100}"
                                            }
                                          ],
                                          ref: "taxRate-" + $index,
                                          attrs: { size: "mini" },
                                          on: {
                                            change: function($event) {
                                              return _vm.detailSaveOne(row)
                                            }
                                          },
                                          model: {
                                            value: row.taxRate,
                                            callback: function($$v) {
                                              _vm.$set(row, "taxRate", $$v)
                                            },
                                            expression: "row.taxRate"
                                          }
                                        },
                                        [
                                          _c("template", { slot: "append" }, [
                                            _vm._v("%")
                                          ])
                                        ],
                                        2
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            row.taxRate == undefined ||
                                              row.taxRate == null
                                              ? 0
                                              : row.taxRate
                                          ) + "%"
                                        )
                                      ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "税额", width: "100", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.calculateTaxAmount(scope.row)) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "采购单价(不含税)",
                        width: "120",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.calculateTaxPurchaseUnitPrice(scope.row)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        align: "center",
                        label: "单位",
                        prop: "unit"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        "min-width": "150",
                        align: "center",
                        label: "注册证号",
                        prop: "certId",
                        "show-overflow-tooltip": ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm.form.inWarehouseStatus === 1 &&
                              _vm.form.intelligent === 1
                                ? _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        size: "mini",
                                        clearable: "",
                                        placeholder: "请选择注册证号"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.detailSaveOne(row)
                                        }
                                      },
                                      model: {
                                        value: row.certId,
                                        callback: function($$v) {
                                          _vm.$set(row, "certId", $$v)
                                        },
                                        expression: "row.certId"
                                      }
                                    },
                                    _vm._l(row.registrationNumberList, function(
                                      item
                                    ) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: item.ID,
                                          attrs: {
                                            label: item.regNoCn,
                                            value: item.ID
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticStyle: { float: "left" } },
                                            [_vm._v(_vm._s(item.regNoCn))]
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                : _c("span", [
                                    _vm._v(_vm._s(row.registrationNumber))
                                  ])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "200",
                        align: "center",
                        label: "操作",
                        prop: "operations",
                        fixed: "right"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "control-column" },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.expandSwitch2(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("查看批次")]
                                  ),
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        type: "primary",
                                        disabled:
                                          _vm.form.inWarehouseStatus !== 1
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.productRowAdd(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("添加批次")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { margin: "10px 0" } },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        layout: "total, sizes, prev, pager, next, jumper",
                        align: "center",
                        "page-sizes": [10, 20, 50, 100],
                        total: _vm.detailListForm.total,
                        "current-page": _vm.detailListForm.page,
                        "page-size": _vm.detailListForm.pageSize
                      },
                      on: {
                        "size-change": _vm.detailSizeChange,
                        "current-change": _vm.detailListFormPageChange
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeTabId === 2,
                    expression: "activeTabId===2"
                  }
                ]
              },
              [
                _c(
                  "el-table",
                  {
                    key: "returnListTable" + _vm.timestamp,
                    ref: "returnListTableRef",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.returnList.slice(
                        (_vm.returnForm.page - 1) * _vm.returnForm.pageSize,
                        _vm.returnForm.page * _vm.returnForm.pageSize
                      ),
                      border: "",
                      size: "small",
                      "header-cell-style": {
                        background: "#f5f7fa",
                        borderColor: "#ebeef5",
                        color: "#333"
                      },
                      "highlight-current-row": ""
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { width: "80", align: "center", label: "序号" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.returnList.length -
                                      (_vm.returnForm.page - 1) *
                                        _vm.returnForm.pageSize -
                                      scope.$index
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productNumber",
                        label: "产品编号",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "main.productName",
                        label: "产品名称",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "specs.specificationModel",
                        label: "规格型号",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "main.manufacturer",
                        label: "生产厂家",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "inventory.registrationNumber",
                        label: "注册证号",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "inventory.batch_number",
                        label: "批号",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "inventory.trackingCode",
                        label: "序列号",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "specs.unit",
                        label: "单位",
                        width: "100",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "returnWarehouseQuantity",
                        label: "退货数量",
                        width: "100",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "returnPrice",
                        label: "单价",
                        width: "100",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "退货金额",
                        width: "150",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (
                                      row.returnPrice *
                                      row.returnWarehouseQuantity
                                    ).toFixed(2)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { margin: "10px 0" } },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        layout: "total, sizes, prev, pager, next, jumper",
                        align: "center",
                        "page-sizes": [10, 20, 50, 100],
                        total: _vm.returnForm.total,
                        "current-page": _vm.returnForm.page,
                        "page-size": _vm.returnForm.pageSize
                      },
                      on: {
                        "size-change": _vm.returnSizeChange,
                        "current-change": _vm.returnPageChange
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        )
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-breadcrumb",
              {
                staticStyle: {
                  "font-size": "12px",
                  "margin-bottom": "10px",
                  width: "100%"
                },
                attrs: { separator: " " }
              },
              [
                _c("el-breadcrumb-item", [
                  _vm._v("入库人：" + _vm._s(_vm.form.inWarehouseName))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("入库时间：" + _vm._s(_vm.form.inWarehouseTime))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("审核人：" + _vm._s(_vm.form.examineName))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("审核时间：" + _vm._s(_vm.form.examineTime))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("记账人：" + _vm._s(_vm.form.accountName))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("记账时间：" + _vm._s(_vm.form.accountTime))
                ])
              ],
              1
            ),
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" }, attrs: { align: "left" } },
                  [
                    _vm.form.intelligent === 1 &&
                    _vm.form.inWarehouseStatus == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "danger",
                              icon: "el-icon-delete",
                              disabled: !(
                                _vm.form.inWarehouseStatus == 1 &&
                                _vm.detailRowIndex >= 0
                              )
                            },
                            on: { click: _vm.detailRowRemove }
                          },
                          [_vm._v("删除行")]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.toFormStorage)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-save" }),
                            _vm._v(" 暂存")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.formSubmit)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-send" }),
                            _vm._v(" 提交")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus == 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: { click: _vm.formUnSubmit }
                          },
                          [
                            _c("i", { staticClass: "fa el-icon-refresh-left" }),
                            _vm._v(" 撤消提交")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.intelligent === 1 &&
                    _vm.form.inWarehouseStatus === 1 &&
                    _vm.form.id
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger", icon: "el-icon-close" },
                            on: { click: _vm.orderInvalid }
                          },
                          [_vm._v("作废")]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus === 6 &&
                    _vm.form.intelligent === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger", icon: "el-icon-delete" },
                            on: { click: _vm.orderDelete }
                          },
                          [_vm._v("删除订单")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "prevent-re-click",
                            rawName: "v-prevent-re-click"
                          }
                        ],
                        attrs: { type: "primary", disabled: !_vm.form.id },
                        on: { click: _vm.orderPrint }
                      },
                      [_vm._v("打印")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "prevent-re-click",
                            rawName: "v-prevent-re-click"
                          }
                        ],
                        attrs: {
                          type: "primary",
                          disabled: _vm.form.inWarehouseStatus !== 4
                        },
                        on: { click: _vm.tagPrint }
                      },
                      [_vm._v("打印标签")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("SpecsSearchComponents", {
        ref: "SpecsSearchComponents",
        on: { confirm: _vm.specsAddToList }
      }),
      _c("ScanInputComponents", {
        ref: "ScanInputComponents",
        on: {
          add: _vm.scanInWarehouse,
          addBatch: _vm.scanInWarehouseBatch,
          update: _vm.scanInWarehouse,
          reload: _vm.loadDetail
        }
      }),
      _c("ScanInputComponents2", {
        ref: "ScanInputComponents2",
        on: {
          add: _vm.specsAddToList,
          update: _vm.specsUpdate,
          reload: _vm.loadDetail
        }
      }),
      _c("ScanInputComponents3", {
        ref: "ScanInputComponents3",
        on: {
          add: _vm.specsAddToList,
          update: _vm.specsUpdate,
          reload: _vm.loadDetail
        }
      }),
      _c("BatchManagerComponents", {
        ref: "BatchManagerComponents",
        on: { confirm: _vm.batchUpdate }
      }),
      _c("BatchListComponents", { ref: "BatchListComponents" }),
      _c("TagPrintComponent", { ref: "TagPrintComponentRef" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }