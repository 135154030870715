var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "1200px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticStyle: { width: "100%" } }, [
            _c(
              "div",
              {
                staticStyle: { "margin-bottom": "20px" },
                attrs: { align: "center" }
              },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { size: "small" },
                    on: { change: _vm.searchTypeChange },
                    model: {
                      value: _vm.searchType,
                      callback: function($$v) {
                        _vm.searchType = $$v
                      },
                      expression: "searchType"
                    }
                  },
                  [
                    _c("el-radio", { attrs: { label: 2 } }, [
                      _vm._v("从仓库明细选择")
                    ]),
                    _c("el-radio", { attrs: { label: 1 } }, [
                      _vm._v("从入库单选择")
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm.searchType === 1
            ? _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { size: "mini", inline: true, "label-suffix": ":" },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    },
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.formSubmit.apply(null, arguments)
                    }
                  },
                  model: {
                    value: _vm.form,
                    callback: function($$v) {
                      _vm.form = $$v
                    },
                    expression: "form"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "入库单号" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: { placeholder: "请输入入库单号", clearable: "" },
                        model: {
                          value: _vm.form.orderNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "orderNumber", $$v)
                          },
                          expression: "form.orderNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", "label-width": "0" } },
                    [
                      _c(
                        "el-popover",
                        {
                          ref: "queryPopover",
                          attrs: {
                            placement: "bottom",
                            title: "选择订单",
                            trigger: "click"
                          }
                        },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading,
                                  expression: "loading"
                                }
                              ],
                              staticStyle: { width: "800px" },
                              attrs: {
                                data: _vm.orderList,
                                border: "",
                                "highlight-current-row": "",
                                size: "mini",
                                "max-height": "260px",
                                "header-cell-style": {
                                  background: "#f5f7fa",
                                  borderColor: "#ebeef5",
                                  color: "#333"
                                }
                              },
                              on: {
                                "row-click": _vm.orderRowClick,
                                "row-dblclick": _vm.orderRowDbClick
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  property: "orderNumber",
                                  label: "订单编号",
                                  width: "200",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  property: "manufacturerName",
                                  label: "供应商",
                                  width: "200",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  property: "inWarehouseName",
                                  label: "入库人",
                                  width: "100",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  property: "quantitySum",
                                  label: "入库总数量",
                                  width: "100",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  property: "inWarehouseTime",
                                  label: "入库时间",
                                  width: "200",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { margin: "10px 0" } },
                            [
                              _c("el-pagination", {
                                attrs: {
                                  background: "",
                                  layout:
                                    "total, sizes, prev, pager, next, jumper",
                                  align: "center",
                                  total: _vm.orderForm.total,
                                  "page-sizes": [5, 10, 20, 50, 100],
                                  "current-page": _vm.orderForm.page,
                                  "page-size": _vm.orderForm.pageSize
                                },
                                on: {
                                  "current-change": _vm.orderPageChange,
                                  "size-change": _vm.orderSizeChange
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "primary" },
                              on: { click: _vm.loadOrder },
                              slot: "reference"
                            },
                            [_vm._v("选择订单")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.formSubmit }
                        },
                        [_vm._v("查 询")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.searchType === 2
            ? _c(
                "el-form",
                {
                  ref: "stockForm",
                  attrs: { inline: true, size: "mini", "label-suffix": ":" },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    },
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.stockFormSubmit.apply(null, arguments)
                    }
                  },
                  model: {
                    value: _vm.stockForm,
                    callback: function($$v) {
                      _vm.stockForm = $$v
                    },
                    expression: "stockForm"
                  }
                },
                [
                  _vm.initForm.manufacturerName
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "供应商" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.initForm.manufacturerName,
                              callback: function($$v) {
                                _vm.$set(_vm.initForm, "manufacturerName", $$v)
                              },
                              expression: "initForm.manufacturerName"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.initForm.subsidiaryName
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "归属公司" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.initForm.subsidiaryName,
                              callback: function($$v) {
                                _vm.$set(_vm.initForm, "subsidiaryName", $$v)
                              },
                              expression: "initForm.subsidiaryName"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品主码" } },
                    [
                      _c("el-input", {
                        staticClass: "bar-input",
                        attrs: { placeholder: "产品主码", clearable: "" },
                        model: {
                          value: _vm.stockForm.barCode,
                          callback: function($$v) {
                            _vm.$set(_vm.stockForm, "barCode", $$v)
                          },
                          expression: "stockForm.barCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品副码" } },
                    [
                      _c("el-input", {
                        ref: "barcodeInput",
                        staticClass: "bar-input",
                        attrs: { placeholder: "产品副码", clearable: "" },
                        model: {
                          value: _vm.scanBarCode,
                          callback: function($$v) {
                            _vm.scanBarCode = $$v
                          },
                          expression: "scanBarCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品编号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "产品编号", clearable: "" },
                        model: {
                          value: _vm.stockForm.productNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.stockForm, "productNumber", $$v)
                          },
                          expression: "stockForm.productNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.searchFormExpand
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "物资名称" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "物资名称", clearable: "" },
                            model: {
                              value: _vm.stockForm.productName,
                              callback: function($$v) {
                                _vm.$set(_vm.stockForm, "productName", $$v)
                              },
                              expression: "stockForm.productName"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.searchFormExpand
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "规格型号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "规格型号", clearable: "" },
                            model: {
                              value: _vm.stockForm.specificationModel,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.stockForm,
                                  "specificationModel",
                                  $$v
                                )
                              },
                              expression: "stockForm.specificationModel"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.searchFormExpand
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "品牌" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择品牌",
                                clearable: ""
                              },
                              model: {
                                value: _vm.stockForm.brandId,
                                callback: function($$v) {
                                  _vm.$set(_vm.stockForm, "brandId", $$v)
                                },
                                expression: "stockForm.brandId"
                              }
                            },
                            _vm._l(_vm.brandList, function(item) {
                              return _c("el-option", {
                                key: item.name,
                                attrs: { label: item.name, value: item.ID }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.searchFormExpand
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "生产批号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "生产批号", clearable: "" },
                            model: {
                              value: _vm.stockForm.batchNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.stockForm, "batchNumber", $$v)
                              },
                              expression: "stockForm.batchNumber"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.searchFormExpand
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "序列号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "序列号", clearable: "" },
                            model: {
                              value: _vm.stockForm.trackingCode,
                              callback: function($$v) {
                                _vm.$set(_vm.stockForm, "trackingCode", $$v)
                              },
                              expression: "stockForm.trackingCode"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", "label-width": "0" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.stockFormSubmit }
                        },
                        [_vm._v("查 询")]
                      ),
                      _c(
                        "el-link",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { type: "primary", underline: false },
                          on: {
                            click: function($event) {
                              _vm.searchFormExpand = !_vm.searchFormExpand
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.searchFormExpand ? "合并" : "展开")
                          ),
                          _c("i", {
                            class:
                              "el-icon-arrow-" +
                              (_vm.searchFormExpand ? "up" : "down") +
                              " el-icon--right"
                          })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "detailListTable",
              attrs: {
                data: _vm.detailList,
                border: "",
                "highlight-current-row": "",
                size: "mini",
                "max-height": "260px",
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                },
                "row-class-name": _vm.rowClassName
              },
              on: { "row-click": _vm.rowClick, "row-dblclick": _vm.rowDbClick }
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "50",
                  align: "center",
                  selectable: _vm.selectable,
                  fixed: ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  index: function(index) {
                    return _vm.indexMethod(index, _vm.stockForm)
                  },
                  label: "序号",
                  width: "80",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.expireDays <= 90 && scope.row.expireDays > 0
                            ? _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    content:
                                      "该产品还有" +
                                      scope.row.expireDays +
                                      "天过期",
                                    placement: "top"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "row",
                                        "align-items": "center",
                                        "justify-content": "center",
                                        position: "relative"
                                      }
                                    },
                                    [
                                      _c("d2-icon-svg", {
                                        staticStyle: {
                                          width: "16px",
                                          height: "14px",
                                          position: "absolute",
                                          left: "0"
                                        },
                                        attrs: { name: "warning-yellow" }
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indexMethod(
                                              scope.$index,
                                              _vm.stockForm
                                            )
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          scope.row.expireDays <= 0
                            ? _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    content:
                                      "该产品已过期" +
                                      Math.abs(scope.row.expireDays) +
                                      "天",
                                    placement: "top"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "row",
                                        "align-items": "center",
                                        "justify-content": "center",
                                        position: "relative"
                                      }
                                    },
                                    [
                                      _c("d2-icon-svg", {
                                        staticStyle: {
                                          width: "16px",
                                          height: "14px",
                                          position: "absolute",
                                          left: "0"
                                        },
                                        attrs: { name: "warning-red" }
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indexMethod(
                                              scope.$index,
                                              _vm.stockForm
                                            )
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          scope.row.expireDays > 90
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.indexMethod(scope.$index, _vm.stockForm)
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3582300870
                )
              }),
              _c("el-table-column", {
                attrs: {
                  property: "productNumber",
                  label: "产品编号",
                  width: "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "batchNumber",
                  label: "产品批号",
                  width: "150",
                  align: "center show-overflow-tooltip"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "trackingCode",
                  label: "序列号",
                  width: "150",
                  align: "center show-overflow-tooltip"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "150",
                  property: "",
                  label: "产地/品牌",
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            _vm._s(scope.row.productPlace) +
                              "/" +
                              _vm._s(scope.row.brandName)
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2727652499
                )
              }),
              _c("el-table-column", {
                attrs: {
                  property: "productName",
                  label: "物资名称",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "inWarehouseQuantity",
                  label: "入库数量",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "returnedQuantity",
                  label: "已退回数量",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "normalQuantity",
                  label: "库存可用数量",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "specificationModel",
                  label: "规格型号",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "unit",
                  label: "单位",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "200",
                  property: "registrationNumber",
                  label: "注册证号",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "afterTaxPrice",
                  label: "成本价格",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "manufacturerDate",
                  label: "生产日期",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "validityDate",
                  label: "有效期",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              })
            ],
            1
          ),
          _vm.searchType === 2
            ? _c(
                "div",
                { staticStyle: { margin: "10px 0" } },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                      align: "center",
                      "page-sizes": [5, 10, 20, 50, 100],
                      total: _vm.stockForm.total,
                      "current-page": _vm.stockForm.page,
                      "page-size": _vm.stockForm.pageSize
                    },
                    on: {
                      "current-change": _vm.stockListPageChange,
                      "size-change": _vm.stockListSizeChange
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.importConfirm }
                },
                [_vm._v("添加")]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }