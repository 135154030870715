import "core-js/modules/es.number.constructor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dayjs from 'dayjs';
export default {
  props: {
    row: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  data: function data() {
    return {
      sampleBarcodeString: null,
      sampleBarcodeItems: [],
      // 数字列表{class:'',char:''}
      dangerListIdx: [] // 错误位置列表

    };
  },
  created: function created() {},
  mounted: function mounted() {
    var that = this;
    that.sampleBarcodeString = null;
    that.sampleBarcode();
  },
  methods: {
    sampleBarcode: function sampleBarcode() {
      var that = this;
      var row = that.row;
      row.manufacturerDateIdentPos = Number(row.manufacturerDateIdentPos);
      row.manufacturerDateLength = Number(row.manufacturerDateLength);
      row.validityDateIdentPos = Number(row.validityDateIdentPos);
      row.validityDateLength = Number(row.validityDateLength);
      row.batchNumberLength = Number(row.batchNumberLength);
      row.batchNumberPos = Number(row.batchNumberPos);
      row.batchNumberIdentiPos = Number(row.batchNumberIdentiPos);
      row.trackingCodeIdentPos = Number(row.trackingCodeIdentPos);
      row.trackingCodeLength = Number(row.trackingCodeLength);
      row.trackingCodePosition = Number(row.trackingCodePosition);
      that.dangerListIdx = [];
      that.sampleBarcodeItems = [];
      var result = [];

      for (var i = 0; i < 50; i++) {
        result.push({
          class: '',
          char: '*'
        });
      } // 生产日期


      if (row.manufacturerDateIdent && row.manufacturerDateIdentPos) {
        try {
          row.manufacturerDateIdent.split('').forEach(function (item, index) {
            var idx = row.manufacturerDateIdentPos - 1 + index;

            if (idx > 49) {
              that.dangerListIdx.push(idx + 1);
              throw new Error('已超出最大长度50位');
            }

            result[idx] = {
              class: 'ident',
              char: item
            };
          });
          var manufactureDate = dayjs().format(row.manufacturerDateFormat).substring(0, row.manufacturerDateLength);
          manufactureDate.split('').forEach(function (item, index) {
            var idx = row.manufacturerDateIdentPos + row.manufacturerDateIdent.length + index - 1;

            if (idx > 49) {
              that.dangerListIdx.push(idx + 1);
              throw new Error('已超出最大长度50位');
            }

            result[idx] = {
              class: 'manufacture-date',
              char: item
            };
          });
        } catch (e) {
          that.$message.error(e.message);
        }
      } // 有效期


      if (row.validityDateIdent && row.validityDateIdentPos) {
        try {
          row.validityDateIdent.split('').forEach(function (item, index) {
            var idx = row.validityDateIdentPos - 1 + index;

            if (idx > 49) {
              that.dangerListIdx.push(idx + 1);
              throw new Error('已超出最大长度50位');
            }

            if (result[idx].char !== '*') {
              that.dangerListIdx.push(idx + 1);
              throw new Error('有效期位置可能存在冲突');
            }

            result[idx] = {
              class: 'ident',
              char: item
            };
          });
          var validityDate = dayjs().format(row.validityDateFormat).substring(0, row.validityDateLength);
          validityDate.split('').forEach(function (item, index) {
            var idx = row.validityDateIdentPos + row.validityDateIdent.length + index - 1;

            if (idx > 49) {
              that.dangerListIdx.push(idx + 1);
              throw new Error(idx + '已超出最大长度50位');
            }

            if (result[idx].char !== '*') {
              that.dangerListIdx.push(idx + 1);
              throw new Error('有效期位置可能存在冲突');
            }

            result[idx] = {
              class: 'validity-date',
              char: item
            };
          });
        } catch (e) {
          that.$message.error(e.message);
        }
      } // 批号


      try {
        if (row.batchNumberIdenti && row.batchNumberIdentiPos) {
          row.batchNumberIdenti.split('').forEach(function (item, index) {
            var idx = row.batchNumberIdentiPos - 1 + index;

            if (idx > 49) {
              that.dangerListIdx.push(idx + 1);
              throw new Error('已超出最大长度50位');
            }

            if (result[idx].char !== '*') {
              that.dangerListIdx.push(idx + 1);
              throw new Error('批号位置可能存在冲突');
            }

            result[idx] = {
              class: 'ident',
              char: item
            };
          });
        }
      } catch (e) {
        that.$message.error(e.message);
      }

      try {
        if (row.batchNumberLength && row.batchNumberPos) {
          var batchNumber = new Array(row.batchNumberLength).fill(8).join('');
          batchNumber.split('').forEach(function (item, index) {
            var idx = row.batchNumberPos + index - 1;

            if (idx > 49) {
              that.dangerListIdx.push(idx + 1);
              throw new Error('已超出最大长度50位');
            }

            if (result[idx].char !== '*') {
              that.dangerListIdx.push(idx + 1);
              throw new Error('批号位置可能存在冲突');
            }

            result[idx] = {
              class: 'batch-number',
              char: item
            };
          });
        }
      } catch (e) {
        that.$message.error(e.message);
      } // 序列号


      try {
        if (row.trackingCodeIdent && row.trackingCodeIdentPos) {
          row.trackingCodeIdent.split('').forEach(function (item, index) {
            var idx = row.trackingCodeIdentPos - 1 + index;

            if (idx > 49) {
              that.dangerListIdx.push(idx + 1);
              throw new Error('已超出最大长度50位');
            }

            if (result[idx].char !== '*') {
              that.dangerListIdx.push(idx + 1);
              throw new Error('序列号位置可能存在冲突');
            }

            result[idx] = {
              class: 'ident',
              char: item
            };
          });
        }
      } catch (e) {
        that.$message.error(e.message);
      }

      try {
        if (row.trackingCodeLength && row.trackingCodePosition) {
          var trackingCode = new Array(row.trackingCodeLength).fill(8).join('');
          trackingCode.split('').forEach(function (item, index) {
            var idx = row.trackingCodePosition + index - 1;

            if (idx > 49) {
              that.dangerListIdx.push(idx + 1);
              throw new Error('已超出最大长度50位');
            }

            if (result[idx].char !== '*') {
              that.dangerListIdx.push(idx + 1);
              throw new Error('序列号位置可能存在冲突');
            }

            result[idx] = {
              class: 'tracking-code',
              char: item
            };
          });
        }
      } catch (e) {
        that.$message.error(e.message);
      }

      that.$emit('change', result.map(function (item) {
        return item.char;
      }).join(''));
      that.sampleBarcodeItems = result;
    },
    barcodeParse: function barcodeParse() {
      var that = this;

      if (that.sampleBarcodeString.substring(0, 5) === '01069') {
        that.sampleBarcodeString = that.sampleBarcodeString.substring(16);
      }
    }
  }
};