var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "1280px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-container",
            [
              _c(
                "el-aside",
                {
                  staticStyle: { "border-right": "solid 1px #e6e6e6" },
                  attrs: { width: "200px" }
                },
                [
                  _c(
                    "el-menu",
                    {
                      staticStyle: { "border-right": "none" },
                      attrs: { "default-active": _vm.itemFormStep.toString() },
                      on: { select: _vm.menuSelect }
                    },
                    [
                      _c("el-menu-item", { attrs: { index: "1" } }, [
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v("生产商信息")]
                        )
                      ]),
                      _c("el-menu-item", { attrs: { index: "2" } }, [
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v("营业执照信息")]
                        )
                      ]),
                      _c("el-menu-item", { attrs: { index: "3" } }, [
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v("经营许可证信息")]
                        )
                      ]),
                      _c("el-menu-item", { attrs: { index: "4" } }, [
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v("生产许可证信息")]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-main",
                { staticStyle: { padding: "0 20px" } },
                [
                  _c(
                    "el-steps",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: {
                        active: _vm.itemFormStep,
                        "process-status": "wait"
                      }
                    },
                    [
                      _c("el-step", { attrs: { title: "第一步" } }),
                      _c("el-step", { attrs: { title: "第二步" } }),
                      _c("el-step", { attrs: { title: "第三步" } })
                    ],
                    1
                  ),
                  _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.formLoading,
                          expression: "formLoading"
                        }
                      ],
                      ref: "dataInfo",
                      style: _vm.itemFormStep !== 1 ? "display: none" : "",
                      attrs: {
                        model: _vm.dataInfo,
                        "label-width": "130px",
                        size: "mini",
                        disabled: true,
                        "label-suffix": ":"
                      },
                      nativeOn: {
                        submit: function($event) {
                          $event.preventDefault()
                        }
                      }
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _vm.changedClass(["show_status"]) === "changed"
                            ? _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "状态", prop: "status" }
                                    },
                                    [
                                      _c("template", { slot: "label" }, [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#f00" } },
                                          [_vm._v("状态:")]
                                        )
                                      ]),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#f00" } },
                                        [
                                          _vm._v(
                                            "正在 " +
                                              _vm._s(
                                                _vm.dataInfo.show_status ===
                                                  false
                                                  ? "禁用"
                                                  : "启用"
                                              ) +
                                              " 生产商，请审核"
                                          )
                                        ]
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_business_license",
                                  class: _vm.changedClass(["business_license"]),
                                  attrs: {
                                    label: "营业执照注册号",
                                    prop: "business_license"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入营业执照注册号"
                                    },
                                    model: {
                                      value: _vm.dataInfo.business_license,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "business_license",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.business_license"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_name",
                                  class: _vm.changedClass(["name"]),
                                  attrs: { label: "生产商名称", prop: "name" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入生产商名称" },
                                    model: {
                                      value: _vm.dataInfo.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.dataInfo, "name", $$v)
                                      },
                                      expression: "dataInfo.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_short_name",
                                  class: _vm.changedClass(["short_name"]),
                                  attrs: {
                                    label: "生产商简称",
                                    prop: "short_name"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入生产商简称" },
                                    model: {
                                      value: _vm.dataInfo.short_name,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "short_name",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.short_name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  class: _vm.changedClass(["pinyin_code"]),
                                  attrs: {
                                    label: "拼音码",
                                    prop: "pinyin_code"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入拼音码" },
                                    model: {
                                      value: _vm.dataInfo.pinyin_code,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "pinyin_code",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.pinyin_code"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_duty_paragraph",
                                  class: _vm.changedClass(["duty_paragraph"]),
                                  attrs: {
                                    label: "税号",
                                    prop: "duty_paragraph"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入税号" },
                                    model: {
                                      value: _vm.dataInfo.duty_paragraph,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "duty_paragraph",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.duty_paragraph"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_legal_person",
                                  class: _vm.changedClass(["legal_person"]),
                                  attrs: { label: "法人", prop: "legal_person" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入法人" },
                                    model: {
                                      value: _vm.dataInfo.legal_person,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "legal_person",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.legal_person"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_prov_city_area",
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: { label: "住所" }
                                },
                                [
                                  _c(
                                    "el-row",
                                    {
                                      staticStyle: { "flex-wrap": "wrap" },
                                      attrs: { type: "flex" }
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              key: "dataInfo_province_name",
                                              class: _vm.changedClass([
                                                "province_name"
                                              ]),
                                              attrs: {
                                                label: "",
                                                prop: "province_name"
                                              }
                                            },
                                            [
                                              _c("el-select", {
                                                attrs: {
                                                  clearable: "",
                                                  placeholder: "请选择省份"
                                                },
                                                model: {
                                                  value:
                                                    _vm.dataInfo.province_name,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.dataInfo,
                                                      "province_name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataInfo.province_name"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              key: "dataInfo_city_name",
                                              class: _vm.changedClass([
                                                "city_name"
                                              ]),
                                              staticStyle: {
                                                margin: "0 5px 5px 5px"
                                              },
                                              attrs: {
                                                label: "",
                                                prop: "city_name"
                                              }
                                            },
                                            [
                                              _c("el-select", {
                                                attrs: {
                                                  clearable: "",
                                                  placeholder: "请选择城市"
                                                },
                                                model: {
                                                  value: _vm.dataInfo.city_name,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.dataInfo,
                                                      "city_name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataInfo.city_name"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              key: "dataInfo_district_name",
                                              class: _vm.changedClass([
                                                "district_name"
                                              ]),
                                              staticStyle: {
                                                "margin-bottom": "5px"
                                              },
                                              attrs: {
                                                label: "",
                                                prop: "district_name"
                                              }
                                            },
                                            [
                                              _c("el-select", {
                                                attrs: {
                                                  clearable: "",
                                                  placeholder: "请选择区/县"
                                                },
                                                model: {
                                                  value:
                                                    _vm.dataInfo.district_name,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.dataInfo,
                                                      "district_name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataInfo.district_name"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_address",
                                  class: _vm.changedClass(["address"]),
                                  attrs: {
                                    label: "住所详情地址",
                                    prop: "address"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入住所详情地址"
                                    },
                                    model: {
                                      value: _vm.dataInfo.address,
                                      callback: function($$v) {
                                        _vm.$set(_vm.dataInfo, "address", $$v)
                                      },
                                      expression: "dataInfo.address"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_place_business",
                                  class: _vm.changedClass(["place_business"]),
                                  attrs: {
                                    label: "经营场所",
                                    prop: "place_business"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入经营场所" },
                                    model: {
                                      value: _vm.dataInfo.place_business,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "place_business",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.place_business"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_payment_method",
                                  class: _vm.changedClass(["payment_method"]),
                                  attrs: {
                                    label: "付款方式",
                                    prop: "payment_method"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择付款方式"
                                      },
                                      model: {
                                        value: _vm.dataInfo.payment_method,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.dataInfo,
                                            "payment_method",
                                            $$v
                                          )
                                        },
                                        expression: "dataInfo.payment_method"
                                      }
                                    },
                                    _vm._l(_vm.paymentList, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_bank",
                                  class: _vm.changedClass(["bank"]),
                                  attrs: { label: "开户银行", prop: "bank" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入开户银行" },
                                    model: {
                                      value: _vm.dataInfo.bank,
                                      callback: function($$v) {
                                        _vm.$set(_vm.dataInfo, "bank", $$v)
                                      },
                                      expression: "dataInfo.bank"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_bank_account",
                                  class: _vm.changedClass(["bank_account"]),
                                  attrs: {
                                    label: "银行账号",
                                    prop: "bank_account"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入银行账号" },
                                    model: {
                                      value: _vm.dataInfo.bank_account,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "bank_account",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.bank_account"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_contacts",
                                  class: _vm.changedClass(["contacts"]),
                                  attrs: { label: "联系人", prop: "contacts" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入联系人" },
                                    model: {
                                      value: _vm.dataInfo.contacts,
                                      callback: function($$v) {
                                        _vm.$set(_vm.dataInfo, "contacts", $$v)
                                      },
                                      expression: "dataInfo.contacts"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_contact_number",
                                  class: _vm.changedClass(["contact_number"]),
                                  attrs: {
                                    label: "联系电话",
                                    prop: "contact_number"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入联系电话" },
                                    model: {
                                      value: _vm.dataInfo.contact_number,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "contact_number",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.contact_number"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_mobile_phone",
                                  class: _vm.changedClass(["mobile_phone"]),
                                  attrs: {
                                    label: "移动电话",
                                    prop: "mobile_phone"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入移动电话" },
                                    model: {
                                      value: _vm.dataInfo.mobile_phone,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "mobile_phone",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.mobile_phone"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_fax",
                                  class: _vm.changedClass(["fax"]),
                                  attrs: { label: "传真", prop: "fax" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入传真" },
                                    model: {
                                      value: _vm.dataInfo.fax,
                                      callback: function($$v) {
                                        _vm.$set(_vm.dataInfo, "fax", $$v)
                                      },
                                      expression: "dataInfo.fax"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_postal_code",
                                  class: _vm.changedClass(["postal_code"]),
                                  attrs: {
                                    label: "邮政编码",
                                    prop: "postal_code"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入邮政编码" },
                                    model: {
                                      value: _vm.dataInfo.postal_code,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "postal_code",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.postal_code"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_email",
                                  class: _vm.changedClass(["email"]),
                                  attrs: { label: "电子邮件", prop: "email" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入电子邮件" },
                                    model: {
                                      value: _vm.dataInfo.email,
                                      callback: function($$v) {
                                        _vm.$set(_vm.dataInfo, "email", $$v)
                                      },
                                      expression: "dataInfo.email"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_qq",
                                  class: _vm.changedClass(["qq"]),
                                  attrs: { label: "QQ", prop: "qq" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入QQ" },
                                    model: {
                                      value: _vm.dataInfo.qq,
                                      callback: function($$v) {
                                        _vm.$set(_vm.dataInfo, "qq", $$v)
                                      },
                                      expression: "dataInfo.qq"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_website",
                                  class: _vm.changedClass(["website"]),
                                  attrs: { label: "网址", prop: "website" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入网址" },
                                    model: {
                                      value: _vm.dataInfo.website,
                                      callback: function($$v) {
                                        _vm.$set(_vm.dataInfo, "website", $$v)
                                      },
                                      expression: "dataInfo.website"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_scope_business_license_text",
                                  class: _vm.changedClass([
                                    "scope_business_license_text"
                                  ]),
                                  attrs: {
                                    label: "经营许可范围",
                                    prop: "scope_business_license_text"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 3,
                                      placeholder: "请选择经营许可范围"
                                    },
                                    model: {
                                      value:
                                        _vm.dataInfo
                                          .scope_business_license_text,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "scope_business_license_text",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataInfo.scope_business_license_text"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.formLoading,
                          expression: "formLoading"
                        }
                      ],
                      ref: "dataInfo",
                      style: _vm.itemFormStep !== 2 ? "display: none" : "",
                      attrs: {
                        model: _vm.dataInfo,
                        "label-width": "130px",
                        size: "mini",
                        disabled: true,
                        "label-suffix": ":"
                      },
                      nativeOn: {
                        submit: function($event) {
                          $event.preventDefault()
                        }
                      }
                    },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "flex-wrap": "wrap" },
                          attrs: { type: "flex" }
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_license_no",
                                  class: _vm.changedClass(["license_no"]),
                                  attrs: {
                                    label: "营业执照编号",
                                    prop: "license_no"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入营业执照编号"
                                    },
                                    model: {
                                      value: _vm.dataInfo.license_no,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "license_no",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.license_no"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_name",
                                  class: _vm.changedClass(["name"]),
                                  attrs: { label: "企业名称" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入企业名称" },
                                    model: {
                                      value: _vm.dataInfo.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.dataInfo, "name", $$v)
                                      },
                                      expression: "dataInfo.name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_enterprise_type",
                                  class: _vm.changedClass(["enterprise_type"]),
                                  attrs: {
                                    label: "企业类型",
                                    prop: "enterprise_type"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择企业类型"
                                      },
                                      model: {
                                        value: _vm.dataInfo.enterprise_type,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.dataInfo,
                                            "enterprise_type",
                                            $$v
                                          )
                                        },
                                        expression: "dataInfo.enterprise_type"
                                      }
                                    },
                                    _vm._l(_vm.enterpriseTypeList, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: item,
                                        attrs: { label: item, value: item }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_legal_person",
                                  class: _vm.changedClass(["legal_person"]),
                                  attrs: { label: "法人代表" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入法人代表" },
                                    model: {
                                      value: _vm.dataInfo.legal_person,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "legal_person",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.legal_person"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_registered_capital",
                                  class: _vm.changedClass([
                                    "registered_capital"
                                  ]),
                                  attrs: {
                                    label: "注册资本",
                                    prop: "registered_capital"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入注册资本" },
                                    model: {
                                      value: _vm.dataInfo.registered_capital,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "registered_capital",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.registered_capital"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_address",
                                  class: _vm.changedClass(["address"]),
                                  attrs: { label: "注册地址" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入注册地址" },
                                    model: {
                                      value: _vm.dataInfo.address,
                                      callback: function($$v) {
                                        _vm.$set(_vm.dataInfo, "address", $$v)
                                      },
                                      expression: "dataInfo.address"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_establishment_time",
                                  class: _vm.changedClass([
                                    "establishment_time"
                                  ]),
                                  attrs: {
                                    label: "成立时间",
                                    prop: "establishment_time"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.dataInfo.establishment_time,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "establishment_time",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.establishment_time"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_registration_authority",
                                  class: _vm.changedClass([
                                    "registration_authority"
                                  ]),
                                  attrs: {
                                    label: "登记机关",
                                    prop: "registration_authority"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入登记机关" },
                                    model: {
                                      value:
                                        _vm.dataInfo.registration_authority,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "registration_authority",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataInfo.registration_authority"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_registration_time",
                                  class: _vm.changedClass([
                                    "registration_time"
                                  ]),
                                  attrs: {
                                    label: "登记时间",
                                    prop: "registration_time"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.dataInfo.registration_time,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "registration_time",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.registration_time"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "营业期限",
                                    required:
                                      _vm.dataInfo.long_term === 1
                                        ? false
                                        : true
                                  }
                                },
                                [
                                  _c(
                                    "el-row",
                                    { attrs: { type: "flex", gutter: 10 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 9 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              key:
                                                "dataInfo_start_time_business",
                                              class: _vm.changedClass([
                                                "start_time_business"
                                              ]),
                                              staticStyle: {
                                                "margin-bottom": "0"
                                              },
                                              attrs: {
                                                "label-width": "0",
                                                prop: "start_time_business"
                                              }
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    _vm.dataInfo
                                                      .start_time_business,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.dataInfo,
                                                      "start_time_business",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataInfo.start_time_business"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("el-col", { attrs: { span: 2 } }, [
                                        _vm._v("至")
                                      ]),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 9 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              key: "dataInfo_end_time_business",
                                              class: _vm.changedClass([
                                                "end_time_business"
                                              ]),
                                              staticStyle: {
                                                "margin-bottom": "0"
                                              },
                                              attrs: {
                                                "label-width": "0",
                                                prop: "end_time_business"
                                              }
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    _vm.dataInfo
                                                      .end_time_business,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.dataInfo,
                                                      "end_time_business",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataInfo.end_time_business"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 18 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          key: "dataInfo_extension_time",
                                          class: _vm.changedClass([
                                            "extension_time"
                                          ]),
                                          attrs: {
                                            label: "延期时间",
                                            prop: "extension_time"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value:
                                                _vm.dataInfo.extension_time,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.dataInfo,
                                                  "extension_time",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataInfo.extension_time"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          key: "dataInfo_whether_postpone",
                                          class: _vm.changedClass([
                                            "whether_postpone"
                                          ]),
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: {
                                            "label-width": "0",
                                            prop: "whether_postpone"
                                          }
                                        },
                                        [
                                          _c("el-checkbox", {
                                            attrs: {
                                              label: "是否延期",
                                              "true-label": "1",
                                              "false-label": "0"
                                            },
                                            model: {
                                              value:
                                                _vm.dataInfo.whether_postpone,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.dataInfo,
                                                  "whether_postpone",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataInfo.whether_postpone"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_extension_certificate_no",
                                  class: _vm.changedClass([
                                    "extension_certificate_no"
                                  ]),
                                  attrs: {
                                    label: "延期证号",
                                    prop: "extension_certificate_no"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入延期证号" },
                                    model: {
                                      value:
                                        _vm.dataInfo.extension_certificate_no,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "extension_certificate_no",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataInfo.extension_certificate_no"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_nature_business",
                                  class: _vm.changedClass([
                                    "scope_business_license_text"
                                  ]),
                                  attrs: { label: "经营范围" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 3,
                                      placeholder: "请输入经营范围"
                                    },
                                    model: {
                                      value:
                                        _vm.dataInfo
                                          .scope_business_license_text,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "scope_business_license_text",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataInfo.scope_business_license_text"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_business_license_photo",
                                  attrs: {
                                    label: "营业执照照片",
                                    prop: "business_license_photo"
                                  }
                                },
                                _vm._l(_vm.licenseUploadFileList, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.uploadPreview(item)
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.formLoading,
                          expression: "formLoading"
                        }
                      ],
                      ref: "dataInfo",
                      style: _vm.itemFormStep !== 3 ? "display: none" : "",
                      attrs: {
                        model: _vm.dataInfo,
                        "label-width": "130px",
                        size: "mini",
                        disabled: true,
                        "label-suffix": ":"
                      },
                      nativeOn: {
                        submit: function($event) {
                          $event.preventDefault()
                        }
                      }
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_business_license_no",
                                  class: _vm.changedClass([
                                    "business_license_no"
                                  ]),
                                  attrs: {
                                    label: "经营许可证编号",
                                    prop: "business_license_no"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入经营许可证编号"
                                    },
                                    model: {
                                      value: _vm.dataInfo.business_license_no,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "business_license_no",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.business_license_no"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_name",
                                  class: _vm.changedClass(["name"]),
                                  attrs: { label: "企业名称" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入企业名称" },
                                    model: {
                                      value: _vm.dataInfo.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.dataInfo, "name", $$v)
                                      },
                                      expression: "dataInfo.name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_short_name",
                                  class: _vm.changedClass(["short_name"]),
                                  attrs: {
                                    label: "企业简称",
                                    prop: "short_name"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入企业简称" },
                                    model: {
                                      value: _vm.dataInfo.short_name,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "short_name",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.short_name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_address",
                                  class: _vm.changedClass(["address"]),
                                  attrs: { label: "注册地址" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入注册地址" },
                                    model: {
                                      value: _vm.dataInfo.address,
                                      callback: function($$v) {
                                        _vm.$set(_vm.dataInfo, "address", $$v)
                                      },
                                      expression: "dataInfo.address"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_legal_person",
                                  class: _vm.changedClass(["legal_person"]),
                                  attrs: { label: "法人代表" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入法人代表" },
                                    model: {
                                      value: _vm.dataInfo.legal_person,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "legal_person",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.legal_person"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_person_charge_enterprise",
                                  class: _vm.changedClass([
                                    "person_charge_enterprise"
                                  ]),
                                  attrs: {
                                    label: "企业负责人",
                                    prop: "person_charge_enterprise"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入企业负责人" },
                                    model: {
                                      value:
                                        _vm.dataInfo.person_charge_enterprise,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "person_charge_enterprise",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataInfo.person_charge_enterprise"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_quality_manager",
                                  class: _vm.changedClass(["quality_manager"]),
                                  attrs: {
                                    label: "质量管理人",
                                    prop: "quality_manager"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入质量管理人" },
                                    model: {
                                      value: _vm.dataInfo.quality_manager,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "quality_manager",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.quality_manager"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_warehouse_address",
                                  class: _vm.changedClass([
                                    "warehouse_address"
                                  ]),
                                  attrs: {
                                    label: "库房地址",
                                    prop: "warehouse_address"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入库房地址" },
                                    model: {
                                      value: _vm.dataInfo.warehouse_address,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "warehouse_address",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.warehouse_address"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  class: _vm.changedClass([
                                    "license_start_time",
                                    "license_end_time"
                                  ]),
                                  attrs: { label: "许可证期限", required: true }
                                },
                                [
                                  _c(
                                    "el-row",
                                    { attrs: { type: "flex", gutter: 10 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 11 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              key:
                                                "dataInfo_license_start_time",
                                              staticStyle: {
                                                "margin-bottom": "0"
                                              },
                                              attrs: {
                                                "label-width": "0",
                                                prop: "license_start_time"
                                              }
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    _vm.dataInfo
                                                      .license_start_time,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.dataInfo,
                                                      "license_start_time",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataInfo.license_start_time"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("el-col", { attrs: { span: 2 } }, [
                                        _vm._v("至")
                                      ]),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 11 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              key: "dataInfo_license_end_time",
                                              class: _vm.changedClass([
                                                "license_end_time"
                                              ]),
                                              staticStyle: {
                                                "margin-bottom": "0"
                                              },
                                              attrs: {
                                                "label-width": "0",
                                                prop: "license_end_time"
                                              }
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    _vm.dataInfo
                                                      .license_end_time,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.dataInfo,
                                                      "license_end_time",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataInfo.license_end_time"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_date_issue",
                                  class: _vm.changedClass(["date_issue"]),
                                  attrs: {
                                    label: "发证日期",
                                    prop: "date_issue"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.dataInfo.date_issue,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "date_issue",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.date_issue"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_issuing_authority",
                                  class: _vm.changedClass([
                                    "issuing_authority"
                                  ]),
                                  attrs: {
                                    label: "发证机关",
                                    prop: "issuing_authority"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入发证机关" },
                                    model: {
                                      value: _vm.dataInfo.issuing_authority,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "issuing_authority",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.issuing_authority"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 18 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          key:
                                            "dataInfo_license_extension_time",
                                          class: _vm.changedClass([
                                            "license_extension_time"
                                          ]),
                                          attrs: {
                                            label: "延期时间",
                                            prop: "license_extension_time"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value:
                                                _vm.dataInfo
                                                  .license_extension_time,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.dataInfo,
                                                  "license_extension_time",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataInfo.license_extension_time"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          key:
                                            "dataInfo_whether_license_extended",
                                          class: _vm.changedClass([
                                            "whether_license_extended"
                                          ]),
                                          attrs: {
                                            prop: "whether_license_extended",
                                            "label-width": "0"
                                          }
                                        },
                                        [
                                          _c("el-checkbox", {
                                            attrs: {
                                              label: "是否延期",
                                              "true-label": "1",
                                              "false-label": "0"
                                            },
                                            model: {
                                              value:
                                                _vm.dataInfo
                                                  .whether_license_extended,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.dataInfo,
                                                  "whether_license_extended",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataInfo.whether_license_extended"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_license_extension_no",
                                  class: _vm.changedClass([
                                    "license_extension_no"
                                  ]),
                                  attrs: {
                                    label: "延期证号",
                                    prop: "license_extension_no"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入延期证号" },
                                    model: {
                                      value: _vm.dataInfo.license_extension_no,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "license_extension_no",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataInfo.license_extension_no"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_nature_business",
                                  class: _vm.changedClass([
                                    "scope_business_license_text"
                                  ]),
                                  attrs: { label: "经营范围" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 4,
                                      placeholder: "请输入经营范围"
                                    },
                                    model: {
                                      value:
                                        _vm.dataInfo
                                          .scope_business_license_text,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "scope_business_license_text",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataInfo.scope_business_license_text"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "dataInfo_picture_business_license",
                                  attrs: {
                                    label: "经营许可证照片",
                                    prop: "picture_business_license"
                                  }
                                },
                                _vm._l(_vm.businessUploadFileList, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.uploadPreview(item)
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.formLoading,
                          expression: "formLoading"
                        }
                      ],
                      ref: "dataInfo",
                      style: _vm.itemFormStep !== 4 ? "display: none" : "",
                      attrs: {
                        model: _vm.dataInfo,
                        "label-width": "150px",
                        size: "mini",
                        "label-suffix": ":",
                        disabled: true
                      },
                      nativeOn: {
                        submit: function($event) {
                          $event.preventDefault()
                        }
                      }
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  class: _vm.changedClass([
                                    "manu_license_code"
                                  ]),
                                  attrs: {
                                    label: "许可证编号",
                                    prop: "manu_license_code"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: _vm.dataInfo.manu_license_code,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "manu_license_code",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.manu_license_code"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  class: _vm.changedClass([
                                    "manu_license_unicode"
                                  ]),
                                  attrs: {
                                    label: "统一社会信用代码",
                                    prop: "manu_license_unicode"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: _vm.dataInfo.manu_license_unicode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "manu_license_unicode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataInfo.manu_license_unicode"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  class: _vm.changedClass([
                                    "manu_license_company"
                                  ]),
                                  attrs: {
                                    label: "企业名称",
                                    prop: "manu_license_company"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: _vm.dataInfo.manu_license_company,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "manu_license_company",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataInfo.manu_license_company"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  class: _vm.changedClass([
                                    "manu_license_legal"
                                  ]),
                                  attrs: {
                                    label: "法定代表人",
                                    prop: "manu_license_legal"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: _vm.dataInfo.manu_license_legal,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "manu_license_legal",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.manu_license_legal"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  class: _vm.changedClass([
                                    "manu_license_home"
                                  ]),
                                  attrs: {
                                    label: "住所",
                                    prop: "manu_license_home"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: _vm.dataInfo.manu_license_home,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "manu_license_home",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.manu_license_home"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  class: _vm.changedClass([
                                    "manu_license_boss"
                                  ]),
                                  attrs: {
                                    label: "企业负责人",
                                    prop: "manu_license_boss"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: _vm.dataInfo.manu_license_boss,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "manu_license_boss",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.manu_license_boss"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  class: _vm.changedClass([
                                    "manu_license_address"
                                  ]),
                                  attrs: {
                                    label: "生产地址",
                                    prop: "manu_license_address"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: _vm.dataInfo.manu_license_address,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "manu_license_address",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataInfo.manu_license_address"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  class: _vm.changedClass([
                                    "manu_license_department"
                                  ]),
                                  attrs: {
                                    label: "发证部门",
                                    prop: "manu_license_department"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value:
                                        _vm.dataInfo.manu_license_department,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "manu_license_department",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataInfo.manu_license_department"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "许可期限" } },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          class: _vm.changedClass([
                                            "manu_license_start"
                                          ]),
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: {
                                            "label-width": "0",
                                            prop: "manu_license_start"
                                          }
                                        },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "date",
                                              format: "yyyy-MM-dd",
                                              "value-format": "yyyy-MM-dd",
                                              placeholder: "开始日期"
                                            },
                                            model: {
                                              value:
                                                _vm.dataInfo.manu_license_start,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.dataInfo,
                                                  "manu_license_start",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataInfo.manu_license_start"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { margin: "0 10px" } },
                                        [_vm._v("至")]
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          class: _vm.changedClass([
                                            "manu_license_end"
                                          ]),
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: {
                                            "label-width": "0",
                                            prop: "manu_license_end"
                                          }
                                        },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "date",
                                              format: "yyyy-MM-dd",
                                              "value-format": "yyyy-MM-dd",
                                              placeholder: "结束日期"
                                            },
                                            model: {
                                              value:
                                                _vm.dataInfo.manu_license_end,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.dataInfo,
                                                  "manu_license_end",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataInfo.manu_license_end"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  class: _vm.changedClass([
                                    "manu_license_range"
                                  ]),
                                  attrs: {
                                    label: "生产范围",
                                    prop: "manu_license_range"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { type: "textarea", rows: 3 },
                                    model: {
                                      value: _vm.dataInfo.manu_license_range,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataInfo,
                                          "manu_license_range",
                                          $$v
                                        )
                                      },
                                      expression: "dataInfo.manu_license_range"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.examineConfirm }
                        },
                        [_vm._v("确认审核")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("ExamineComponents", {
            ref: "ExamineComponents",
            on: { confirm: _vm.parentReload }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }