import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listInWarehouseAcceptDetail, createInwarehouseDetails, findDealerPackingByNumber } from '@/api/stock';
import { postSpecsList } from '@/api/product.js';
import { createInWarehouseInfoAccepts } from '@/api/purchase';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: '斯潘威随货同行单扫码导入',
      dialogVisible: false,
      loading: false,
      form: {
        pi: '',
        // 11开头，长度小于20位
        di: '',
        // 01开头，长度小于20位
        dipi: '',
        // 长度大于20位
        barCode: ''
      },
      barInputPlaceholder: '请扫描随行单条码',
      lastType: '',
      specsForm: {
        barCode: '',
        page: 1,
        pageSize: 1,
        warehouseId: 0
      },
      tips: '',
      barCodeRs: {
        batchNumber: null,
        trackingCode: null,
        validityDate: null,
        manufacturerDate: null
      },
      readonly: false,
      inputType: '手动',
      orderId: null,
      taxRate: null
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init(warehouseId, orderId, taxRate) {
      var that = this;
      that.dialogVisible = true;
      that.taxRate = taxRate;
      that.form.warehouseId = warehouseId;
      that.orderId = orderId;
      that.form.barCode = '';
      that.tips = '';
      that.inputType = '手动';
      that.readonly = false;
      that.$nextTick(function () {
        that.$refs.barCodeInput.focus();
      });
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    dealerOrderInfo: function dealerOrderInfo(barCode, plist) {
      plist.forEach(function (row) {
        if (row.barCode === barCode) {
          return row;
        }
      });
    },
    barCodeSearch: function barCodeSearch() {
      var that = this;

      if (that.form.barCode) {
        that.form.barCode = that.form.barCode.replace(/[^a-zA-Z0-9]/g, '');
        that.form.barCode = that.form.barCode.toUpperCase();

        if (that.form.barCode) {
          // 随行出货单，扫码后调用工厂系统接口，直接获取出库产品列表
          findDealerPackingByNumber({
            pickingNumber: that.form.barCode
          }).then(function (res) {
            var barCodeList = [];
            res.redealerPacking.packingInfo.forEach(function (row) {
              barCodeList.push(row.orderInfo.specs.barCode);
            });
            console.log('barCodeList', barCodeList); // 根据产品主码查询产品

            var form = {
              barCodes: barCodeList
            };
            var productList = [];
            postSpecsList(form).then(function (res2) {
              if (res2.list.length > 0) {
                res2.list.forEach(function (row) {
                  res.redealerPacking.packingInfo.forEach(function (row2) {
                    if (row.barCode.trim() === row2.orderInfo.specs.barCode.trim()) {
                      var findProduct = productList.filter(function (item) {
                        return item.specsId === row.ID;
                      });

                      if (findProduct.length === 0) {
                        productList.push({
                          inWarehouseId: that.orderId,
                          qualifiedQuantity: Number(row2.pickingQuantity),
                          afterTaxPrice: row.wholesalePurchasePrice || row.stockMain.afterTaxPrice,
                          specsId: row.ID,
                          batch: [{
                            afterTaxPrice: row.wholesalePurchasePrice || row.stockMain.afterTaxPrice,
                            batchNumber: row2.stockMain.batch_number,
                            inWarehouseId: that.orderId,
                            inWarehouseQuantity: row2.pickingQuantity,
                            infoAcceptId: 0,
                            mainId: row.mainId,
                            manufacturerDate: row2.stockMain.manufacturer_date,
                            remark: row2.desc,
                            specsId: row.ID,
                            sterilizationBatchNumber: row2.stockMain.sterilization_batch_number,
                            sterilizationDate: row2.stockMain.sterilization_date,
                            trackingCode: null,
                            validityDate: row2.stockMain.validity_date
                          }]
                        });
                      } else {
                        productList.forEach(function (row3) {
                          if (row3.specsId === row.ID) {
                            row3.qualifiedQuantity += Number(row2.pickingQuantity);
                            var find = row3.batch.filter(function (item) {
                              return item.batchNumber === row2.stockMain.batch_number;
                            });

                            if (find.length === 0) {
                              row3.batch.push({
                                afterTaxPrice: row.wholesalePurchasePrice || row.stockMain.afterTaxPrice,
                                batchNumber: row2.stockMain.batch_number,
                                inWarehouseId: that.orderId,
                                inWarehouseQuantity: row2.pickingQuantity,
                                infoAcceptId: 0,
                                mainId: row.mainId,
                                manufacturerDate: row2.stockMain.manufacturer_date,
                                remark: row2.desc,
                                specsId: row.ID,
                                sterilizationBatchNumber: row2.stockMain.sterilization_batch_number,
                                sterilizationDate: row2.stockMain.sterilization_date,
                                trackingCode: null,
                                validityDate: row2.stockMain.validity_date
                              });
                            } else {
                              find[0].inWarehouseQuantity += Number(row2.pickingQuantity);
                            }
                          }
                        });
                      }
                    }
                  });
                });

                if (productList.length > 0) {
                  var proForm = [];
                  productList.forEach(function (row) {
                    proForm.push({
                      inWarehouseId: row.inWarehouseId,
                      qualifiedQuantity: Number(row.qualifiedQuantity),
                      specsId: Number(row.specsId),
                      afterTaxPrice: row.afterTaxPrice,
                      taxRate: that.taxRate
                    });
                  });
                  createInWarehouseInfoAccepts(proForm).then(function (res3) {
                    listInWarehouseAcceptDetail({
                      inWarehouseId: that.orderId,
                      page: 1,
                      pageSize: 9999
                    }).then(function (res4) {
                      if (res4.list.length > 0) {
                        var batchForm = [];
                        res4.list.forEach(function (row4) {
                          productList.forEach(function (row5) {
                            if (row5.specsId === row4.specsId) {
                              row5.batch.forEach(function (row6) {
                                row6.infoAcceptId = row4.ID;
                                row6.id = null;
                                batchForm.push(row6);
                              });
                            }
                          });
                        });

                        if (batchForm.length > 0) {
                          createInwarehouseDetails(batchForm).then(function (res5) {
                            that.$notify.success('订单导入成功');
                            that.$emit('reload');
                          }).catch(function (e3) {});
                        }
                      }
                    }).catch(function (e1) {});
                  }).catch(function (e) {});
                }
              }
            }).catch(function (e) {});
          }).catch(function (e) {
            console.log('e', e);
            that.$message.error('请检查随货同行单号码是否正确');
          });
        }
      } else {
        that.$message.error('请扫描、输入条码');
      }

      that.form.barCode = '';
      return false;
    },
    specsListFormat: function specsListFormat(rows) {
      var resultRows = [];
      rows.forEach(function (row, index) {
        resultRows.push({
          ID: row.ID,
          mainId: row.mainId,
          specsId: row.ID,
          rowIndex: index,
          productNumber: row.productNumber,
          unit: row.unit,
          packUnit: row.packUnit,
          packNum: row.packNum,
          unitPrice: row.wholesalePrice,
          wholesalePurchasePrice: row.wholesalePurchasePrice,
          consignmentPurchasePrice: row.consignmentPurchasePrice,
          marketPrice: row.marketPrice,
          consignmentPrice: row.consignmentPrice,
          wholesalePrice: row.wholesalePrice,
          sterilizationWays: row.sterilizationWays,
          specificationModel: row.specificationModel,
          batchNumber: row.stockMain.batch_number,
          stockQuantity: row.stockMain.stock_quantity,
          normalQuantity: row.stockMain.normalQuantity,
          consumedQuantity: row.stockMain.consumedQuantity,
          receiptQuantity: row.stockMain.receiptQuantity,
          issueLocking: row.stockMain.issueLocking,
          salesPendingApproval: row.stockMain.salesPendingApproval,
          productPackageLocking: row.stockMain.productPackageLocking,
          manufacturerDate: row.stockMain.manufacturer_date,
          validityDate: row.stockMain.validity_date,
          sterilizationBatchNumber: row.stockMain.sterilization_batch_number,
          sterilizationDate: row.stockMain.sterilization_date,
          placementPosition: row.stockMain.placement_position,
          inventoryId: row.stockMain.ID,
          trackingCode: row.trackingCode ? row.trackingCode : '',
          productName: row.main.productName,
          registrationNumber: row.main.registrationNumber,
          expirationDate: row.main.expirationDate,
          productPlace: row.main.productPlace,
          brandName: row.main.brandName,
          manufacturerName: row.stockMain.manufacturerName,
          afterTaxPrice: Number(row.stockMain.afterTaxPrice),
          inWarehouseQuantity: 1
        });
      });
      return resultRows;
    },
    inputParse: function inputParse(e) {
      var that = this;

      if (that.readonly) {
        var filterList = 'abcdefghijklmnopqrstuvwxyz0123456789+-*/,./<>?;:"\'[]{}\\|=_)(&^%$#@!~`)';

        if (filterList.indexOf(e.key.toLowerCase()) !== -1) {
          that.form.barCode = that.form.barCode + e.key;
        }
      }
    },
    inputTypeClick: function inputTypeClick() {
      var that = this;

      if (that.inputType === '手动') {
        that.inputType = '扫码';
        that.readonly = true;
      } else {
        that.inputType = '手动';
        that.readonly = false;
      }

      that.$nextTick(function () {
        that.$refs.barCodeInput.focus();
      });
    },
    focusInput: function focusInput() {
      var that = this;

      if (that.dialogVisible) {
        setTimeout(function () {
          that.$refs.barCodeInput.focus();
        }, 0);
      } else {
        console.log('窗口已关');
      }

      that.$nextTick(function () {
        that.$refs.barCodeInput.focus();
      });
    }
  }
};