var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "1200px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "append-to-body": true,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "200px",
                size: "mini",
                disabled: true,
                "label-suffix": ":"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "flex-wrap": "wrap" },
                  attrs: { type: "flex" }
                },
                [
                  _vm.changedClass(["state"]) === "changed"
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "状态", prop: "status" } },
                            [
                              _c("template", { slot: "label" }, [
                                _c("span", { staticStyle: { color: "#f00" } }, [
                                  _vm._v("状态:")
                                ])
                              ]),
                              _c("span", { staticStyle: { color: "#f00" } }, [
                                _vm._v(
                                  "正在 " +
                                    _vm._s(
                                      _vm.form.state === 1 ? "启用" : "禁用"
                                    ) +
                                    " 注册证，请审核"
                                )
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["reg_no_cn"]),
                          attrs: { label: "注册证编号", prop: "regNoCn" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入注册证编号",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.regNoCn,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "regNoCn", $$v)
                              },
                              expression: "form.regNoCn"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["reg_name"]),
                          attrs: { label: "注册人姓名", prop: "regName" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入注册人姓名",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.regName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "regName", $$v)
                              },
                              expression: "form.regName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["company_reg_address"]),
                          attrs: {
                            label: "注册人地址",
                            prop: "companyRegAddress"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入注册人地址",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.companyRegAddress,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "companyRegAddress", $$v)
                              },
                              expression: "form.companyRegAddress"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["entrusted_production"]),
                          attrs: {
                            label: "受托生产企业",
                            prop: "entrustedProduction"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入受托生产企业",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.entrustedProduction,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "entrustedProduction", $$v)
                              },
                              expression: "form.entrustedProduction"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["address_production_site"]),
                          attrs: {
                            label: "生产地址",
                            prop: "addressProductionSite"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入生产地址",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.addressProductionSite,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "addressProductionSite", $$v)
                              },
                              expression: "form.addressProductionSite"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["reg_product_name"]),
                          attrs: { label: "产品名称", prop: "regProductName" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入产品名称",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.regProductName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "regProductName", $$v)
                              },
                              expression: "form.regProductName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["admin_type"]),
                          attrs: { label: "管理类别", prop: "adminType" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请选择管理类别" },
                            model: {
                              value: _vm.form.adminType,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "adminType", $$v)
                              },
                              expression: "form.adminType"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["material_spec_cert"]),
                          attrs: { label: "规格型号", prop: "materialSpecCert" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入规格型号",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.materialSpecCert,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "materialSpecCert", $$v)
                              },
                              expression: "form.materialSpecCert"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass([
                            "product_structure_composition"
                          ]),
                          attrs: {
                            label: "结构及组成/主要组成成分",
                            prop: "productStructureComposition"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 2,
                              placeholder: "请输入结构及组成/主要组成成分",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.productStructureComposition,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "productStructureComposition",
                                  $$v
                                )
                              },
                              expression: "form.productStructureComposition"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["scope_application"]),
                          attrs: {
                            label: "适用范围/预期用途",
                            prop: "scopeApplication"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 2,
                              placeholder: "请输入适用范围/预期用途",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.scopeApplication,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "scopeApplication", $$v)
                              },
                              expression: "form.scopeApplication"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["registered_agent"]),
                          attrs: {
                            label: "代理人名称",
                            prop: "registeredAgent"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入代理人名称",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.registeredAgent,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "registeredAgent", $$v)
                              },
                              expression: "form.registeredAgent"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["address"]),
                          attrs: { label: "代理人住所", prop: "address" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入代理人住所",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.address,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "address", $$v)
                              },
                              expression: "form.address"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["regDate"]),
                          attrs: { label: "批准日期", prop: "regDate" }
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              placeholder: "选择日期",
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd"
                            },
                            model: {
                              value: _vm.form.regDate,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "regDate", $$v)
                              },
                              expression: "form.regDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass([
                            "expiration_date",
                            "long_term_validity"
                          ]),
                          attrs: { label: "有效期至", prop: "expirationDate" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row",
                                "justify-content": "flex-start",
                                width: "250px"
                              }
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  type: "date",
                                  placeholder: "选择日期",
                                  "value-format": "yyyy-MM-dd",
                                  format: "yyyy-MM-dd",
                                  disabled: _vm.form.longTermValidity
                                    ? true
                                    : false
                                },
                                model: {
                                  value: _vm.form.expirationDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "expirationDate", $$v)
                                  },
                                  expression: "form.expirationDate"
                                }
                              }),
                              _c(
                                "el-checkbox",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  model: {
                                    value: _vm.form.longTermValidity,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "longTermValidity",
                                        $$v
                                      )
                                    },
                                    expression: "form.longTermValidity"
                                  }
                                },
                                [_vm._v("长期")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["other_content"]),
                          attrs: { label: "其他内容", prop: "otherContent" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 2,
                              placeholder: "请输入其他内容",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.otherContent,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "otherContent", $$v)
                              },
                              expression: "form.otherContent"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["remarks"]),
                          attrs: { label: "备注", prop: "remarks" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 2,
                              placeholder: "请输入备注",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remarks", $$v)
                              },
                              expression: "form.remarks"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "原注册证编号", prop: "corIds" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                multiple: "",
                                clearable: "",
                                placeholder: "请选择注册证号"
                              },
                              model: {
                                value: _vm.certIdString,
                                callback: function($$v) {
                                  _vm.certIdString = $$v
                                },
                                expression: "certIdString"
                              }
                            },
                            _vm._l(_vm.registrationList, function(item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.ID,
                                  attrs: { label: item.regNoCn, value: item.ID }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(item.regNoCn))]
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "附件", prop: "enclosure" } },
                        _vm._l(_vm.uploadFileList, function(item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.uploadPreview(item)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(item.name))]
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "10 0" }, attrs: { align: "center" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.examineConfirm }
                },
                [_vm._v("确认审核")]
              )
            ],
            1
          ),
          _c("ExamineComponents", {
            ref: "ExamineComponents",
            on: { confirm: _vm.parentReload }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }