var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("销售管理")]),
              _c("el-breadcrumb-item", [_vm._v("手术管理")]),
              _c("el-breadcrumb-item", [_vm._v("订单管理（数据员）")]),
              _c("el-breadcrumb-item", [_vm._v("订单详情")])
            ],
            1
          ),
          _c("div", { staticStyle: { "font-size": "14px" } }, [
            _vm._v(" 订单编号: " + _vm._s(_vm.orderInfo.orderNumber) + " ")
          ])
        ],
        1
      ),
      [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                ref: "form",
                staticClass: "form-table",
                attrs: {
                  "label-suffix": ":",
                  size: "mini",
                  "label-width": "120px",
                  "show-message": false
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { "flex-wrap": "wrap" },
                    attrs: { type: "flex" }
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "订单编号" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.orderNumber))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "医院" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.hospitalName))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "仓库" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.warehouseName))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "医生" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.hospitalDoctorName))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "病人" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.patientName))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "手术名称" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.surgerysFormatter(_vm.orderInfo.surgerys)
                              )
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "销售" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.saleUserName))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "跟台" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.orderInfo.followUname ||
                                  _vm.orderInfo.followTableUname
                              )
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "归属公司" } },
                          [
                            _vm.orderInfo.status !== 200
                              ? _c(
                                  "el-select",
                                  {
                                    attrs: { clearable: "", filterable: "" },
                                    model: {
                                      value: _vm.orderInfo.subsidiaryId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.orderInfo,
                                          "subsidiaryId",
                                          $$v
                                        )
                                      },
                                      expression: "orderInfo.subsidiaryId"
                                    }
                                  },
                                  _vm._l(_vm.subsidiaryList, function(
                                    item,
                                    index
                                  ) {
                                    return _c("el-option", {
                                      key: "" + index,
                                      attrs: {
                                        label: item.companyName,
                                        value: item.ID
                                      }
                                    })
                                  }),
                                  1
                                )
                              : _c("div", { staticClass: "item-text" }, [
                                  _vm._v(_vm._s(_vm.orderInfo.subsidiaryName))
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.orderInfo.status === 200
                      ? _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "归档日期" } },
                              [
                                _c("div", { staticClass: "item-text" }, [
                                  _vm._v(_vm._s(_vm.orderInfo.fillTime))
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.orderInfo.status === 200
                      ? _c(
                          "el-col",
                          { attrs: { sm: 24, lg: 16, xl: 16 } },
                          [
                            _c("el-form-item", { attrs: { label: "归档人" } }, [
                              _c("div", { staticClass: "item-text" }, [
                                _vm._v(_vm._s(_vm.orderInfo.fillName))
                              ])
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              "margin-top": "10px",
              "margin-bottom": "0px",
              display: "flex",
              "justify-content": "space-between"
            }
          },
          [
            _c(
              "div",
              { staticClass: "tab-list-grey", staticStyle: { margin: "0" } },
              [
                _c("div", { staticClass: "tab-item hover" }, [
                  _vm._v("销售明细")
                ])
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "font-size": "14px",
                  "align-items": "center"
                }
              },
              [
                _c("span", [
                  _vm._v(" 销售总数量: "),
                  _c("span", { staticStyle: { color: "#f00" } }, [
                    _vm._v(_vm._s(_vm.sumQuantity()))
                  ])
                ]),
                _c("span", { staticStyle: { margin: "0 10px" } }, [
                  _vm._v(" 销售总额: "),
                  _c("span", { staticStyle: { color: "#f00" } }, [
                    _vm._v(_vm._s(_vm.sumAmount()))
                  ])
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function($event) {
                        return _vm.toPrint()
                      }
                    }
                  },
                  [_vm._v("打印销售单")]
                )
              ],
              1
            )
          ]
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading"
            }
          ],
          ref: "detailTableRef",
          attrs: {
            hasIndex: true,
            hasPagination: true,
            pagination: _vm.form,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            data: _vm.detailList.slice(
              (_vm.form.page - 1) * _vm.form.pageSize,
              _vm.form.page * _vm.form.pageSize
            )
          },
          on: { pageChange: _vm.pageChange, sizeChange: _vm.sizeChange }
        })
      ],
      _vm.orderInfo.statusDescribe === "待确认归档"
        ? _c("template", { slot: "footer" }, [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "column",
                  "justify-content": "space-between",
                  "align-items": "center"
                }
              },
              [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    staticStyle: { margin: "0px", width: "100%" },
                    attrs: { size: "small" },
                    nativeOn: {
                      submit: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticStyle: { margin: "0" } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "button1",
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.toReject()
                              }
                            }
                          },
                          [_vm._v("驳回")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "button2",
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.toPass()
                              }
                            }
                          },
                          [_vm._v("归档确认")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c("ConfirmComponent", {
        ref: "ConfirmComponentRef",
        on: { reload: _vm.init }
      }),
      _c("PrintComponent", { ref: "PrintComponentRef" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }