import { request } from '@/api/_service2.js'; // 订单列表

export function listModifyOrder(params) {
  return request({
    url: '/erpRetWarehouseErp/getErpRetWarehouseList',
    method: 'get',
    params: params
  });
} // 订单列表导出

export function listModifyOrderExport(params) {
  return request({
    url: '/erpRetWarehouseErp/getErpRetWarehouseList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 订单查询

export function findModifyOrder(params) {
  return request({
    url: '/erpRetWarehouseErp/findErpRetWarehouse',
    method: 'get',
    params: params
  });
} // 订单更新

export function updateModifyOrder(data) {
  return request({
    url: '/erpRetWarehouseErp/updateErpRetWarehouse',
    method: 'put',
    data: data
  });
} // 订单审核

export function checkModifyOrder(data) {
  return request({
    url: '/erpRetWarehouseErp/updateErpRetWarehouseStatus',
    method: 'put',
    data: data
  });
} // 创建订单详情

export function createModifyOrder(data) {
  return request({
    url: '/erpRetWarehouseErp/createFaErpRetWarehouseReport',
    method: 'post',
    data: data
  });
} // 删除订单

export function deleteModifyOrder(data) {
  return request({
    url: '/erpRetWarehouseErp/deleteErpRetWarehouse',
    method: 'delete',
    data: data
  });
} // 订单详情列表

export function listModifyOrderDetail(params) {
  return request({
    url: '/erpRetWarehouseInfoErp/getErpRetWarehouseInfoList',
    method: 'get',
    params: params
  });
}
export function createModifyOrderDetail(data) {
  return request({
    url: '/erpRetWarehouseInfoErp/createErpRetWarehouseInfoReport',
    method: 'post',
    data: data
  });
}
export function updateModifyOrderDetail(data) {
  return request({
    url: '/erpRetWarehouseInfoErp/updateErpRetWarehouseInfo',
    method: 'put',
    data: data
  });
}
export function createModifyOrderDetails(data) {
  return request({
    url: '/erpRetWarehouseInfoErp/createErpRetWarehouseInfoReports',
    method: 'post',
    data: data
  });
}
export function deleteModifyOrderDetail(data) {
  return request({
    url: '/erpRetWarehouseInfoErp/deleteErpRetWarehouseInfo',
    method: 'delete',
    data: data
  });
}
export function deleteModifyOrderDetailByIds(data) {
  return request({
    url: '/erpRetWarehouseInfoErp/deleteErpRetWarehouseInfoByIds',
    method: 'delete',
    data: data
  });
}
export function findModifyOrderDetail(params) {
  return request({
    url: '/erpRetWarehouseInfoErp/findErpRetWarehouseInfo',
    method: 'get',
    params: params
  });
}