var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "500px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-width": "auto",
                size: "small",
                "status-icon": true
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "returnRemarks" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 2,
                      placeholder: "请输入备注内容"
                    },
                    model: {
                      value: _vm.form.returnRemarks,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "returnRemarks", $$v)
                      },
                      expression: "form.returnRemarks"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "附件", prop: "returnRemarksPic" } },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        "list-type": "picture-card",
                        "on-preview": _vm.uploadPreview,
                        "auto-upload": true,
                        "on-remove": _vm.uploadRemove,
                        "on-progress": _vm.uploadProgress,
                        "on-success": _vm.uploadSuccess,
                        "on-error": _vm.uploadError,
                        limit: 5,
                        "on-exceed": _vm.uploadExceed,
                        action: _vm.uploadURL,
                        headers: _vm.uploadToken,
                        multiple: true,
                        "file-list": _vm.fileList,
                        "before-upload": _vm.uploadBefore,
                        "show-file-list": true,
                        drag: false,
                        accept: _vm.acceptList.toString()
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default"
                      })
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { align: "center" } },
                [
                  _c("el-button", { on: { click: _vm.dialogClose } }, [
                    _vm._v("关闭")
                  ]),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.formSubmit }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.showViewer
            ? _c("el-image-viewer", {
                attrs: {
                  "on-close": _vm.closeViewer,
                  "url-list": _vm.uploadPreviewImageUrl,
                  "z-index": 9999
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }