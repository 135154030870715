var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "400px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-suffix": ":",
            "label-width": "100px",
            size: "small",
            rules: _vm.formRules
          },
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-row",
            {
              staticStyle: { "flex-wrap": "wrap" },
              attrs: { type: "flex", gutter: 20 }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司名称", prop: "companyName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "公司名称",
                          clearable: "",
                          maxlength: 100
                        },
                        model: {
                          value: _vm.form.companyName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "companyName", $$v)
                          },
                          expression: "form.companyName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "公司简称", prop: "companyAbbreviation" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "公司简称",
                          clearable: "",
                          maxlength: 50
                        },
                        model: {
                          value: _vm.form.companyAbbreviation,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "companyAbbreviation", $$v)
                          },
                          expression: "form.companyAbbreviation"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "税率类型", prop: "taxRateType" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.taxRateType,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "taxRateType", $$v)
                            },
                            expression: "form.taxRateType"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("一般纳税人")
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("小规模纳税人")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "税率", prop: "salesTaxRate" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              placeholder: "公司简称",
                              controls: false,
                              min: 0,
                              clearable: "",
                              max: 100
                            },
                            model: {
                              value: _vm.form.salesTaxRate,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "salesTaxRate", $$v)
                              },
                              expression: "form.salesTaxRate"
                            }
                          }),
                          _c("span", { staticStyle: { padding: "0 5px" } }, [
                            _vm._v("%")
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司税号", prop: "companyTaxNumber" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "公司税号",
                          clearable: "",
                          maxlength: 100
                        },
                        model: {
                          value: _vm.form.companyTaxNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "companyTaxNumber", $$v)
                          },
                          expression: "form.companyTaxNumber"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开户银行", prop: "bank" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "开户银行",
                          clearable: "",
                          maxlength: 100
                        },
                        model: {
                          value: _vm.form.bank,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "bank", $$v)
                          },
                          expression: "form.bank"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "银行账户", prop: "bankAccount" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "银行账户",
                          clearable: "",
                          maxlength: 50
                        },
                        model: {
                          value: _vm.form.bankAccount,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "bankAccount", $$v)
                          },
                          expression: "form.bankAccount"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单位地址", prop: "workAddress" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "单位地址",
                          clearable: "",
                          maxlength: 100
                        },
                        model: {
                          value: _vm.form.workAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "workAddress", $$v)
                          },
                          expression: "form.workAddress"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "电话", prop: "telephone" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "电话",
                          clearable: "",
                          maxlength: 50
                        },
                        model: {
                          value: _vm.form.telephone,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "telephone", $$v)
                          },
                          expression: "form.telephone"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24, align: "center" } },
                [
                  _c("el-button", { on: { click: _vm.dialogClose } }, [
                    _vm._v("取消")
                  ]),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.formSubmit }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }