var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            "align-items": "center"
          }
        },
        [
          _c("div"),
          _c(
            "div",
            [
              _c(
                "el-popover",
                {
                  ref: "optionsPopover",
                  attrs: { placement: "bottom", trigger: "click" }
                },
                [
                  _c("TableHeadComponent", {
                    ref: "TableHeadComponentRef",
                    attrs: {
                      "cache-name": _vm.cacheName,
                      "remote-columns-list": _vm.remoteColumnsList
                    },
                    on: { "save-table-set": _vm.saveTableSet }
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: {
                        slot: "reference",
                        type: "primary",
                        size: "mini"
                      },
                      slot: "reference"
                    },
                    [_vm._v("字段自定义")]
                  )
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.printClick }
                },
                [_vm._v("点击打印")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.exportExcel }
                },
                [_vm._v("导出")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              attrs: {
                "element-loading-text": "拼命加载中...",
                "element-loading-spinner": "el-icon-loading"
              }
            },
            [
              _vm.loading === false
                ? _c(
                    "div",
                    {
                      ref: "printContainer" + _vm.$route.name,
                      staticStyle: { width: "100%" },
                      attrs: {
                        id: "printContainer" + _vm.$route.name,
                        loading: _vm.loading
                      }
                    },
                    [
                      _c("div", { staticClass: "title-container" }, [
                        _c("div", { staticClass: "title-content" }, [
                          _c("div", [_vm._v(_vm._s(_vm.corporateName))]),
                          _c("div", [_vm._v("销售单")])
                        ]),
                        _c("div", { staticClass: "image-content" }, [
                          _c("img", { attrs: { id: "barcode" } })
                        ])
                      ]),
                      _c("div", { staticClass: "table-header" }, [
                        _c("div", { staticClass: "item" }, [
                          _vm._v(
                            "订单编号：" + _vm._s(_vm.orderInfo.orderNumber)
                          )
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _vm._v("医院：" + _vm._s(_vm.orderInfo.hospitalName))
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _vm._v("仓库：" + _vm._s(_vm.orderInfo.warehouseName))
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _vm._v(
                            "医生：" + _vm._s(_vm.orderInfo.hospitalDoctorName)
                          )
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _vm._v("病人：" + _vm._s(_vm.orderInfo.patientName))
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _vm._v(
                            "手术名称：" +
                              _vm._s(
                                _vm.surgerysFormatter(_vm.orderInfo.surgerys)
                              )
                          )
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _vm._v("销售：" + _vm._s(_vm.orderInfo.saleUserName))
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _vm._v(
                            "跟台：" +
                              _vm._s(
                                _vm.orderInfo.followUname ||
                                  _vm.orderInfo.followTableUname
                              )
                          )
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _vm._v("归档日期：" + _vm._s(_vm.orderInfo.fillTime))
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _vm._v("归档人：" + _vm._s(_vm.orderInfo.fillName))
                        ])
                      ]),
                      _c("div", { staticClass: "detail-table" }, [
                        _c("table", [
                          _c("thead", [
                            _c(
                              "tr",
                              [
                                _vm._l(_vm.remoteColumnsList, function(
                                  item,
                                  index
                                ) {
                                  return [
                                    item.checked === true
                                      ? _c("th", { key: "field-" + index }, [
                                          _vm._v(_vm._s(item.label))
                                        ])
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          ]),
                          _c(
                            "tbody",
                            [
                              _vm._l(_vm.detailList, function(item, index) {
                                return _c(
                                  "tr",
                                  { key: "data-col-" + index },
                                  [
                                    _vm._l(_vm.remoteColumnsList, function(
                                      item2,
                                      index2
                                    ) {
                                      return [
                                        item2.checked === true
                                          ? _c(
                                              "td",
                                              { key: "data-item-" + index2 },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.dataFormatter(
                                                      item[item2.prop],
                                                      item2.type
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    })
                                  ],
                                  2
                                )
                              }),
                              _vm._l(_vm.getSummary(), function(item, index) {
                                return _c(
                                  "tr",
                                  { key: index },
                                  [
                                    _vm._l(_vm.remoteColumnsList, function(
                                      item2,
                                      index2
                                    ) {
                                      return [
                                        item2.checked === true
                                          ? _c(
                                              "td",
                                              {
                                                key: "summary-" + index2,
                                                staticClass: "summary"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.dataFormatter(
                                                      item[item2.prop],
                                                      item2.type
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    })
                                  ],
                                  2
                                )
                              })
                            ],
                            2
                          )
                        ])
                      ])
                    ]
                  )
                : _vm._e()
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }