var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          attrs: { title: "过期产品提示", visible: _vm.dialogVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.gridData,
                "highlight-current-row": "",
                border: "",
                "max-height": "400",
                size: "mini",
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                }
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  align: "center",
                  width: "80"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "productName",
                  label: "物资名称",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "manufacturerName",
                  label: "供应商",
                  width: "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "productNumber",
                  label: "产品编号",
                  width: "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "specificationModel",
                  label: "规格型号",
                  width: "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "batchNumber",
                  label: "生产批号",
                  width: "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "trackingCode",
                  label: "序列号",
                  width: "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "deliveryQuantity",
                  label: "出库数量",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "manufacturerDate",
                  label: "生产日期",
                  width: "110",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "validityDate",
                  label: "有效期",
                  width: "110",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.formSubmit }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }