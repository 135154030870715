var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "500px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          !_vm.loading
            ? _c(
                "div",
                { staticStyle: { position: "relative" } },
                [
                  _c(
                    "el-tabs",
                    {
                      model: {
                        value: _vm.tabActiveName,
                        callback: function($$v) {
                          _vm.tabActiveName = $$v
                        },
                        expression: "tabActiveName"
                      }
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "本公司配送", name: "form1" } },
                        [
                          _c(
                            "el-form",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.formLoading,
                                  expression: "formLoading"
                                }
                              ],
                              ref: "form1",
                              attrs: {
                                model: _vm.form1,
                                rules: _vm.form1Rules,
                                size: "small",
                                "label-suffix": ":",
                                "status-icon": true,
                                "label-width": "100px",
                                disabled:
                                  _vm.form2.deliveryRequired === 2
                                    ? true
                                    : false
                              },
                              nativeOn: {
                                submit: function($event) {
                                  $event.preventDefault()
                                }
                              }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "配送员",
                                    prop: "deliveryUid"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                        filterable: ""
                                      },
                                      on: { change: _vm.deliveryUidChange },
                                      model: {
                                        value: _vm.form1.deliveryUid,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form1,
                                            "deliveryUid",
                                            $$v
                                          )
                                        },
                                        expression: "form1.deliveryUid"
                                      }
                                    },
                                    _vm._l(_vm.userList, function(item, index) {
                                      return _c("el-option", {
                                        key: "deliveryUid" + index,
                                        attrs: {
                                          label: item.name,
                                          value: item.uid
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "配送员收益" } },
                                [_vm._v(" " + _vm._s(_vm.expressFee) + " ")]
                              ),
                              _vm.describe
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { color: "#f00" },
                                      attrs: {
                                        "label-width": "0",
                                        align: "center"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(_vm.describe) + " ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "第三方物流", name: "form2" } },
                        [
                          _c(
                            "el-form",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.formLoading,
                                  expression: "formLoading"
                                }
                              ],
                              ref: "form2",
                              attrs: {
                                model: _vm.form2,
                                rules: _vm.form2Rules,
                                size: "small",
                                "label-width": "100px",
                                "label-suffix": ":",
                                disabled:
                                  _vm.form2.deliveryRequired === 2
                                    ? true
                                    : false
                              },
                              nativeOn: {
                                submit: function($event) {
                                  $event.preventDefault()
                                }
                              }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "物流公司",
                                    prop: "logisticsCompany"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "物流公司",
                                        filterable: ""
                                      },
                                      model: {
                                        value: _vm.form2.logisticsCompany,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form2,
                                            "logisticsCompany",
                                            $$v
                                          )
                                        },
                                        expression: "form2.logisticsCompany"
                                      }
                                    },
                                    _vm._l(_vm.expressList, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: "logisticsCompany" + index,
                                        attrs: {
                                          label: item.name,
                                          value: item.name
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "物流单号",
                                    prop: "logisticsTrackingNumber"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { maxlength: "50", clearable: "" },
                                    model: {
                                      value: _vm.form2.logisticsTrackingNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form2,
                                          "logisticsTrackingNumber",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form2.logisticsTrackingNumber"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "快递费用",
                                    prop: "expressDeliveryFee"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: { controls: false, min: 0 },
                                    model: {
                                      value: _vm.form2.expressDeliveryFee,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form2,
                                          "expressDeliveryFee",
                                          $$v
                                        )
                                      },
                                      expression: "form2.expressDeliveryFee"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "附件", prop: "enclosure" } },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      attrs: {
                                        drag: "",
                                        action: _vm.uploadAction,
                                        headers: _vm.uploadToken,
                                        "on-preview": _vm.uploadPreview,
                                        "on-remove": _vm.uploadRemove,
                                        "on-success": _vm.uploadSuccess,
                                        "file-list": _vm.form2.enclosure,
                                        multiple: ""
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-upload"
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "el-upload__text" },
                                        [
                                          _vm._v("将文件拖到此处，或"),
                                          _c("em", [_vm._v("点击上传")])
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "el-upload__tip",
                                          attrs: { slot: "tip" },
                                          slot: "tip"
                                        },
                                        [
                                          _vm._v(
                                            "只能上传jpg/png文件，且不超过500kb"
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "备注",
                                    prop: "deliveryRemarks"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      maxlength: "200"
                                    },
                                    model: {
                                      value: _vm.form2.deliveryRemarks,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form2,
                                          "deliveryRemarks",
                                          $$v
                                        )
                                      },
                                      expression: "form2.deliveryRemarks"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      attrs: { slot: "footer", align: "center" },
                      slot: "footer"
                    },
                    [
                      _c("el-button", { on: { click: _vm.dialogClose } }, [
                        _vm._v("关闭")
                      ]),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.formSubmit()
                            }
                          }
                        },
                        [_vm._v("确定")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        top: "10px",
                        right: "0px"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { "true-label": 2, "false-label": 3 },
                          model: {
                            value: _vm.form2.deliveryRequired,
                            callback: function($$v) {
                              _vm.$set(_vm.form2, "deliveryRequired", $$v)
                            },
                            expression: "form2.deliveryRequired"
                          }
                        },
                        [_vm._v("无需配送")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }