var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "1200px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "modal-append-to-body": true,
            "append-to-body": true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dbLoading,
                  expression: "dbLoading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.dbList,
                size: "small",
                border: "",
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                }
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "invoiceNumber",
                  label: "发票号",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "invoicingCompany",
                  label: "开票公司",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "invoiceApp.customerName",
                  label: "购买单位",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "invoicingDate",
                  label: "开票日期",
                  width: "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "taxAmount",
                  label: "含税金额",
                  width: "150",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "receiveStatusName",
                  label: "回款状态",
                  width: "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "overdueDaysDesc",
                  label: "超期状态",
                  width: "100",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(row.overdueDaysDesc) }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2675229093
                )
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "130", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function($event) {
                                  return _vm.toSkip(row)
                                }
                              }
                            },
                            [_vm._v("跳转至发票页面")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3984642332
                )
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "prev, pager, next, total",
                  align: "center",
                  total: _vm.form.total,
                  "current-page": _vm.form.page,
                  "page-size": _vm.form.pageSize
                },
                on: { "current-change": _vm.pageChange }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }