import _objectSpread from "D:/wwwroot/ERP\u524D\u7AEF/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var meta = {
  auth: true
};
export default {
  path: '/financial',
  name: 'financial',
  meta: meta,
  component: layoutHeaderAside,
  children: [{
    path: 'purchase/return_check',
    name: 'financial-purchase-return_check',
    component: _import('financial/purchase/return_check'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '退货审核'
    })
  }, {
    path: 'purchase/return_check/detail/:id',
    name: 'financial-purchase-return_check-detail',
    component: _import('financial/purchase/return_check_detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '退货单详情'
    }),
    props: true
  }, {
    path: 'purchase/confirm',
    name: 'financial-purchase-confirm',
    component: _import('financial/purchase/confirm'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '采购付款'
    }),
    props: true
  }, {
    path: 'purchase/records',
    name: 'financial-purchase-records',
    component: _import('financial/purchase/records'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '付款记录'
    }),
    props: true
  }, {
    path: 'purchase/supplement/index',
    name: 'financial-purchase-supplement-index',
    component: _import('financial/purchase/supplement/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '采购补差单'
    }),
    props: true
  }, {
    path: 'purchase/supplement/detail/:id',
    name: 'financial-purchase-supplement-detail',
    component: _import('financial/purchase/supplement/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '补差单详情'
    }),
    props: true
  }, {
    path: 'purchase/clearance/index',
    name: 'financial-purchase-clearance-index',
    component: _import('financial/purchase/clearance/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '采购核销'
    }),
    props: true
  }, {
    path: 'purchase/clearance/detail/:id',
    name: 'financial-purchase-clearance-detail',
    component: _import('financial/purchase/clearance/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '采购核销详情'
    }),
    props: true
  }, {
    path: 'purchase/dues',
    name: 'financial-purchase-dues',
    component: _import('financial/purchase/dues/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '应付管理'
    }),
    props: true
  }, {
    path: 'purchase/advance',
    name: 'financial-purchase-advance',
    component: _import('financial/purchase/advance/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '预付管理'
    }),
    props: true
  }, {
    path: 'sales/sales_check',
    name: 'financial-sales-sales_check',
    component: _import('financial/sales/sales_check'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '销售单审核'
    })
  }, {
    path: 'sales/sales_check/detail/:id',
    name: 'financial-sales-sales_check-detail',
    component: _import('financial/sales/sales_check_detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '销售单详情'
    }),
    props: true
  }, {
    path: 'sales/agent',
    name: 'financial-sales-agent-index',
    component: _import('financial/sales/agent'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售订货单审核'
    })
  }, {
    path: 'sales/agent/detail/:id',
    name: 'financial-sales-agent-detail',
    component: _import('financial/sales/agent/detail.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售订货单详情'
    }),
    props: true
  }, {
    path: 'sales/agent/sales',
    name: 'financial-sales-agent-sales',
    component: _import('financial/sales/agent/sales.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售销售单审核'
    })
  }, {
    path: 'sales/agent/sales/detail/:id',
    name: 'financial-sales-agent-sales-detail',
    component: _import('financial/sales/agent/sales_detail.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售销售单详情'
    }),
    props: true
  }, {
    path: 'sales/sales_order_check',
    name: 'financial-sales-sales_order_check',
    component: _import('financial/sales/sales_order_check/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '销售单审核'
    })
  }, {
    path: 'sales/sales_order_check/view/:id',
    name: 'financial-sales-sales_order_check-view',
    component: _import('financial/sales/sales_order_check/view'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '销售订货单详情'
    }),
    props: true
  }, {
    path: 'sales/sales_return',
    name: 'financial-sales-sales_return',
    component: _import('financial/sales/sales_return'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '销售退货单审核'
    })
  }, {
    path: 'sales/sales_return/detail/:id',
    name: 'financial-sales-sales_return-detail',
    component: _import('financial/sales/sales_return_detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '销售退货单详情'
    }),
    props: true
  }, {
    path: 'sales/receivable',
    name: 'financial-sales-receivable-index',
    component: _import('financial/sales/receivable'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '应收账款'
    }),
    props: true
  }, {
    path: 'sales/repayment/check',
    name: 'financial-sales-repayment-check-index',
    component: _import('financial/sales/repayment/check'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '回款审核'
    }),
    props: true
  }, {
    path: 'sales/repayment',
    name: 'financial-sales-repayment-index',
    component: _import('financial/sales/repayment'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '回款发票关联'
    }),
    props: true
  }, {
    path: 'sales/clearance',
    name: 'financial-sales-clearance-index',
    component: _import('financial/sales/clearance'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '销售核销'
    }),
    props: true
  }, {
    path: 'sales/clearance/detail/:id/:type',
    name: 'financial-sales-clearance-detail',
    component: _import('financial/sales/clearance/detail.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '销售核销详情'
    }),
    props: true
  }, {
    path: 'sales/stocktaking',
    name: 'financial-sales-stocktaking-index',
    component: _import('financial/sales/stocktaking'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '盘存单审核'
    }),
    props: true
  }, {
    path: 'sales/stocktaking/detail/:id',
    name: 'financial-sales-stocktaking-detail',
    component: _import('financial/sales/stocktaking/detail.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '盘存单审核详情'
    }),
    props: true
  }, {
    path: 'sales/agent/return/check',
    name: 'financial-sales-agent-return-check-index',
    component: _import('financial/sales/agent/return/check'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售退货审核'
    }),
    props: true
  }, {
    path: 'sales/agent/return/check/view/:id',
    name: 'financial-sales-agent-return-check-view',
    component: _import('financial/sales/agent/return/check/view'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售退货审核详情'
    }),
    props: true
  }, {
    path: 'config/price',
    name: 'financial-config-price',
    component: _import('financial/config/price'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '产品价格管理'
    })
  }, {
    path: 'config/strategy',
    name: 'financial-config-strategy-index',
    component: _import('financial/config/strategy'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '经销商价格策略'
    })
  }, {
    path: 'config/strategy/hospital',
    name: 'financial-config-strategy-hospital',
    component: _import('financial/config/strategy/hospital'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '医院价格策略'
    })
  }, {
    path: 'config/strategy/price/:type/:id',
    name: 'financial-config-strategy-price',
    component: _import('financial/config/strategy/price'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '产品价格表'
    }),
    props: true
  }, {
    path: 'config/subsidiary',
    name: 'financial-config-subsidiary',
    component: _import('financial/config/subsidiary'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '归属公司管理'
    }),
    props: true
  }, {
    path: 'config/appclass',
    name: 'financial-config-appclass',
    component: _import('financial/config/appclass'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '申请分类管理'
    }),
    props: true
  }, {
    path: 'config/hospital',
    name: 'financial-config-hospital',
    component: _import('financial/config/hospital'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '医院产品名称设置'
    }),
    props: true
  }, {
    path: 'config/repayment',
    name: 'financial-config-repayment',
    component: _import('financial/config/repayment'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '回款周期设置'
    }),
    props: true
  }, {
    path: 'invoice/apply',
    name: 'financial-invoice-apply',
    component: _import('financial/invoice/apply'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '开票申请'
    }),
    props: true
  }, {
    path: 'invoice/receipts',
    name: 'financial-invoice-receipts',
    component: _import('financial/invoice/receipts'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '进项发票'
    }),
    props: true
  }, {
    path: 'invoice/manage',
    name: 'financial-invoice-manage',
    component: _import('financial/invoice/manage'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '开票管理'
    }),
    props: true
  }, {
    path: 'invoice/records',
    name: 'financial-invoice-records',
    component: _import('financial/invoice/records'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '开票申请记录'
    }),
    props: true
  }, {
    path: 'invoice/output',
    name: 'financial-invoice-output',
    component: _import('financial/invoice/output'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '销项发票管理'
    }),
    props: true
  }, {
    path: 'deposit/dealer',
    name: 'financial-deposit-dealer-index',
    component: _import('financial/deposit/dealer/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '经销商押金管理'
    }),
    props: true
  }, {
    path: 'balance',
    name: 'financial-balance-index',
    component: _import('financial/balance'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '余额管理'
    }),
    props: true
  }, {
    path: 'youtai/commission',
    name: 'financial-youtai-commission',
    component: _import('financial/youtai/commission'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '提成明细'
    }),
    props: true
  }, {
    path: 'purchase/modify',
    name: 'financial-purchase-modify',
    component: _import('financial/purchase/modify/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '库存信息修改审核'
    }),
    props: true
  }, {
    path: 'purchase/modify/detail/:id',
    name: 'financial-purchase-modify-detail',
    component: _import('financial/purchase/modify/detail.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '修改单详情'
    }),
    props: true
  }, {
    path: 'config/policy',
    name: 'financial-config-policy-index',
    component: _import('financial/config/policy/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '价格策略管理'
    }),
    props: true
  }]
};