var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "1200px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "modal-append-to-body": true,
            "append-to-body": true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dbLoading,
                  expression: "dbLoading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.dbList,
                size: "small",
                border: "",
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                },
                "cell-class-name": _vm.cellClassName
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "50",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "deliveryTime",
                  label: "出库时间",
                  width: "200",
                  align: "center",
                  formatter: function(row, column) {
                    return _vm.timeFormatter(row.deliveryTime)
                  }
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "issueType",
                  label: "业务类型",
                  width: "100",
                  align: "center",
                  formatter: function(row, column) {
                    return _vm.issueTypeFormatter(row.issueType)
                  }
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "deliveryOrderNo",
                  label: "出库单号",
                  width: "200",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "状态",
                  width: "100",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.statusFormatter(scope.row.status)
                              )
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3337272994
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_type",
                  width: "100",
                  label: "订货类型",
                  align: "center",
                  formatter: function(row, column) {
                    return _vm.orderTypeFormatter(row, column)
                  }
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "warehouseName",
                  label: "发货库房",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "hospitalName",
                  label: "客户(医院)名称",
                  width: "150",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "180", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function($event) {
                                  return _vm.toView(row)
                                }
                              }
                            },
                            [_vm._v("跳转至该订单页面")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2711651437
                )
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "prev, pager, next, total",
                  align: "center",
                  total: _vm.form.total,
                  "current-page": _vm.form.page,
                  "page-size": _vm.form.pageSize
                },
                on: { "current-change": _vm.pageChange }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }