var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "500px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "append-to-body": true,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c(
                  "el-upload",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "column",
                      "align-items": "flex-start"
                    },
                    attrs: {
                      "show-file-list": false,
                      "auto-upload": true,
                      action: _vm.uploadURL,
                      headers: _vm.uploadToken,
                      accept: _vm.uploadFileType.toString(),
                      "http-request": _vm.myUpload,
                      loading: _vm.loading,
                      disabled: _vm.loading
                    }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "small",
                          type: "primary",
                          loading: _vm.loading
                        }
                      },
                      [_vm._v("点击导入")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-link",
                  {
                    staticStyle: { margin: "0 10px" },
                    attrs: { type: "primary" },
                    on: { click: _vm.templateDownload }
                  },
                  [_vm._v("产品明细模板下载")]
                )
              ],
              1
            ),
            _vm.errorMsg
              ? _c(
                  "div",
                  { staticStyle: { margin: "10px 0", color: "#f00" } },
                  [_vm._v(_vm._s(_vm.errorMsg))]
                )
              : _vm._e(),
            _vm.successMsg
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      margin: "10px 0",
                      color: "rgb(103, 194, 58)"
                    }
                  },
                  [_vm._v(_vm._s(_vm.successMsg))]
                )
              : _vm._e(),
            _c("p", [_vm._v("注意事项：")]),
            _c("ol", [
              _c("li", [_vm._v("导入功能仅针对新增信息，不覆盖已存在信息")]),
              _c("li", [
                _vm._v("库房、供应商、注册证、产品信息，单位必需提前维护")
              ]),
              _c("li", [_vm._v("“*”为必填项，不可为空 ")])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }