var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "1000px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "mainForm",
              attrs: {
                model: _vm.mainForm,
                "label-width": "100px",
                size: "mini",
                disabled: true
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "flex-wrap": "wrap" },
                  attrs: { type: "flex" }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["product_name"]),
                          attrs: { label: "物资名称", prop: "productName" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入物资名称",
                              maxlength: "50",
                              "show-word-limit": ""
                            },
                            model: {
                              value: _vm.mainForm.productName,
                              callback: function($$v) {
                                _vm.$set(_vm.mainForm, "productName", $$v)
                              },
                              expression: "mainForm.productName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["brand_name"]),
                          attrs: { label: "品牌", prop: "brandName" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.mainForm.brandName,
                              callback: function($$v) {
                                _vm.$set(_vm.mainForm, "brandName", $$v)
                              },
                              expression: "mainForm.brandName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["product_place"]),
                          attrs: { label: "产地", prop: "productPlace" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入产地",
                              maxlength: "100",
                              "show-word-limit": ""
                            },
                            model: {
                              value: _vm.mainForm.productPlace,
                              callback: function($$v) {
                                _vm.$set(_vm.mainForm, "productPlace", $$v)
                              },
                              expression: "mainForm.productPlace"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["classification"]),
                          attrs: { label: "所属分类", prop: "classification" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请选择所属分类" },
                            model: {
                              value: _vm.mainForm.classificationText,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.mainForm,
                                  "classificationText",
                                  $$v
                                )
                              },
                              expression: "mainForm.classificationText"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["manufacturer"]),
                          attrs: { label: "生产厂家", prop: "manufacturer" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入生产厂家" },
                            model: {
                              value: _vm.mainForm.manufacturer,
                              callback: function($$v) {
                                _vm.$set(_vm.mainForm, "manufacturer", $$v)
                              },
                              expression: "mainForm.manufacturer"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["registration_number"]),
                          attrs: {
                            label: "注册证号",
                            prop: "registrationNumber"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                multiple: "",
                                clearable: "",
                                placeholder: "请选择注册证号"
                              },
                              model: {
                                value: _vm.certIdString,
                                callback: function($$v) {
                                  _vm.certIdString = $$v
                                },
                                expression: "certIdString"
                              }
                            },
                            _vm._l(_vm.registrationList, function(item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.ID,
                                  attrs: { label: item.regNoCn, value: item.ID }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(item.regNoCn))]
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "specForm",
              attrs: {
                model: _vm.specForm,
                "label-width": "120px",
                size: "mini",
                disabled: true
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "flex-wrap": "wrap" },
                  attrs: { type: "flex" }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["productNumber"]),
                          attrs: { label: "产品编码", prop: "productNumber" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入产品编码"
                            },
                            model: {
                              value: _vm.specForm.productNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "productNumber", $$v)
                              },
                              expression: "specForm.productNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.mainForm.type === "工具",
                          expression: "mainForm.type==='工具'"
                        }
                      ],
                      attrs: { span: 8 }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["toolName"]),
                          attrs: { label: "工具名称", prop: "toolName" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入工具名称",
                              clearable: "",
                              maxlength: "50"
                            },
                            model: {
                              value: _vm.specForm.toolName,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "toolName", $$v)
                              },
                              expression: "specForm.toolName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["barCode"]),
                          attrs: { label: "条码主码", prop: "barCode" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入条码主码"
                            },
                            model: {
                              value: _vm.specForm.barCode,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "barCode", $$v)
                              },
                              expression: "specForm.barCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["specificationModel"]),
                          attrs: {
                            label: "规格型号",
                            prop: "specificationModel"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入规格型号"
                            },
                            model: {
                              value: _vm.specForm.specificationModel,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.specForm,
                                  "specificationModel",
                                  $$v
                                )
                              },
                              expression: "specForm.specificationModel"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["unit"]),
                          attrs: { label: "单位", prop: "unit" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.specForm.unit,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "unit", $$v)
                              },
                              expression: "specForm.unit"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["packNum"]),
                          attrs: { label: "包装数量", prop: "packNum" }
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              placeholder: "请输入包装数量",
                              step: 1,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.specForm.packNum,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "packNum", $$v)
                              },
                              expression: "specForm.packNum"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["packUnit"]),
                          attrs: { label: "包装单位", prop: "packUnit" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.specForm.packUnit,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "packUnit", $$v)
                              },
                              expression: "specForm.packUnit"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["packInstructions"]),
                          attrs: { label: "包装说明", prop: "packInstructions" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入包装说明"
                            },
                            model: {
                              value: _vm.specForm.packInstructions,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "packInstructions", $$v)
                              },
                              expression: "specForm.packInstructions"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["storage"]),
                          attrs: { label: "存储条件", prop: "storage" }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text", placeholder: "存储条件" },
                            model: {
                              value: _vm.specForm.storageString,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "storageString", $$v)
                              },
                              expression: "specForm.storageString"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["sterilizationWays"]),
                          attrs: {
                            label: "灭菌方式",
                            prop: "sterilizationWays"
                          }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.specForm.sterilizationWays,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "sterilizationWays", $$v)
                              },
                              expression: "specForm.sterilizationWays"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["material"]),
                          attrs: { label: "材质", prop: "material" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.specForm.material,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "material", $$v)
                              },
                              expression: "specForm.material"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["wholesalePurchasePrice"]),
                          attrs: {
                            label: "批发进价",
                            prop: "wholesalePurchasePrice"
                          }
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              placeholder: "请输入批发进价",
                              step: 0.01,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.specForm.wholesalePurchasePrice,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.specForm,
                                  "wholesalePurchasePrice",
                                  $$v
                                )
                              },
                              expression: "specForm.wholesalePurchasePrice"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["consignmentPurchasePrice"]),
                          attrs: {
                            label: "寄售进价",
                            prop: "consignmentPurchasePrice"
                          }
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              placeholder: "请输入寄售进价",
                              step: 0.01,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.specForm.consignmentPurchasePrice,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.specForm,
                                  "consignmentPurchasePrice",
                                  $$v
                                )
                              },
                              expression: "specForm.consignmentPurchasePrice"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["marketPrice"]),
                          attrs: { label: "市场售价", prop: "marketPrice" }
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              placeholder: "请输入市场售价",
                              step: 0.01,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.specForm.marketPrice,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "marketPrice", $$v)
                              },
                              expression: "specForm.marketPrice"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["consignmentPrice"]),
                          attrs: { label: "寄售售价", prop: "consignmentPrice" }
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              placeholder: "请输入寄售售价",
                              step: 0.01,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.specForm.consignmentPrice,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "consignmentPrice", $$v)
                              },
                              expression: "specForm.consignmentPrice"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["wholesalePrice"]),
                          attrs: { label: "批发售价", prop: "wholesalePrice" }
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              placeholder: "请输入批发售价",
                              step: 0.01,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.specForm.wholesalePrice,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "wholesalePrice", $$v)
                              },
                              expression: "specForm.wholesalePrice"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["monetaryUnit"]),
                          attrs: { label: "货币单位", prop: "monetaryUnit" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.specForm.monetaryUnit,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "monetaryUnit", $$v)
                              },
                              expression: "specForm.monetaryUnit"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["medCode"]),
                          attrs: { label: "医保编码", prop: "medCode" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.specForm.medCode,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "medCode", $$v)
                              },
                              expression: "specForm.medCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["genericNameEn"]),
                          attrs: { label: "英文描述", prop: "genericNameEn" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入英文描述"
                            },
                            model: {
                              value: _vm.specForm.genericNameEn,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "genericNameEn", $$v)
                              },
                              expression: "specForm.genericNameEn"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["genericName"]),
                          attrs: {
                            label: "中文描述（通用名称）",
                            prop: "genericName"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入中文描述（通用名称）"
                            },
                            model: {
                              value: _vm.specForm.genericName,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "genericName", $$v)
                              },
                              expression: "specForm.genericName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["epMonthCheck", "epMonth"]),
                          attrs: { label: "效期产生策略", prop: "ep_month" }
                        },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { "true-label": 1, "false-label": 0 },
                              model: {
                                value: _vm.specForm.ep_month_check,
                                callback: function($$v) {
                                  _vm.$set(_vm.specForm, "ep_month_check", $$v)
                                },
                                expression: "specForm.ep_month_check"
                              }
                            },
                            [_vm._v("按生产日期往后推算产生")]
                          ),
                          _c("el-input-number", {
                            staticStyle: { width: "100px", margin: "0 10px" },
                            attrs: {
                              "controls-position": "right",
                              min: 0,
                              disabled:
                                _vm.specForm.ep_month_check === 0 ? true : false
                            },
                            model: {
                              value: _vm.specForm.ep_month,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "ep_month", $$v)
                              },
                              expression: "specForm.ep_month"
                            }
                          }),
                          _vm._v(" 个月 ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          class: _vm.changedClass(["epDayCheck", "epDay"]),
                          attrs: { label: "效期预警策略", prop: "ep_day" }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  model: {
                                    value: _vm.specForm.ep_day_check,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.specForm,
                                        "ep_day_check",
                                        $$v
                                      )
                                    },
                                    expression: "specForm.ep_day_check"
                                  }
                                },
                                [_vm._v("提前")]
                              ),
                              _vm._v(" "),
                              _c("el-input-number", {
                                staticStyle: {
                                  width: "100px",
                                  margin: "0 10px"
                                },
                                attrs: { "controls-position": "right", min: 0 },
                                model: {
                                  value: _vm.specForm.ep_day,
                                  callback: function($$v) {
                                    _vm.$set(_vm.specForm, "ep_day", $$v)
                                  },
                                  expression: "specForm.ep_day"
                                }
                              }),
                              _vm._v(" 天 ")
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24, align: "center" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium", type: "primary" },
                      on: { click: _vm.examineConfirm }
                    },
                    [_vm._v("确认审核")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("ExamineComponents", {
            ref: "ExamineComponents",
            on: { confirm: _vm.parentReload }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }