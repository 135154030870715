var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "1200px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "append-to-body": true,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              ref: "itemForm",
              attrs: {
                model: _vm.itemForm,
                rules: _vm.itemFormRules,
                "label-width": "200px",
                size: "mini",
                "label-suffix": ":"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                },
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.formSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "flex-wrap": "wrap" },
                  attrs: { type: "flex" }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "注册证编号", prop: "regNoCn" } },
                        [
                          !_vm.readonly
                            ? _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请输入注册证编号",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.itemForm.regNoCn,
                                    callback: function($$v) {
                                      _vm.$set(_vm.itemForm, "regNoCn", $$v)
                                    },
                                    expression: "itemForm.regNoCn"
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { slot: "append" },
                                      on: { click: _vm.autoGet },
                                      slot: "append"
                                    },
                                    [_vm._v("自动获取")]
                                  )
                                ],
                                1
                              )
                            : _c("span", [_vm._v(_vm._s(_vm.itemForm.regNoCn))])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "注册人姓名", prop: "regName" } },
                        [
                          !_vm.readonly
                            ? _c("el-input", {
                                attrs: {
                                  placeholder: "请输入注册人姓名",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.itemForm.regName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.itemForm, "regName", $$v)
                                  },
                                  expression: "itemForm.regName"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(_vm.itemForm.regName))])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "注册人地址",
                            prop: "companyRegAddress"
                          }
                        },
                        [
                          !_vm.readonly
                            ? _c("el-input", {
                                attrs: {
                                  placeholder: "请输入注册人地址",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.itemForm.companyRegAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemForm,
                                      "companyRegAddress",
                                      $$v
                                    )
                                  },
                                  expression: "itemForm.companyRegAddress"
                                }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(_vm.itemForm.companyRegAddress))
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "受托生产企业",
                            prop: "entrustedProduction"
                          }
                        },
                        [
                          !_vm.readonly
                            ? _c("el-input", {
                                attrs: {
                                  placeholder: "请输入受托生产企业",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.itemForm.entrustedProduction,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemForm,
                                      "entrustedProduction",
                                      $$v
                                    )
                                  },
                                  expression: "itemForm.entrustedProduction"
                                }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(_vm.itemForm.entrustedProduction))
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "生产地址",
                            prop: "addressProductionSite"
                          }
                        },
                        [
                          !_vm.readonly
                            ? _c("el-input", {
                                attrs: {
                                  placeholder: "请输入生产地址",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.itemForm.addressProductionSite,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemForm,
                                      "addressProductionSite",
                                      $$v
                                    )
                                  },
                                  expression: "itemForm.addressProductionSite"
                                }
                              })
                            : _c("span", [
                                _vm._v(
                                  _vm._s(_vm.itemForm.addressProductionSite)
                                )
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "产品名称", prop: "regProductName" }
                        },
                        [
                          !_vm.readonly
                            ? _c("el-input", {
                                attrs: {
                                  placeholder: "请输入产品名称",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.itemForm.regProductName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemForm,
                                      "regProductName",
                                      $$v
                                    )
                                  },
                                  expression: "itemForm.regProductName"
                                }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(_vm.itemForm.regProductName))
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "管理类别", prop: "adminType" } },
                        [
                          !_vm.readonly
                            ? _c("el-input", {
                                attrs: { placeholder: "请选择管理类别" },
                                model: {
                                  value: _vm.itemForm.adminType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.itemForm, "adminType", $$v)
                                  },
                                  expression: "itemForm.adminType"
                                }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(_vm.itemForm.adminType))
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "规格型号", prop: "materialSpecCert" }
                        },
                        [
                          !_vm.readonly
                            ? _c("el-input", {
                                attrs: {
                                  placeholder: "请输入规格型号",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.itemForm.materialSpecCert,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemForm,
                                      "materialSpecCert",
                                      $$v
                                    )
                                  },
                                  expression: "itemForm.materialSpecCert"
                                }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(_vm.itemForm.materialSpecCert))
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "结构及组成/主要组成成分",
                            prop: "productStructureComposition"
                          }
                        },
                        [
                          !_vm.readonly
                            ? _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 2,
                                  placeholder: "请输入结构及组成/主要组成成分",
                                  clearable: ""
                                },
                                model: {
                                  value:
                                    _vm.itemForm.productStructureComposition,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemForm,
                                      "productStructureComposition",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "itemForm.productStructureComposition"
                                }
                              })
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.itemForm.productStructureComposition
                                  )
                                )
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "适用范围/预期用途",
                            prop: "scopeApplication"
                          }
                        },
                        [
                          !_vm.readonly
                            ? _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 2,
                                  placeholder: "请输入适用范围/预期用途",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.itemForm.scopeApplication,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemForm,
                                      "scopeApplication",
                                      $$v
                                    )
                                  },
                                  expression: "itemForm.scopeApplication"
                                }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(_vm.itemForm.scopeApplication))
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "代理人名称",
                            prop: "registeredAgent"
                          }
                        },
                        [
                          !_vm.readonly
                            ? _c("el-input", {
                                attrs: {
                                  placeholder: "请输入代理人名称",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.itemForm.registeredAgent,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemForm,
                                      "registeredAgent",
                                      $$v
                                    )
                                  },
                                  expression: "itemForm.registeredAgent"
                                }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(_vm.itemForm.registeredAgent))
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "代理人住所", prop: "address" } },
                        [
                          !_vm.readonly
                            ? _c("el-input", {
                                attrs: {
                                  placeholder: "请输入代理人住所",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.itemForm.address,
                                  callback: function($$v) {
                                    _vm.$set(_vm.itemForm, "address", $$v)
                                  },
                                  expression: "itemForm.address"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(_vm.itemForm.address))])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "批准日期", prop: "regDate" } },
                        [
                          !_vm.readonly
                            ? _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  placeholder: "选择日期",
                                  "value-format": "yyyy-MM-dd",
                                  format: "yyyy-MM-dd"
                                },
                                model: {
                                  value: _vm.itemForm.regDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.itemForm, "regDate", $$v)
                                  },
                                  expression: "itemForm.regDate"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(_vm.itemForm.regDate))])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "有效期至", prop: "expirationDate" }
                        },
                        [
                          !_vm.readonly
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "row",
                                    "justify-content": "flex-start",
                                    width: "250px"
                                  }
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "250px" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期",
                                      "value-format": "yyyy-MM-dd",
                                      format: "yyyy-MM-dd",
                                      disabled: _vm.itemForm.longTermValidity
                                        ? true
                                        : false
                                    },
                                    model: {
                                      value: _vm.itemForm.expirationDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.itemForm,
                                          "expirationDate",
                                          $$v
                                        )
                                      },
                                      expression: "itemForm.expirationDate"
                                    }
                                  }),
                                  _c(
                                    "el-checkbox",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      on: { change: _vm.longTermChange },
                                      model: {
                                        value: _vm.itemForm.longTermValidity,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.itemForm,
                                            "longTermValidity",
                                            $$v
                                          )
                                        },
                                        expression: "itemForm.longTermValidity"
                                      }
                                    },
                                    [_vm._v("长期")]
                                  )
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.itemForm.longTermValidity
                                      ? "长期"
                                      : _vm.itemForm.expirationDate
                                  )
                                )
                              ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "其他内容", prop: "otherContent" } },
                        [
                          !_vm.readonly
                            ? _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 2,
                                  placeholder: "请输入其他内容",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.itemForm.otherContent,
                                  callback: function($$v) {
                                    _vm.$set(_vm.itemForm, "otherContent", $$v)
                                  },
                                  expression: "itemForm.otherContent"
                                }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(_vm.itemForm.otherContent))
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注", prop: "remarks" } },
                        [
                          !_vm.readonly
                            ? _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 2,
                                  placeholder: "请输入备注",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.itemForm.remarks,
                                  callback: function($$v) {
                                    _vm.$set(_vm.itemForm, "remarks", $$v)
                                  },
                                  expression: "itemForm.remarks"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(_vm.itemForm.remarks))])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "原注册证编号", prop: "corIds" } },
                        [
                          !_vm.readonly
                            ? _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    multiple: "",
                                    clearable: "",
                                    placeholder: "请选择注册证号"
                                  },
                                  model: {
                                    value: _vm.certIdString,
                                    callback: function($$v) {
                                      _vm.certIdString = $$v
                                    },
                                    expression: "certIdString"
                                  }
                                },
                                _vm._l(_vm.registrationList, function(item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.ID,
                                      attrs: {
                                        label: item.regNoCn,
                                        value: item.ID
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { float: "left" } },
                                        [_vm._v(_vm._s(item.regNoCn))]
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            : _c("span", [
                                _vm._v(
                                  _vm._s(_vm.certIdToString(_vm.certIdString))
                                )
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "附件", prop: "enclosure" } },
                        [
                          _c(
                            "el-upload",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "column",
                                "align-items": "flex-start"
                              },
                              attrs: {
                                "on-preview": _vm.uploadPreview,
                                "auto-upload": true,
                                "on-remove": _vm.uploadRemove,
                                "on-progress": _vm.uploadProgress,
                                "on-success": _vm.uploadSuccess,
                                "on-error": _vm.uploadError,
                                "on-change": _vm.uploadChange,
                                action: _vm.uploadURL,
                                headers: _vm.uploadToken,
                                "file-list": _vm.uploadFileList,
                                "show-file-list": true,
                                disabled: _vm.readonly,
                                accept: _vm.uploadFileType.toString(),
                                drag: ""
                              }
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip"
                                },
                                [
                                  _vm._v(
                                    "只能上传" +
                                      _vm._s(
                                        _vm.uploadFileType
                                          .toString()
                                          .toUpperCase()
                                          .replace(/\./g, "")
                                          .replace(/,/g, "/")
                                      ) +
                                      "类型文件，且单个文件大小不超过20MB，最多允许上传10个附件"
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm.uploadPreviewVisible
                            ? _c(
                                "el-dialog",
                                {
                                  attrs: {
                                    visible: _vm.uploadPreviewVisible,
                                    "append-to-body": true,
                                    "modal-append-to-body": false
                                  },
                                  on: {
                                    "update:visible": function($event) {
                                      _vm.uploadPreviewVisible = $event
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      width: "100%",
                                      src: _vm.uploadPreviewImageUrl,
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "0", align: "center" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "medium" },
                              on: { click: _vm.dialogClose }
                            },
                            [_vm._v(_vm._s(_vm.readonly ? "关闭" : "取消"))]
                          ),
                          !_vm.readonly
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "prevent-re-click",
                                      rawName: "v-prevent-re-click"
                                    }
                                  ],
                                  attrs: { size: "medium", type: "primary" },
                                  on: { click: _vm.formSubmit }
                                },
                                [_vm._v("提交")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }